import { defineStore } from 'pinia';
import alertsDataJson from "@/data/alertsData.json";
import { alertOrderByOptionsType } from '@/data/multiUseData';

export const useAlertsStore = defineStore( {
  id: "alertsStore",
  state: () => ({
    alertsData: [], // Stores the fetched alerts data
    searchKeyword: '', // Stores the search keyword for filtering alerts
    selectedType: '',
    selectedOrderBy: '',
    isLoading: false, // Boolean flag to indicate if alerts data is loading
    initialLoad: false,
    filteredAlerts: [], // Stores the filtered alerts based on search and sort
    searchPerformed: false,
  }),

  // Getter functions to compute derived state based on state variables
  getters: {
    filteredData: (state) => {
      let filteredData = [...state.alertsData];
      // Apply search keyword filter
      if (state.searchPerformed) {
        filteredData = filteredData.filter(alertsData =>
          (!state.selectedType || alertsData.type === state.selectedType) &&
          (!state.searchKeyword || Object.values(alertsData).some(value => value.toString().toLowerCase().includes(state.searchKeyword.toLowerCase())))
        );
      }
      //Apply sorting based on selected sort option
      //Option types are added to this in the alertOrderByOptionsType in @/data/multiUseData 
      //The selection options from the front end did not chnage locations
      //This section was devised so unless a new filter type is added the code here does not chnage upon adding new options
      if (state.selectedOrderBy) {
        const selectedOption = alertOrderByOptionsType.find(option => option.label === state.selectedOrderBy);
        if (selectedOption) {
          const { value, key } = selectedOption;
          filteredData.sort((a, b) => {
            if (value === 'date') {
              return new Date(b[key]) - new Date(a[key]);
            } else if (value === 'text') {
              return a[key].localeCompare(b[key]);
            }
          });
        }
      }
      return filteredData;
    },
  },
  actions: {
    applyFilters() {
      this.isLoading = true;
      setTimeout(() => {
      this.alertsData = alertsDataJson.data;
      this.handleSearch(this.searchKeyword, this.selectedOrderBy);
      this.alertsData = this.filteredData;
      this.isLoading = false;
    }, 1000);
    },
    handleSearch(selectedKeyword, selectedOrderBy) {
      this.searchKeyword = selectedKeyword;
      this.selectedOrderBy = selectedOrderBy;
      this.searchPerformed = true;
    },
    getAlerts() {
      this.initialLoad = true;
      setTimeout(() => {
        this.selectedOrderBy = '';
        this.alertsData = alertsDataJson.data;
        this.initialLoad = false;
      }, 1000);
    },
    resetSearch() {
      this.searchKeyword = '';
      this.selectedType = '';
      this.selectedOrderBy = '';
      this.searchPerformed = false;
      this.alertsData = alertsDataJson.data;
    },
  },
});

