import { useModalStore } from '@/stores/ModalStore';

export function useModalActions() {
  const modalStore = useModalStore();

  const simulateSave = (modalId) => {
    modalStore.modalLoading = true;
    setTimeout(() => {
      modalStore.modalLoading = false;
      modalStore.closeModal(modalId);
    }, 1000); // Simulate save delay
  };

  const simulateResync = () => {
    modalStore.modalLoading = true;
    setTimeout(() => {
      modalStore.modalLoading = false;
    }, 1200); // Simulate resync delay
  };

  return {
    simulateSave,
    simulateResync,
  };
}
