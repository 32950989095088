<script setup>

</script>

<template>
  <svg class="icon-color" xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
    <path d="M18.7472 2.93706H16.5755C16.4023 2.34903 16.0434 1.83286 15.5525 1.4657C15.0616 1.09855 14.4651 0.900146 13.8521 0.900146C13.239 0.900146 12.6425 1.09855 12.1516 1.4657C11.6607 1.83286 11.3018 2.34903 11.1286 2.93706H1.69436C1.58026 2.92512 1.46493 2.93728 1.35583 2.97277C1.24674 3.00825 1.14632 3.06626 1.06108 3.14304C0.975842 3.21982 0.907689 3.31366 0.861039 3.41846C0.814389 3.52327 0.790283 3.63671 0.790283 3.75143C0.790283 3.86615 0.814389 3.97959 0.861039 4.0844C0.907689 4.1892 0.975842 4.28304 1.06108 4.35982C1.14632 4.4366 1.24674 4.49461 1.35583 4.5301C1.46493 4.56558 1.58026 4.57775 1.69436 4.5658H11.1286C11.3018 5.15383 11.6607 5.67001 12.1516 6.03716C12.6425 6.40431 13.239 6.60272 13.8521 6.60272C14.4651 6.60272 15.0616 6.40431 15.5525 6.03716C16.0434 5.67001 16.4023 5.15383 16.5755 4.5658H18.7472C18.8613 4.57775 18.9766 4.56558 19.0857 4.5301C19.1948 4.49461 19.2952 4.4366 19.3805 4.35982C19.4657 4.28304 19.5338 4.1892 19.5805 4.0844C19.6271 3.97959 19.6512 3.86615 19.6512 3.75143C19.6512 3.63671 19.6271 3.52327 19.5805 3.41846C19.5338 3.31366 19.4657 3.21982 19.3805 3.14304C19.2952 3.06626 19.1948 3.00825 19.0857 2.97277C18.9766 2.93728 18.8613 2.92512 18.7472 2.93706ZM13.8521 4.97521C13.613 4.96648 13.3818 4.88762 13.1872 4.74844C12.9926 4.60926 12.8433 4.41592 12.7578 4.19249C12.6723 3.96906 12.6544 3.72541 12.7063 3.49189C12.7583 3.25837 12.8778 3.04529 13.0499 2.87919C13.2221 2.71308 13.4393 2.60129 13.6746 2.55774C13.9098 2.51419 14.1526 2.54079 14.3729 2.63425C14.5931 2.7277 14.781 2.88387 14.9131 3.08331C15.0452 3.28275 15.1157 3.51665 15.1159 3.75588C15.1148 3.91946 15.081 4.08118 15.0165 4.23152C14.9521 4.38187 14.8582 4.51783 14.7405 4.63141C14.6227 4.74499 14.4835 4.83391 14.331 4.89295C14.1784 4.95199 14.0156 4.97996 13.8521 4.97521Z" />
    <path d="M18.747 9.44292H9.31278C9.13957 8.85489 8.78068 8.33872 8.28978 7.97156C7.79888 7.60441 7.20233 7.40601 6.58932 7.40601C5.9763 7.40601 5.37976 7.60441 4.88885 7.97156C4.39795 8.33872 4.03907 8.85489 3.86585 9.44292H1.6942C1.47939 9.44292 1.27339 9.52825 1.1215 9.68014C0.969608 9.83203 0.884277 10.038 0.884277 10.2528C0.884277 10.4676 0.969608 10.6737 1.1215 10.8255C1.27339 10.9774 1.47939 11.0628 1.6942 11.0628H3.86585C4.03907 11.6508 4.39795 12.167 4.88885 12.5341C5.37976 12.9013 5.9763 13.0997 6.58932 13.0997C7.20233 13.0997 7.79888 12.9013 8.28978 12.5341C8.78068 12.167 9.13957 11.6508 9.31278 11.0628H18.747C18.9618 11.0628 19.1678 10.9774 19.3197 10.8255C19.4716 10.6737 19.5569 10.4676 19.5569 10.2528C19.5569 10.038 19.4716 9.83203 19.3197 9.68014C19.1678 9.52825 18.9618 9.44292 18.747 9.44292ZM6.58932 11.4722C6.34816 11.4722 6.11241 11.4007 5.91189 11.2667C5.71138 11.1327 5.55509 10.9423 5.4628 10.7195C5.37051 10.4967 5.34637 10.2515 5.39342 10.015C5.44046 9.77843 5.55659 9.56117 5.72712 9.39064C5.89765 9.22012 6.11491 9.10399 6.35144 9.05694C6.58796 9.00989 6.83313 9.03404 7.05593 9.12633C7.27874 9.21862 7.46917 9.3749 7.60315 9.57542C7.73713 9.77593 7.80865 10.0117 7.80865 10.2528C7.80865 10.5762 7.68018 10.8864 7.45151 11.115C7.22284 11.3437 6.9127 11.4722 6.58932 11.4722Z" />
  </svg>
</template>

<style scoped lang="scss">
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
    cursor: pointer;
  }
}

</style>
