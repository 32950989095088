export const reportOptionsData = [
  {
    id: 1,
    label: 'Benchmark',
    value: 1,
  },
  {
    id: 2,
    label: 'ETA',
    value: 2,
  },
  {
    id: 3,
    label: 'Extension',
    value: 3,
  },
  {
    id: 4,
    label: 'Keyword',
    value: 4,
  },
  {
    id: 5,
    label: 'RSA',
    value: 5,
  },
  {
    id: 6,
    label: 'Trending',
    value: 6,
  },
];
