<!-- TheHeader.vue -->
<script setup>
// Imports...
import AppLogoLight from './svg/AppLogoLight.vue';
import AppLogoDyverse from './svg/AppLogoDyverse.vue';
import AppHeaderSearch from "./ui/search/AppHeaderSearch.vue";
import { computed } from "vue";
import TheNavBar from "@/components/TheNavBar.vue";
import { useThemeStore } from "@/stores/ThemeStore";

// Logic...
const themeStore = useThemeStore();

// Computed property to dynamically select the logo based on the current theme
const logoComponent = computed(() => {
  switch (themeStore.selectedTheme) {
    case 'Dark':
      return AppLogoLight;
    case 'Light':
      return AppLogoLight;
    case 'Dyverse':
      return AppLogoDyverse;
    default:
      return AppLogoLight;
  }
});

</script>

<template>
  <header class="header">
    <div class="left-side">
      <!-- Conditionally render the logo component based on the selected theme -->
      <component :is="logoComponent"></component>
      <AppHeaderSearch />
    </div>
    <TheNavBar />
  </header>
</template>


<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
  background: var(--element-bg);
  border-bottom: var(--border-size-bar) solid var(--secondary-color);
}

.left-side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  > :first-child {
    margin-right: 20px;
  }

  // Hiding the AppHeaderSearch component on mobile screens
  @media (max-width: 560px) {
    > :last-child {
      display: none;
    }
  }
}
</style>
