// useScrollVisibility.js

// Imports...
import {onMounted, onUnmounted, ref} from "vue";
// Logic...

export function useScrollVisibility(store, key) {
  const element = ref(null);

  const handleScroll = () => {

    store.topFilterVisibility[key] = element.value.scrollTop === 0;
  };

  onMounted(() => {
    element.value = document.getElementById('app-body');
    element.value.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    element.value.removeEventListener('scroll', handleScroll);
  });

  return element;
}
