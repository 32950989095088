<template>
    <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_678_39)">
            <path
                d="M42.9245 21.4203C42.9245 33.2709 33.3205 42.8562 21.4886 42.8562C9.65676 42.8562 0.0527344 33.2709 0.0527344 21.4203C0.0527344 9.58841 9.65676 -0.015625 21.4886 -0.015625C33.3392 -0.015625 42.9245 9.58841 42.9245 21.4203Z"
                fill="#00B43C" />
            <path d="M19.2802 8.10938H13.4766L19.2802 13.913V8.10938Z" fill="#A6E5BB" />
            <path
                d="M19.2802 30.9305V25.4826L24.7281 30.9305H31.917L24.5783 23.5917L31.112 17.058H23.9231L19.2802 21.6822V17.058L13.4766 11.2544V30.9305H19.2802Z"
                fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_678_39">
                <rect width="44" height="45" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>