<!-- AppIconInfo.vue -->
<script setup>

</script>

<template>
    <svg class="icon-color" width="13" height="13" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.5 0.359375C5.34763 0.359375 0.359375 5.34943 0.359375 11.5C0.359375 17.6542 5.34763 22.6406 11.5 22.6406C17.6524 22.6406 22.6406 17.6542 22.6406 11.5C22.6406 5.34943 17.6524 0.359375 11.5 0.359375ZM11.5 5.30078C12.542 5.30078 13.3867 6.14549 13.3867 7.1875C13.3867 8.22951 12.542 9.07422 11.5 9.07422C10.458 9.07422 9.61328 8.22951 9.61328 7.1875C9.61328 6.14549 10.458 5.30078 11.5 5.30078ZM14.0156 16.7109C14.0156 17.0086 13.7743 17.25 13.4766 17.25H9.52344C9.22574 17.25 8.98438 17.0086 8.98438 16.7109V15.6328C8.98438 15.3351 9.22574 15.0938 9.52344 15.0938H10.0625V12.2188H9.52344C9.22574 12.2188 8.98438 11.9774 8.98438 11.6797V10.6016C8.98438 10.3039 9.22574 10.0625 9.52344 10.0625H12.3984C12.6961 10.0625 12.9375 10.3039 12.9375 10.6016V15.0938H13.4766C13.7743 15.0938 14.0156 15.3351 14.0156 15.6328V16.7109Z"
            fill="var(--secondary-color)" />
    </svg>
</template>

<style scoped lang="scss">
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
