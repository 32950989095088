// src/composables/useTableStyles.js
import { computed } from 'vue';

export default function useTableStyles(data, multiplier) {
  const getLongestString = (data) => Math.max(...data.map(item => Math.max(...Object.values(item).filter(value => typeof value === 'string').map(value => value.length))));

  const longestString = getLongestString(data);
  const minColumnWidth = computed(() => longestString * multiplier);
  const cellStyle = computed(() => ({ '--cell-base-size': `${minColumnWidth.value}px` }));

  return { cellStyle, minColumnWidth };
}
