<!-- AppIconOccupancy.vue -->
<script setup>

defineProps({
  amount: {
    type: [String, Number],
    required: true
  }
});

const getOccupancyColor = (amount) => {
  const numericAmount = parseFloat(amount.replace('%', ''));
  if (!amount) return 'color-default';  // Return a default color or value

  if (numericAmount >= 90) {
    return 'color-good';
  } else if (numericAmount >= 80) {
    return 'color-warning';
  } else {
    return 'color-danger';

  }
}
</script>

<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5ZM3.36 15.6229C3.36 15.4368 3.5109 15.2859 3.69704 15.2859H4.64079V6.38815C4.64079 5.64359 5.24437 5.04 5.98894 5.04H10.4378C11.1824 5.04 11.786 5.64359 11.786 6.38815V10.4326H15.8304C16.575 10.4326 17.1786 11.0362 17.1786 11.7808V15.2859H18.1222C18.3084 15.2859 18.4593 15.4368 18.4593 15.6229C18.4593 15.8091 18.3084 15.96 18.1222 15.96H3.69704C3.5109 15.96 3.36 15.8091 3.36 15.6229ZM11.786 15.2859V11.1067H15.8304C16.2027 11.1067 16.5045 11.4085 16.5045 11.7808V15.2859H11.786ZM11.1119 15.2859V6.38815C11.1119 6.01587 10.8101 5.71408 10.4378 5.71408H5.98894C5.61665 5.71408 5.31486 6.01587 5.31486 6.38815V15.2859H11.1119ZM6.25847 6.79261C6.10956 6.79261 5.98884 6.91333 5.98884 7.06224C5.98884 7.21116 6.10956 7.33187 6.25847 7.33187H10.1681C10.317 7.33187 10.4377 7.21116 10.4377 7.06224C10.4377 6.91333 10.317 6.79261 10.1681 6.79261H6.25847ZM5.98884 8.81484C5.98884 8.66593 6.10956 8.54521 6.25847 8.54521H10.1681C10.317 8.54521 10.4377 8.66593 10.4377 8.81484C10.4377 8.96376 10.317 9.08447 10.1681 9.08447H6.25847C6.10956 9.08447 5.98884 8.96376 5.98884 8.81484ZM6.25847 10.2978C6.10956 10.2978 5.98884 10.4185 5.98884 10.5674C5.98884 10.7163 6.10956 10.837 6.25847 10.837H10.1681C10.317 10.837 10.4377 10.7163 10.4377 10.5674C10.4377 10.4185 10.317 10.2978 10.1681 10.2978H6.25847Z"
            :class="getOccupancyColor(amount)" />
    </svg>
</template>

<style lang="scss" scoped></style>
