<!-- BudgetTableSubHeader.vue -->
<script setup>
// Imports...
import { useBudgetTableStore } from '@/stores/BudgetTableStore';
import { useModalStore } from '@/stores/ModalStore';

// Logic...
const budgetTableStore = useBudgetTableStore();
const modalStore = useModalStore();
const handleAddItemModal = () => {
  modalStore.openModal('addItemModal');
};
</script>

<template>
  <div class="div-row div-sub-header">
    <div class="sub-header-cell">
      <p class="add-expense" @click="handleAddItemModal">Add Expense</p>
    </div>
    <template v-for="month in budgetTableStore.months" :key="month">
      <div class="sub-header-cell">Budget</div>
      <div class="sub-header-cell">Actual</div>
    </template>
    <div class="sub-header-cell">Budget</div>
    <div class="sub-header-cell">Actual</div>
  </div>
</template>

<style scoped lang="scss">
.div-row {
  display: flex;
  flex-wrap: nowrap;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }
}

.div-sub-header {
  color: var(--secondary-color);
}

.sub-header-cell {
  flex-basis: calc(100% / 13);
  padding: 15px 10px;
  text-align: center;
  font-weight: 400;
  transition: background-color 0.3s ease;
}

.sub-header-cell:first-child {
  text-align: left;
  font-weight: 600;
  min-width: 400px;
}

.sub-header-cell:last-child {
  border-right: none;
}

.sub-header-cell {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  border-bottom: var(--border-size) solid var(--secondary-color);
  border-right: var(--border-size) solid var(--secondary-color);
  min-width: 140px;

  &.focused {
    box-shadow: inset 0 0 0 1px var(--primary-color);
  }
}

.add-expense {
  cursor: pointer;
  color: var(--primary-color);
  text-decoration: underline;
  width: 25%;
}
</style>
