<!-- PropertiesGroupSelector.vue -->
<script setup>
// imports
import { usePropertiesStore } from '@/stores/PropertiesStore';
import { liveGroupData } from '@/data/multiUseData';
import { transformData } from '@/helpers/MultiSelectorHelper';
import AppMultiSelector from '@/components/ui/selects/AppMultiSelector.vue';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import AppIconAdd from '@/components/svg/AppIconAdd.vue';
import AppIconCopy from '@/components/svg/AppIconCopy.vue';
import { useModalStore } from '@/stores/ModalStore';
import AppFilterControlPanel from '@/components/ui/AppFilterControlPanel.vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';
import AppCategoryList from '@/components/ui/AppCategoryList.vue';
import useSearch from '@/composables/useSearch';
// Logic ***********************

// Store instances
const propertiesStore = usePropertiesStore();

// FOR NOW, THIS IS JUST TEMPORARY TILL I RETURN AND WE DISCUSS THE FILTERS
// We transform them for now working with Josh to get the data in the right format
const transformedOptions = transformData(liveGroupData, 'category_type', ['id', 'name', 'property_ids', 'tags']);

const { searchValue, filteredOptions } = useSearch(transformedOptions);

function clearAll() {
  searchValue.value = '';
  propertiesStore.selectedProperties.length = 0; // This clears the array without losing the reactivity
}

const modalStore = useModalStore();

const handleMangeGroupsModal = () => {
  modalStore.openModal('manageGroupsModal');
};

const handleOpenNewGroupsModal = () => {
  modalStore.openModal('newGroupsModal');
};
</script>
<template>
  <AppVisibleFilterWrapper>
    <AppMultiSelector title="Groups" v-model:selectedOptions="propertiesStore.selectedProperties" placeholder="Select one or more groups">
      <template v-slot:body>
        <AppSearch placeholder="Search..." v-model="searchValue" @click.stop />
        <AppFilterControlPanel :currentFilterType="propertiesStore.currentFilterType" @clearAll="clearAll" />
        <div class="category-list-container">
          <AppCategoryList :filteredOptions="filteredOptions" />
          <div v-if="!filteredOptions.length" class="no-results">
            We could not find any results based on your search. Please adjust your filters and search again.
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-container">
          <div class="footer-item">
            <AppIconCopy />
            <a @click="handleMangeGroupsModal">Manage groups</a>
          </div>
          <div class="footer-item">
            <AppIconAdd />
            <a @click="handleOpenNewGroupsModal">New group</a>
          </div>
        </div>
      </template>
    </AppMultiSelector>
  </AppVisibleFilterWrapper>
</template>

<style scoped lang="scss">
@import '../../../styles/settings/mixins';

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.footer-item {
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  background: none;
  border: none;
  border-radius: 7px;
  display: flex;
  width: 100%;
  text-align: left;
  padding: 7px 42px 7px 8px;
  color: inherit;
  cursor: pointer;
}

.footer-item:hover {
  background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);

  & svg {
    fill: var(--primary-color);
  }
}

:deep(.search-alt) > .search__btn {
  padding: 0 0 0 40px;
}

.category-list-container {
  margin: 5px 0 15px 0;
  max-height: 300px; // Set this to whatever height you want
  overflow-y: auto; // This will add a scrollbar when the content exceeds the max-height

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 9px;
    transition:
      width 0.5s ease,
      background-color 0.5s ease; /* Increase transition duration */
    scrollbar-gutter: auto;
  }

  &::-webkit-scrollbar-track {
    background: var(--property-card-bg);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }
}
</style>
