<!-- LoginHeader.vue -->
<script setup>
import { ref, computed, watchEffect } from 'vue';
import { useLoginStore } from '@/stores/LoginStore';

const loginStore = useLoginStore();
const step = computed(() => loginStore.step);
const isTransitioning = ref(false);

// Function to get the current day of the week
const getDayOfWeek = () => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const date = new Date();
  return days[date.getDay()];
};

const currentDay = ref(getDayOfWeek());

// Update the day of the week every midnight
const updateDayOfWeek = () => {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(now.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  const msUntilMidnight = tomorrow - now;
  setTimeout(() => {
    currentDay.value = getDayOfWeek();
    updateDayOfWeek();
  }, msUntilMidnight);
};

updateDayOfWeek();

const startTransition = () => {
  isTransitioning.value = true;
};
const endTransition = () => {
  isTransitioning.value = false;
};

const dynamicText = computed(() => {
  // Check for validation error first
  if (loginStore.validationError && loginStore.step === 'email') {
    return {
      mainHeading: 'Oops, username is incorrect.',
      subHeading: 'Please enter valid username:',
    };
  } else if (loginStore.validationError && loginStore.step === 'password') {
    return {
      mainHeading: 'Hm, password is incorrect.',
      subHeading: 'Please enter valid password:',
    };
  }

  // Then check for the step
  if (step.value === 'email') {
    return {
      mainHeading: `Hey there, happy ${currentDay.value}!`,
      subHeading: 'Enter your username:',
    };
  } else if (step.value === 'password') {
    return {
      mainHeading: 'Thanks!',
      subHeading: 'Enter your password:',
    };
  }

  return {};
});

const dynamicTextKey = ref(0);

watchEffect(() => {
  dynamicText.value; // Access dynamicText to set up a dependency
  dynamicTextKey.value++; // Increment the key to force a re-render
});
</script>

<template>
  <div class="login-header">
    <div class="kyzen-logo">
      <img src="@/assets/svg/kyzen-logo.svg" alt="Kyzen Analytics" srcset="" />
    </div>
    <transition name="fade" mode="out-in" @before-enter="startTransition" @after-enter="endTransition">
      <div :key="dynamicTextKey" class="login-text">
        <div class="main-heading">
          {{ dynamicText.mainHeading }}
        </div>
        <div class="sub-heading">
          {{ dynamicText.subHeading }}
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.login-header {
  margin-top: 100px;

  .kyzen-logo {
    text-align: center;
  }

  .login-text {
    text-align: center;

    .main-heading {
      color: #fff;
      font-size: 60px;
      padding-top: 35px;
      font-family: 'Hero New', sans-serif;
      font-weight: 400;

      @mixin mobile {
        font-size: 36px;
      }
    }

    .sub-heading {
      color: #fff;
      font-size: 45px;
      padding-top: 10px;
      font-family: 'Hero New', sans-serif;
      font-weight: 300;

      @mixin mobile {
        font-size: 25px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.25s ease-in-out,
    transform 0.33s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px); // Move up by 20 pixels
}
</style>
