<!-- AppLogoLight.vue -->
<script setup>

</script>

<template>
    <svg class="logo" width="137" height="45" viewBox="0 0 137 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.4927 24.5983L67.3847 18.7031H72.0787L64.9297 25.6398L72.7661 34.2468H67.915L61.4927 27.0743V34.2468H57.604V12.0415H61.4927V24.5983Z" fill="white"/>
        <path d="M75.6336 36.3887C77.8726 36.4869 79.1295 36.4476 80.5632 36.4476C83.2932 36.4476 84.2752 35.7009 84.2752 32.7926V32.4978C83.47 33.1463 82.6647 33.6572 80.033 33.6572C76.1835 33.6572 74.082 32.2817 74.082 28.5677V18.7031H77.9708V27.8013C77.9708 29.5895 78.1475 30.5524 80.8775 30.5524C83.7449 30.5524 84.2949 29.8057 84.2949 27.5262V18.7031H88.1836V32.8122C88.1836 37.6266 86.1214 39.5524 80.7596 39.5524C79.0902 39.5524 77.8922 39.5917 75.0248 39.4935L75.6336 36.3887Z" fill="white"/>
        <path d="M95.1558 31.1223H103.896L103.267 34.2664H90.344V31.5153L98.5928 21.8865H90.3047L90.9332 18.7227H103.424V21.5131L95.1558 31.1223Z" fill="white"/>
        <path d="M109.493 27.8014C109.493 30.6704 110.004 31.3385 112.871 31.3385C114.285 31.3385 115.955 31.3778 118.586 31.2795L117.997 34.2664C115.778 34.3647 114.58 34.3254 112.714 34.3254C107.058 34.3254 105.624 31.7315 105.624 27.2905V25.6202C105.624 20.8254 107.176 18.2315 112.832 18.2315C118.056 18.2315 119.647 20.6878 119.647 24.0285V24.5394C119.647 27.2905 119.058 27.8014 117.29 27.8014H109.493ZM109.493 24.952H115.915V24.2643C115.915 21.9651 115.228 21.1595 112.714 21.1595C109.984 21.1595 109.493 21.9455 109.493 24.952Z" fill="white"/>
        <path d="M121.847 34.2664V23.6747C121.847 19.725 124.027 18.2315 128.956 18.2315C133.886 18.2315 136.046 19.725 136.046 23.6747V34.2664H132.158V24.166C132.158 22.3385 131.922 21.4346 128.956 21.4346C125.991 21.4346 125.735 22.3385 125.735 24.166V34.2664H121.847Z" fill="white"/>
        <path d="M44.9757 22.5C44.9757 34.9389 34.9003 45 22.4878 45C10.0753 45 0 34.9389 0 22.5C0 10.0808 10.0753 0 22.4878 0C34.92 0 44.9757 10.0808 44.9757 22.5Z" fill="url(#paint0_linear_26_23)"/>
        <path d="M42 22C42 33.0568 33.0393 42 22 42C10.9607 42 2 33.0568 2 22C2 10.9607 10.9607 2 22 2C33.0568 2 42 10.9607 42 22Z" fill="url(#paint1_linear_26_23)" fill-opacity="0.25"/>
        <path d="M20.1704 9.52832H14.082L20.1704 15.62V9.52832Z" fill="white"/>
        <path d="M20.1704 33.4824V27.7641L25.8857 33.4824H33.4275L25.7286 25.7794L32.5829 18.9213H25.0412L20.1704 23.775V18.9213L14.082 12.8296V33.4824H20.1704Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_26_23" x1="3.5" y1="4" x2="45" y2="62" gradientUnits="userSpaceOnUse">
        <stop stop-color="#00FF0A"/>
        <stop offset="0.312441" stop-color="#00B43C"/>
        </linearGradient>
        <linearGradient id="paint1_linear_26_23" x1="5.11279" y1="5.55556" x2="41.9952" y2="57.13" gradientUnits="userSpaceOnUse">
        <stop/>
        <stop offset="0.312441" stop-color="#00B43C"/>
        </linearGradient>
        </defs>
    </svg>

</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
  display: inline-block;
  width: 137px;
  vertical-align: middle;

  /*  Mobile  */

  @include mobile {
    width: 120px;
  }

  > svg {
    width: 90%;

    /*  Mobile  */

    @include mobile {
      display: none;
    }

    + svg {
      display: none;

      /*  Mobile  */

      @include mobile {
        display: inline-block;
      }
    }
  }
}

</style>
