<script setup>
// Imports...
import { computed, onUnmounted, ref } from 'vue';
import { GridLayout } from 'grid-layout-plus';
import { getComponent } from '@/helpers/WidgetRegistry';
import { useDashboardsStore } from '@/stores/DashboardsStore';
import AppIconTrash from '@/components/svg/AppIconTrash.vue';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import DashboardLandingPage from '@/components/dashboard/LandingPage.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppIconAdd from '@/components/svg/AppIconAdd.vue';

// Logic...
const dashboardsStore = useDashboardsStore();

const gridLayout = ref();
dashboardsStore.setGridLayout(gridLayout);

const wrapper = ref();
dashboardsStore.setWrapper(wrapper);

const attemptReload = () => {
  dashboardsStore.loadLayout(dashboardsStore.selectedDashboard);
};

const showLandingPage = computed(() => {
  return !dashboardsStore.layoutLoaded && !dashboardsStore.isLoading && !dashboardsStore.dashboardError;
});

const showErrorMessage = computed(() => {
  return dashboardsStore.dashboardError && !dashboardsStore.isLoading;
});

const showLayoutLoaded = computed(() => {
  return dashboardsStore.layoutLoaded && !dashboardsStore.isLoading;
});

const showNewWidgetContainer = computed(() => {
  return dashboardsStore.dashboardData.layout.length === 0 && !dashboardsStore.personalDragging && !dashboardsStore.dashboardError;
});

const showTrashCan = computed(() => {
  return dashboardsStore.isEditMode && !dashboardsStore.personalDragging;
});

onUnmounted(() => {
  dashboardsStore.resetState();
});
</script>

<template>
  <div class="styles_view__body" ref="wrapper">
    <DashboardLandingPage v-if="showLandingPage" />

    <!-- Show loading spinner while data is loading -->
    <div v-if="dashboardsStore.isLoading" class="center-spinner">
      <AppSpinner />
    </div>

    <!-- Display message for any errors for the dashboard -->
    <div v-if="showErrorMessage">
      <div class="alert-banner">
        <!-- Message when dashboard data might be missing or corrupted -->
        <div>
          <p class="alert-text">Unable to find dashboard. This might be an error.</p>
        </div>
        <div class="alert-button-container">
          <AppButton class="alert-button" @click="attemptReload">Reload Dashboard</AppButton>
          <AppButton class="alert-button" @click="dashboardsStore.createNewDashboard">Create New Dashboard</AppButton>
        </div>
      </div>
    </div>

    <div class="wrapper-container" v-if="showLayoutLoaded">
      <div class="new-widget-container" v-if="showNewWidgetContainer">
        <div class="new-widget-icon">
          <AppIconAdd />
          <p>Customize</p>
        </div>
        <div class="new-widget-text">
          <p>Customize this dashboard by dragging elements from the sidebar.</p>
        </div>
      </div>
      <GridLayout
        ref="gridLayout"
        v-model:layout="dashboardsStore.dashboardData.layout"
        :row-height="30"
        :use-style-cursor="true"
        :is-draggable="dashboardsStore.isEditMode"
        :is-resizable="dashboardsStore.isEditMode"
        :class="{ 'no-select': dashboardsStore.isEditMode }"
        :margin="[15, 15]"
      >
        <template #item="{ item }">
          <div class="widget-container">
            <!-- vue's way of caching components -->
            <keep-alive>
              <component :is="getComponent([item.component])" :key="item.i"></component>
            </keep-alive>
            <div v-if="dashboardsStore.isEditMode" class="edit-overlay"></div>
          </div>
          <div v-if="showTrashCan" class="remove" @click="dashboardsStore.removeItem(item.i)">
            <AppIconTrash class="icon-trash-can" color="#d93434" />
          </div>
        </template>
      </GridLayout>
    </div>
  </div>
</template>

<style scoped lang="scss">
.widget-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.edit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--property-card-bg-rgb), 0.5); /* Semi-transparent */
  border: var(--border-size) solid var(--secondary-color); /* Semi-transparent */
  z-index: 5; /* Below interactive elements */
  pointer-events: all; /* Catch mouse events */
  border-radius: 18px;
  transition: border 0.3s ease;

  &:hover {
    border: var(--border-size) solid var(--primary-color);
  }
}

.remove {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10; /* Ensures it's above the overlay */
}

.wrapper-container {
  height: 100%;
  width: 100%;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

/*
Library styles for the grid layout
with some customizations and overrides
 */
:deep(.vgl-item--placeholder) {
  opacity: 100%;
  z-index: var(--vgl-placeholder-z-index, 1000);
  user-select: none;
  transition-duration: 100ms;
  background-color: var(--page-bg-color);
  border: 1.5px dashed var(--primary-color);
  border-radius: 18px;
  box-shadow: 0 1px 37px 5px rgba(0, 0, 0, 0.23) inset;
  -webkit-box-shadow: 0 1px 37px 5px rgba(0, 0, 0, 0.23) inset;
  -moz-box-shadow: 0 1px 37px 5px rgba(0, 0, 0, 0.23) inset;
}

:deep(.vgl-item__resizer) {
  transition: all 0.3s ease;
  background-image: url('@/assets/svg/ico-expand.svg');
  background-repeat: no-repeat;
  width: 17px; // Set the width of the handle
  height: 16px; // Set the height of the handle
  position: absolute;
  right: 6px; // Position it to the right
  bottom: 3px; // Position it to the bottom
  top: auto; // Override any potential conflicting rules
  z-index: 10;
}

.icon-trash-can {
  width: 21px;
  height: 21px;
}

.remove {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 32px;
  bottom: 5px;
  top: auto;
  z-index: 10;
  padding: 4px;
  background: var(--filter-nav-bg);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--secondary-color-muted);
  transition: border 0.3s ease;

  &:hover {
    border: 1px solid var(--error-color);
  }
}

:deep(.vgl-item__resizer:before) {
  inset: 0 4px 3px 0;
  border: 0 solid transparent;
  border-right-width: 2px;
  border-bottom-width: 2px;
}

:deep(.vgl-item:not(.vgl-item--placeholder)) {
  background-color: var(--property-card-bg);
  border-radius: 18px;
  transition-property: none;
}

.new-widget-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  align-items: center;
  border: 1px dashed var(--secondary-color);
  padding: 30px;
  border-radius: 18px;
  max-width: 100%;
  margin: 15px;
}

.new-widget-icon {
  display: flex;
  flex-direction: row;
  font-size: 22px;
  color: var(--primary-text-color);
  align-items: center;
  gap: 10px;
}

.new-widget-text {
  color: var(--primary-text-color);
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
}

.alert-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.49);
  padding: 5px;
  border-radius: 5px;
}

.alert-text {
  font-family: 'Hero New', sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.alert-button {
  min-width: 30px;
  min-height: 30px;
  padding: 5px 10px;
}

.alert-button-container {
  display: flex;
  gap: 10px;
}

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.preview-component {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  padding-right: 30px;
}

.styles_view__body {
  padding: 0;
}
</style>
