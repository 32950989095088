import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styles/main.scss';
import { createPinia } from 'pinia';
import { useAuthStore } from './stores/AuthStore'; // Update the path as per your project structure

const pinia = createPinia();
const app = createApp(App);

// Initialize the authentication state from local storage
const authStore = useAuthStore(pinia); // Pass the pinia instance to the store
authStore.initializeFromSessionStorage();

const routerInstance = router(pinia); // Pass the pinia instance to the router factory
app.use(routerInstance);
app.use(pinia);
app.mount('#app');
