// store.js
import { defineStore } from 'pinia';

export const useBudgetStore = defineStore('BudgetStore', {
  state: () => ({
    currentBudgetView: 'table',
  }),
  actions: {
    toggleView(view) {
      this.currentBudgetView = view;
      localStorage.setItem('currentBudgetView', view);
    },
    loadInitialView() {
      this.currentBudgetView = localStorage.getItem('currentBudgetView') || 'table';
    },
  },
});
