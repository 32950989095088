<!-- LoginSubmitButton.vue -->
<script setup>
import { ref } from 'vue';
import { useLoginStore } from '@/stores/LoginStore';

const loginStore = useLoginStore();
const isLoading = ref(false);

const handleClick = async () => {
  isLoading.value = true;
  await new Promise((resolve) => setTimeout(resolve, 650)); // Simulated delay
  isLoading.value = false;
};
</script>

<template>
  <div>
    <template v-if="isLoading">
      <!-- Loading spinner -->
      <span class="spinner"></span>
    </template>
    <template v-else>
      <!-- Original button -->
      <svg
        @click="handleClick"
        :disabled="isLoading"
        class="angle-right-icon opacity"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
      >
        <circle
          cx="25"
          cy="25"
          r="24.0122"
          stroke="#00F93D"
          stroke-width="1.97561"
          :class="{
            invalidBtn: loginStore.isEmailInvalid || loginStore.isPasswordInvalid,
          }"
        />
        <path
          d="M20.7314 32.9269L29.8778 25.0001L20.7314 17.0732"
          stroke="#02E84F"
          stroke-width="1.97561"
          stroke-linecap="round"
          stroke-linejoin="round"
          :class="{
            invalidBtn: loginStore.isEmailInvalid || loginStore.isPasswordInvalid,
          }"
        />
      </svg>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.angle-right-icon {
  pointer-events: auto;
  position: absolute;
  right: 26px;
  transform: translateY(-50%);
}

.invalidBtn {
  stroke: #c81f25 !important;
  // animation: shake 0.2s;
}

.spinner {
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    right: 32px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid #ffffff;
    border-right: 2px solid transparent;
    animation: spinner 0.5s linear infinite;
    opacity: 0.5;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(2px);
  }

  75% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
