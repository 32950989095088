import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/AuthStore';

/*
 * Views
 */
import LoginView from '@/views/LoginView.vue';
import DashboardsView from '@/views/DashboardsView.vue';
import ReportsView from '@/views/ReportsView.vue';
import SettingsView from '@/views/SettingsView.vue';
import BudgetsView from '@/views/BudgetsView.vue';
import UsersView from '@/views/UsersView.vue';
import AlertsView from '@/views/AlertsView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import PropertiesView from '@/views/PropertiesView.vue';
import PropertiesTopFilterSection from '@/components/properties/TopFilterSection.vue';
import PropertiesPageHeader from '@/components/properties/PageHeader.vue';
import DashboardsTopFilterSection from '@/components/dashboard/TopFilterSection.vue';
import DashboardPageHeader from '@/components/dashboard/PageHeader.vue';
import ReportsPageHeader from '@/components/reports/PageHeader.vue';
import ReportsTopFilterSection from '@/components/reports/TopFilterSection.vue';
import BudgetsPageHeader from '@/components/budgets/PageHeader.vue';
import BudgetsTopFilterSection from '@/components/budgets/TopFilterSection.vue';
import AlertsPageHeader from '@/components/alerts/PageHeader.vue';
import AlertsTopFilterSection from '@/components/alerts/TopFilterSection.vue';
import UsersPageHeader from '@/components/users/PageHeader.vue';
import UsersTopFilterSection from '@/components/users/TopFilterSection.vue';
import SettingsTopFilterSection from '@/components/settings/TopFilterSection.vue';
import SettingsPageHeader from '@/components/settings/PageHeader.vue';

export default function createRouterInstance(pinia) {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'Login',
        component: LoginView,
      },
      {
        path: '/properties',
        name: 'Properties',
        components: {
          default: PropertiesView,
          topFilter: PropertiesTopFilterSection,
          pageHeader: PropertiesPageHeader,
        },
        meta: { requiresAuth: true },
      },
      {
        path: '/dashboards',
        name: 'Dashboards',
        components: {
          default: DashboardsView,
          topFilter: DashboardsTopFilterSection,
          pageHeader: DashboardPageHeader,
        },
        meta: { requiresAuth: true },
      },
      {
        path: '/reports',
        name: 'Reports',
        components: {
          default: ReportsView,
          topFilter: ReportsTopFilterSection,
          pageHeader: ReportsPageHeader,
        },
        meta: { requiresAuth: true },
      },
      {
        path: '/budgets',
        name: 'BudgetView',
        components: {
          default: BudgetsView,
          topFilter: BudgetsTopFilterSection,
          pageHeader: BudgetsPageHeader,
        },
        meta: { requiresAuth: true },
      },
      {
        path: '/users',
        name: 'Users',
        components: {
          default: UsersView,
          topFilter: UsersTopFilterSection,
          pageHeader: UsersPageHeader,
        },
        meta: { requiresAuth: true },
      },
      {
        path: '/alerts',
        name: 'Alerts',
        components: {
          default: AlertsView,
          pageHeader: AlertsPageHeader,
          topFilter: AlertsTopFilterSection,
        },
        meta: { requiresAuth: true },
      },
      {
        path: '/settings',
        name: 'Settings',
        components: {
          default: SettingsView,
          topFilter: SettingsTopFilterSection,
          pageHeader: SettingsPageHeader,
        },
        meta: { requiresAuth: true },
      },
      {
        path: '/:notFound(.*)',
        component: NotFoundView,
        meta: { requiresAuth: false },
      },
    ],
  });

  router.beforeEach((to, from, next) => {
    const authStore = useAuthStore(pinia); // Use the passed-in pinia instance

    // Check if the user is navigating to the Login page while already authenticated
    if (authStore.isLoggedIn && to.name === 'Login') {
      next('/properties'); // Redirect to Dashboards or a preferred authenticated route
    } else if (!authStore.isLoggedIn && to.meta.requiresAuth) {
      next('/'); // Redirect to login if trying to access a protected route without authentication
    } else {
      next(); // Proceed as normal if none of the above conditions are met
    }
  });

  return router;
}
