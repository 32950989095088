<!-- TheUserMenu.vue -->
<script setup>
import { ref } from 'vue';
import AppOverlayMenu from "@/components/ui/AppOverlayMenu.vue";
import TheActiveUserPanel from "@/components/TheActiveUserPanel.vue";
import TheAppLinks from "@/components/TheAppLinks.vue";
import {onClickOutside} from "@vueuse/core/index";
// Logic...
const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

function handleOptionClicked(option) {
  // Handle the option clicked logic here
  console.log(`Option ${option} clicked`);
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

</script>

<template>
  <div class="user-menu" ref="target">
    <div class="avatar--img" @click="toggleOverlay">
      <img src="../assets/images/avatar-main.png" alt="profile">
    </div>
    <AppOverlayMenu
        v-if="isOverlayVisible"
        @option-clicked="handleOptionClicked"
        :options="['activeUserPanel', 'appLinks']"
    >
      <template #activeUserPanel>
        <TheActiveUserPanel />
      </template>
      <template #appLinks>
        <TheAppLinks />
      </template>
    </AppOverlayMenu>
  </div>
</template>

<style lang="scss" scoped>

.user-menu {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;


  .avatar--img {
    width: 40px;
    height: 40px;
    margin-right: 10px; // Adjust as needed
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--border-color);
    box-shadow: 0 0 0 2px var(--border-color);
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0 0 2px var(--primary-color);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

:deep(.overlay-menu) {
  min-width: 325px;
  top: 42px;
}

:deep(.overlay-menu ul) {
  padding: 0;
}

:deep(.overlay-menu li) {
  opacity: 100;

  &:hover {
    opacity: 1;
    background: transparent;
    cursor: default;
    transition: none;
  }
}
</style>
