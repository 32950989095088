<!-- BudgetTableHeader.vue -->
<script setup>
import { useBudgetTableStore } from '@/stores/BudgetTableStore';

const budgetTableStore = useBudgetTableStore();
</script>

<template>
  <div class="div-row div-header">
    <div class="column-cell">
      <!-- <AppSearch/> -->
    </div>
    <div class="column-cell" v-for="month in budgetTableStore.months" :key="month">
      {{ month.toUpperCase() }}
    </div>
    <div class="column-cell">Yearly Totals</div>
  </div>
</template>

<style scoped lang="scss">
.div-row {
  display: flex;
  flex-wrap: nowrap;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }
}

.div-header {
  text-align: left;
  position: sticky;
  left: 0;
  min-width: 400px;
}

.column-cell {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  border-bottom: var(--border-size) solid var(--secondary-color);
  border-right: var(--border-size) solid var(--secondary-color);

  &.focused {
    box-shadow: inset 0 0 0 1px var(--primary-color);
  }
}

.column-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  font-weight: 400;
  transition: background-color 0.3s ease;
  flex-basis: calc(100% / 13 * 2);
  min-width: 280px;
}

.column-cell:first-child {
  justify-content: flex-start;
  font-weight: 600;
  min-width: 400px;
  padding-left: 20px;
}

.column-cell:last-child {
  border-right: none;
}
</style>
