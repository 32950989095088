<!-- Users/TableLayout.vue -->
<script setup>
import { useTableSortingStore } from '@/stores/TableSortingStore';
import { computed } from 'vue';
import defaultAvatar from '@/assets/images/default-avatar.png';
import SimpleBar from 'simplebar-vue';
import useTableStyles from '@/composables/useTableStyles';
import { useUsersStore } from '@/stores/UsersStore';

const usersStore = useUsersStore();
const { cellStyle } = useTableStyles(usersStore.users, 13);
const tableStore = useTableSortingStore();
const tableId = 'usersTable';

// Define the columns array to be used in the table rendering
const columns = [
  { key: 'email', displayName: 'Username' },
  { key: 'client', displayName: 'Client' },
  { key: 'user_level', displayName: 'User Level' },
  { key: 'date_added', displayName: 'Date Added' },
  { key: 'last_sign_in', displayName: 'Last Sign In' },
];

const handleSort = (dataKey) => tableStore.setSorting(tableId, dataKey);
const arrowDirection = (dataKey) => tableStore.arrowDirection(tableId, dataKey);
const sortedData = computed(() => tableStore.getSortedData(tableId, usersStore.users));

const getImageUrl = (avatarUrl) => (avatarUrl ? require('@/assets/images/' + avatarUrl) : defaultAvatar);
</script>

<template>
  <div class="wrapper">
    <SimpleBar>
      <div class="div-column-row">
        <div v-for="column in columns" :key="column.key" :style="cellStyle" class="header-cell sortable" @click="handleSort(column.key)">
          {{ column.displayName }}
          <span class="sort-styles" v-html="arrowDirection(column.key)"></span>
        </div>
      </div>
      <div v-if="sortedData.length > 0">
        <div class="row-container" v-for="row in sortedData" :key="row">
          <div v-for="column in columns" :key="column.key" :style="cellStyle" class="cell">
            <img v-if="column.key === 'email'" :src="getImageUrl(row.avatar)" alt="avatar" class="avatar" />
            <span v-if="column.key === 'email'">{{ row[column.key] }}</span>
            <span v-else-if="column.key === 'user_level'" class="pill">{{ row[column.key] }}</span>
            <span v-else>{{ row[column.key] }}</span>
          </div>
        </div>
      </div>
      <div v-else class="cell">No results</div>
    </SimpleBar>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/settings/mixins';
@import 'simplebar-vue/dist/simplebar.min.css';

.wrapper {
  display: flex;
  flex-direction: column;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 18px;
  overflow-x: auto;
}

:deep(.simplebar-scrollbar::before) {
  background-color: var(--secondary-color);
}

.div-column-row {
  display: flex;
  min-width: max-content;
  border-bottom: var(--border-size) solid var(--secondary-color);
}

.header-cell {
  flex: 1 0 var(--cell-base-size);
  min-width: var(--cell-base-size);
  padding: 15px;
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary-color);
  text-align: left;
  transition: color 0.3s ease;
}

.sort-styles {
  font-size: 12px;
  margin-left: 10px;
  align-self: start;
  vertical-align: middle;
}

.sortable:hover {
  cursor: pointer;
}

.row-container {
  display: flex;
  min-width: max-content;
  border-bottom: var(--border-size) solid var(--secondary-color);
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }

  &:last-child {
    border-bottom: none;
  }
}

.cell {
  flex: 1 0 var(--cell-base-size);
  min-width: var(--cell-base-size);
  padding: 15px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  transition: background-color 0.3s ease;

  &:first-child {
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.pill {
  border: 1px solid var(--primary-color);
  border-radius: 50px;
  margin: 0;
  padding: 5px 15px;
  display: inline-block;
  font-size: 0.65em;
  color: var(--primary-text-color);
}
</style>
