// Imports...
import { defineStore } from "pinia";

export const useTableSortingStore = defineStore('TableSortingStore', {
  state: () => ({
    sorting: {}
  }),
  getters: {
    getSortedData: (state) => (tableId = 'defaultTable', data = []) => {
      const sortingInfo = state.sorting[tableId];
      if (sortingInfo && Array.isArray(data)) {
        const { dataKey, direction } = sortingInfo;

        return [...data].sort((a, b) => {
          let valA, valB;

          if ('attributes' in a && 'attributes' in b) {
            valA = a.attributes[dataKey];
            valB = b.attributes[dataKey];
          } else {
            valA = a[dataKey];
            valB = b[dataKey];
          }

          if (valA !== undefined && valB !== undefined) {
            return direction === 'asc' ? (valA > valB ? 1 : -1) : (valA < valB ? 1 : -1);
          }
          return 0;
        });
      }
      return data;
    },
    arrowDirection: (state) => (tableId, dataKey) => {
      const sortingInfo = state.sorting[tableId] || {dataKey: 'nonexistent', direction: 'asc'};
      return sortingInfo.dataKey === dataKey ? (sortingInfo.direction === 'desc' ? '&#8593;' : '&#8595;') : '&#8597;';
    }
  },
  actions: {
    setSorting(tableId, dataKey) {
      const sortingInfo = this.sorting[tableId] || {dataKey: 'nonexistent', direction: 'asc'};
      const newDirection = sortingInfo.dataKey === dataKey && sortingInfo.direction === 'asc' ? 'desc' : 'asc';
      this.sorting = { ...this.sorting, [tableId]: { dataKey, direction: newDirection }}
    },
  }
});
