import { icons, previews } from '@/helpers/IconRegistry';
import { largeWidget, smallWidget } from '@/helpers/WidgetSizes';
import { v4 as uuidv4 } from 'uuid';
import { mediumWidget } from '../WidgetSizes';

const commonFields = {
  integration: 'Meta Ads',
  icon: icons.AppMetaAdsIcon,
};

export const metaAdsWidgets = [
  {
    title: 'Conversions',
    type: 'Metric',
    description: 'Lorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'ConversionsMetric',
    i: uuidv4(),
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Click Activity',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    component: 'ClicksLine',
    i: uuidv4(),
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Conversions Activity',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    component: 'ConversionsLine',
    i: uuidv4(),
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Spend',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'SpendMetric',
    i: uuidv4(),
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Cost-Per-Conversion',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'CostPerConvMetric',
    i: uuidv4(),
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Impressions',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    component: 'ImpressionsMetric',
    i: uuidv4(),
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Campaigns',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    component: 'CampaignsTable',
    i: uuidv4(),
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Demographics',
    type: 'Donut Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppDonutPreview,
    component: 'DemographicsDonut',
    i: uuidv4(),
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Gender Targeting',
    type: 'Donut Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppDonutPreview,
    component: 'GenderDonut',
    i: uuidv4(),
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Placement Performance',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    component: 'PlacementPerformanceTable',
    i: uuidv4(),
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Creative Performance',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    component: 'CreativePerformanceTable',
    i: uuidv4(),
    ...largeWidget,
    ...commonFields,
  },
];
