

export const budgetOptionsChartData = {
	responsive: true,
	maintainAspectRatio: false,
	title: {
		display: true,
		text: "Line Chart Example",
	},
	tooltips: {
		mode: "index",
		intersect: false,
	},
	scales: {
		y: {
			min: 0,
			max: 3000,
			ticks: {
				font: {
					size: 14,
				},
				callback: function (value) {
					return "$" + value;
				},
			},
			labels: {
				callback: function (value) {
					return "$" + value;
				},
			},
			grid: {
				display: false
			}
		},
		x: {
			ticks: {
				font: {
					size: 14,
				},
			},
		},
	},
	plugins: {
		legend: {
			display: false,
		},
	},
};

export const budgetOptionsData = [
	{
		label: 'Current',
		value: 'current'
	},
	{
		label: 'Working',
		value: 'working'
	},
	{
		label: 'Reduced',
		value: 'Reduced'
	}
];

export const budgetYearOptionsData = [
	{
		label: '2020',
		value: '2020'
	},
	{
		label: '2021',
		value: '2021'
	},
	{
		label: '2022',
		value: '2022'
	},
	{
		label: '2023',
		value: '2023'
	},
	{
		label: '2024',
		value: '2024'
	},
	{
		label: '2025',
		value: '2025'
	},
	{
		label: '2026',
		value: '2026'
	},
	{
		label: '2027',
		value: '2027'
	},
];