<!-- AppIconSearchSmall.vue -->
<script setup>

</script>

<template>
    <svg class="icon-color" width="17" height="17" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="colorize" d="M25.7145 23.2363L19.7239 17.1873C20.7417 15.5527 21.3318 13.619 21.3318 11.5436C21.3318 5.67184 16.6178 0.911926 10.8027 0.911926C4.98763 0.911926 0.275879 5.67184 0.275879 11.5436C0.275879 17.4155 4.98982 22.1754 10.8049 22.1754C13.0423 22.1754 15.1131 21.4688 16.8175 20.2705L22.7357 26.2486C23.148 26.6628 23.6855 26.871 24.2251 26.871C24.7647 26.871 25.3021 26.6651 25.7145 26.2486C26.5371 25.4136 26.5371 24.0669 25.7145 23.2363ZM10.8049 18.7289C6.87408 18.7289 3.68905 15.5106 3.68905 11.5436C3.68905 7.57669 6.87408 4.35616 10.8049 4.35616C14.7358 4.35616 17.9208 7.57447 17.9208 11.5414C17.9208 15.5084 14.7336 18.7289 10.8049 18.7289Z" />
    </svg>

</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}</style>
