<!-- AppIconAdd.vue -->
<script setup>


</script>

<template>
  <svg class="icon-color" width="21" height="21" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="colorize" fill-rule="evenodd" clip-rule="evenodd"
          d="M11.8965 6.80963C11.8965 6.32638 11.5047 5.93463 11.0215 5.93463C10.5383 5.93463 10.1465 6.32638 10.1465 6.80963V10.125H6.80966C6.32641 10.125 5.93466 10.5168 5.93466 11C5.93466 11.4832 6.32641 11.875 6.80966 11.875H10.1465V15.1906C10.1465 15.6738 10.5383 16.0656 11.0215 16.0656C11.5047 16.0656 11.8965 15.6738 11.8965 15.1906V11.875H15.2336C15.7169 11.875 16.1086 11.4832 16.1086 11C16.1086 10.5168 15.7169 10.125 15.2336 10.125H11.8965V6.80963Z"
          />
    <path class="colorize" fill-rule="evenodd" clip-rule="evenodd"
          d="M11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
          />
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
