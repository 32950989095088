<script setup>
import { ref } from 'vue';
import { useReportsStore } from '@/stores/ReportsStore';

const reportsStore = useReportsStore();

// temporary placeholder data for the cards
const reports = ref([
  { id: 1, label: 'Benchmark', date: 'May 14 2024' },
  { id: 2, label: 'ETA', date: 'Aug 11 2024' },
  { id: 3, label: 'Extension', date: 'Nov 14 2024' },
  { id: 4, label: 'Keyword', date: 'Jun 9 2024' },
  { id: 5, label: 'RSA', date: 'Jul 4 2024' },
  { id: 6, label: 'Trending', date: 'Jul 19 2024' },
]);
</script>

<template>
  <div class="report-grid">
    <div v-for="report in reports" :key="report.id" class="report-block">
      <div class="report-content">
        <h3>{{ report.label }}</h3>
        <p class="last-edited">
          Last edited on <span>{{ report.date }}</span>
        </p>
        <p class="description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent
          taciti sociosqu ad litora.
        </p>
      </div>
      <div class="buttons">
        <button class="btn view" @click="reportsStore.loadReport(report.id)">View</button>
        <button class="btn btn-outlined">Option</button>
        <button class="btn btn-outlined">Option</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/settings/_mixins.scss';

.report-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.report-block {
  color: #fff;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  background-color: var(--property-card-bg);
  transition: all 0.3s ease;
}

.report-content {
  padding: 25px;
}

h3 {
  font-size: 1.2rem;
  margin: 0;
}

.last-edited {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  margin: 5px 0 10px;
}

.description {
  color: var(--primary-text-color);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  background-color: #121e2a;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.btn {
  width: 129px;
  height: 30px;
  padding: 6.169px 18.712px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48.781px;
  border: 1px solid #00b43c;
  background: #00b43c;
  transition: all 0.4s ease;

  &:hover {
    // Temporary colors here - working with design team on button hover variables
    background-color: darken(#00b43c, 10%);
    border-color: darken(#00b43c, 10%);
  }

  &.btn-outlined {
    background: none;
    border-color: var(--primary-text-color);
    &:hover {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
}

.view {
  background-color: #28a745;
  color: var(--primary-text-color);
}

.outlined-btn {
  background-color: transparent;
  border: 1px solid var(--primary-text-color);
  color: var(--primary-text-color);
}

// Medium screens
@media (max-width: 1475px) {
  .report-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

// Small screens
@media (max-width: 768px) {
  .report-grid {
    grid-template-columns: 1fr;
  }
}
</style>
