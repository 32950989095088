<!-- BudgetsTopFilterSection.vue -->
<script setup>
// imports...
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import { budgetOptionsData, budgetYearOptionsData } from '@/data/budgets.js';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import { ref, onMounted } from 'vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import BudgetOptionsSelectorFooter from '@/components/budgets/top-filter-section-components/SelectorFooter.vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import { usePropertiesStore } from '@/stores/PropertiesStore';

// Logic...

// Store instances
const globalStore = useGlobalStore();
const propertiesStore = usePropertiesStore();
const originalProperties = ref([]); // Use ref for reactivity
// Handle visibility for the top filter navbar inputs and selectors
const handleClick = () => {
  globalStore.toggleVisibleFilter();
};

onMounted(async () => {
  // Set the selectedYear to the current year
  await propertiesStore.getProperties();
  originalProperties.value = propertiesStore.originalProperties;
  const currentYear = new Date().getFullYear();
  selectedYear.value = currentYear.toString();
});

function transformOption(option) {
  const { attributes, ...rest } = option;

  if (!attributes) {
    return { ...rest, name: 'Unknown', value: rest.id || 'Unknown', label: 'Unknown' };
  }

  return {
    ...rest,
    ...attributes,
    label: attributes.name,
    name: attributes.name,
    value: attributes.id,
  };
}

// Fetch properties on component mount
onMounted(async () => {});

const selectedYear = ref('');
const selectedBudgets = ref('');
const selectedProperty = ref('');
const reset = ref(false);
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!-- Property Selector -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          body-width="300px"
          title="Properties"
          placeholder="Select a property"
          :options="originalProperties.map(transformOption)"
          :show-search="true"
          v-model="selectedProperty"
        />
      </AppVisibleFilterWrapper>
      <!-- Budget Dropdown -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          title="Budget"
          placeholder="Select a budget"
          :options="budgetOptionsData"
          v-model="selectedBudgets"
          :show-search="true"
          footer-link="/properties"
          footer-text="New budget"
        >
          <template v-slot:footer>
            <BudgetOptionsSelectorFooter />
          </template>
        </AppSingleSelector>
      </AppVisibleFilterWrapper>
      <!-- Year Selector -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Year" placeholder="Select a year" :options="budgetYearOptionsData" v-model="selectedYear" :show-search="true" />
      </AppVisibleFilterWrapper>
      <!-- filter button-->
      <div class="filter-button-container">
        <div class="filter-clear-all" v-if="reset === true">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>
        <AppButton label="View" class="filter-button-bottom" />
      </div>
      <!-- Toggle Button for mobile -->
      <AppButton toggle_btn @click="handleClick">
        <AppIconAngleDownPair />
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/settings/mixins';

.grid {
  display: flex;
  align-items: center; // Vertically center items
  flex-wrap: wrap;
  gap: 15px;
}

.flex-auto {
  flex: 1 1 auto; // Flex item can grow and shrink but is initially sized to content
}

.visible-mobile {
  display: none;

  /*  Tablet  */

  @include tablet {
    display: block;
  }
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  text-decoration: underline;
  height: 15px; // Adjust to match the height of the reset button
  visibility: hidden; // Hide the placeholder but keep its space
}

.filter-clear-all {
  visibility: visible; // Make the actual button visible
  text-decoration: underline;

  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }

  &:active {
    opacity: 0.7; // Adjust as needed
  }
}
</style>
