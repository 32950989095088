<!-- EditTrendingReportModal.vue -->
<!-- EditTrendingReportModal.vue -->
<script setup>
import { onMounted, reactive } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import AppModal from '@/components/ui/AppModal.vue';
import AppTextInput from '@/components/ui/AppTextInput.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppMultiSelector from '@/components/ui/selects/AppMultiSelector.vue';
import AppCheckboxButton from '@/components/ui/buttons/AppCheckboxButton.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import { useBenchmarkReport } from '@/stores/BenchmarkReportStore';

const benchmarkReportStore = useBenchmarkReport();
const modalStore = useModalStore();

// Create local state
const localState = reactive({
  reportName: '',
  selectedRecurring: '',
  selectedPeriod: 3,
  numSelectedPeriod: 4,
  selectedReportOptions: [],
  selectedGroup: '',
  selectedCategoryOptions: [],
});

// Function to reset local state when modal is opened
function resetLocalState() {
  localState.reportName = benchmarkReportStore.reportName;
  localState.selectedRecurring = benchmarkReportStore.selectedRecurring;
  localState.selectedPeriod = benchmarkReportStore.selectedPeriod;
  localState.numSelectedPeriod = benchmarkReportStore.numSelectedPeriod;
  localState.selectedReportOptions = [...benchmarkReportStore.selectedReportOptions];
  localState.selectedGroup = benchmarkReportStore.selectedGroup;
  localState.selectedCategoryOptions = [...benchmarkReportStore.selectedCategoryOptions];
}

// Use onMounted hook to reset local state when component is mounted
onMounted(() => {
  resetLocalState();
});

// Toggle function for options
function toggleOption(option) {
  const index = localState.selectedReportOptions.indexOf(option.value);
  if (index > -1) {
    localState.selectedReportOptions.splice(index, 1);
  } else {
    localState.selectedReportOptions.push(option.value);
  }
}

// Toggle function for category options
function toggleCategoryOption(option) {
  const index = localState.selectedCategoryOptions.indexOf(option.value);
  if (index > -1) {
    localState.selectedCategoryOptions.splice(index, 1);
  } else {
    localState.selectedCategoryOptions.push(option.value);
  }
}

// Save changes to the store
function saveChanges() {
  benchmarkReportStore.updateReportSettings(localState);
  modalStore.closeModal('EditBenchmarkReportModal');
}
</script>

<template>
  <teleport to="body">
    <AppModal title="Edit Report" modal-id="EditBenchmarkReportModal">
      <template #modalBody>
        <!-- Report name input -->
        <AppTextInput title="Report Name" placeholder="Enter report name" v-model="localState.reportName" />
        <!-- Report Recurring selector -->
        <AppSingleSelector
          title="Recurring"
          placeholder="Select recurring"
          :options="benchmarkReportStore.recurringOptions"
          v-model="localState.selectedRecurring"
        />
        <!-- Period selector -->
        <AppSingleSelector
          title="Period"
          placeholder="Select period"
          :options="benchmarkReportStore.periodOptions"
          v-model="localState.selectedPeriod"
        />
        <!-- group selector -->
        <AppSingleSelector title="Group" placeholder="Select group" :options="benchmarkReportStore.groupOptions" v-model="localState.selectedGroup" />
        <!-- categories select options -->
        <AppMultiSelector
          title="Categories"
          placeholder="Select categories"
          :options="benchmarkReportStore.categoryOptions"
          v-model:selectedOptions="localState.selectedCategoryOptions"
        >
          <template #body>
            <div class="list-container">
              <div class="list-item" v-for="option in benchmarkReportStore.categoryOptions" :key="option.value">
                <div class="list-item-contents-wrapper">
                  <AppCheckboxButton
                    :label="option.label"
                    :is-check="true"
                    :is-checked="localState.selectedCategoryOptions.includes(option.value)"
                    @checkboxClicked="toggleCategoryOption(option)"
                  />
                </div>
              </div>
            </div>
          </template>
        </AppMultiSelector>
        <!-- Include report choices -->
        <AppMultiSelector
          title="Include Report"
          placeholder="Select report"
          :options="benchmarkReportStore.benchmarkReportOptions"
          v-model:selectedOptions="localState.selectedReportOptions"
        >
          <template #body>
            <div class="list-container">
              <div class="list-item" v-for="option in benchmarkReportStore.benchmarkReportOptions" :key="option.value">
                <div class="list-item-contents-wrapper">
                  <AppCheckboxButton
                    :label="option.label"
                    :is-check="true"
                    :is-checked="localState.selectedReportOptions.includes(option.value)"
                    @checkboxClicked="toggleOption(option)"
                  />
                </div>
              </div>
            </div>
          </template>
        </AppMultiSelector>
      </template>
      <template #modalFooter>
        <div class="footer-container">
          <AppButton label="Save" @click="saveChanges" />
        </div>
      </template>
    </AppModal>
  </teleport>
</template>

<style scoped lang="scss">
.period-container {
  display: flex;
  gap: 1rem;
}
.width {
  width: 50%;
}

.list-container {
  overflow-y: auto;
  max-height: 180px;

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 9px;
    transition:
      width 0.5s ease,
      background-color 0.5s ease; /* Increase transition duration */
    scrollbar-gutter: auto;
  }

  &::-webkit-scrollbar-track {
    background: var(--property-card-bg);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }
}

.list-item-contents-wrapper {
  padding: 2px 4px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
</style>
