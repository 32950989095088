import { previews } from '@/helpers/IconRegistry';
import { v4 as uuidv4 } from 'uuid';

export const otherWidgets = [
  {
    title: 'Section Divider',
    icon: null,
    type: 'Other',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppOtherPreview,
    integration: 'Other',
    i: uuidv4(),
    component: 'AppDividerWidget',
    w: 12,
    minW: 12,
    h: 1,
    minH: 1,
    maxH: 1,
  },
];
