<!-- TheActiveUserPanel.vue -->
<script setup></script>

<template>
  <div>
    <!-- This is temporary until real user data is pulled in. Use the above code when backend is hooked up. -->
    <div class="widget__head-alt2">
      <div class="avatar-big">
        <a href="#"></a>
        <div class="avatar__image avatar__image--alt">
          <img src="../assets/images/avatar-main.png" alt="profile" />
        </div>
        <h6>Brad Chadwick<span>admin</span></h6>
        <p>brad@kyzen.io</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatar-big {
  position: relative;
  padding: 31px 20px 31px 104px;
  letter-spacing: -0.019em;
  transition: background 0.4s;
  text-align: left;

  &:hover {
    background: rgba(0, 0, 0, 0);

    &:after {
      opacity: 1;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 33px;
    right: 28px;
    background-size: 100% 100%;
    width: 16px;
    height: 16px;
    opacity: 0;
    transition: opacity 0.4s;
  }

  h6 {
    font-size: 16px;
    margin-bottom: 6px;

    span {
      color: var(--primary-text-color);
      display: inline-block;
      min-width: 50px;
      background-color: #f3f809;
      background-image: var(--primary-gradient);
      background-size: 100%;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 400;
      padding: 5px 3px;
      margin-left: 16px;
      position: relative;
      top: -2px;
      text-align: center;
    }
  }

  p {
    font-size: 15px;
    color: var(--secondary-color);
  }

  .avatar__image {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    border: 2px solid var(--secondary-color);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 33px;
    text-transform: uppercase;
    background-size: cover;

    img {
      border-radius: 50%;
    }
  }
}

.widget__head-alt2 {
  position: relative;
  border-bottom: var(--border-size) solid var(--secondary-color-muted);
  text-align: center;
}
</style>
