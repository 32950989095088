<!-- AlertsViewPageHeaderSection.vue -->
<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core/index';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import { useModalStore } from '@/stores/ModalStore';

const modalStore = useModalStore();
const isOverlayVisible = ref(false);
const target = ref(null);

// function toggleOverlay() {
//   isOverlayVisible.value = !isOverlayVisible.value;
// }

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

const handleOpenAddAlertsModal = () => {
  modalStore.openModal('AddAlertsModal');
};

// function handleOptionClicked(option) {
//   // Handle the option clicked logic here
//   console.log(`Option ${option.text} clicked`);
// }
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <h3>Alerts</h3>
      </div>
      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <div>
          <AppIconBoxWrapper>
            <a @click="handleOpenAddAlertsModal"><AppIconAddAlt /></a>
          </AppIconBoxWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';
</style>
