<!-- AppIconCheckMark.vue -->
<script setup>

</script>

<template>
  <svg class="icon-color" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.74381 11.513C5.60059 11.6581 5.40528 11.7391 5.20232 11.7391C4.99936 11.7391 4.80406 11.6581 4.66084 11.513L0.33661 7.15386C-0.112203 6.70151 -0.112203 5.96818 0.33661 5.5166L0.878095 4.97085C1.32691 4.51849 2.05374 4.51849 2.50255 4.97085L5.20232 7.6919L12.4974 0.339263C12.9463 -0.113088 13.6739 -0.113088 14.1219 0.339263L14.6634 0.885018C15.1122 1.33737 15.1122 2.0707 14.6634 2.52228L5.74381 11.513Z"
      fill="white"/>
  </svg>
</template>

<style scoped lang="scss">
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
