import { defineStore } from "pinia";
import budgetDataJson from "@/data/budgetTableData.json"

export const useBudgetTableStore = defineStore('budgetTable',
  {
    state: () => ({
      focusedCell: String | null,
      budgetData: [],
      months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
      copyIconCell: null,
      tempInputValue: String | null,
      openDrawers: [],
      editItemData: {},
      showOverlay: false,
      formData: {
        integration: "",
        customName: "",
        parentID: "",
      },
    }),
    getters: {
      parentOptions() {
        // Map the budgetData to the desired format
        const mappedBudgetData = this.budgetData.map((parent) => ({
          label: parent.name || parent.data_source,
          value: parent.id
        }));
        // Add an option for 'None' with a value of null
        return [
          {
            label: 'None',
            value: null
          },
          ...mappedBudgetData
        ];
      },
      // Update the monthlyBudgetColTotals computed property to use the helper function.
      monthlyBudgetColTotals() {
        return this.months.reduce((totals, month) => {
          let total = 0;
          this.budgetData.forEach((item) => {
            total += this.calculateParentBudgetForMonth(item, month);
          });
          totals[month] = total.toFixed(2);
          return totals;
        }, {});
      },
      // Update the monthlyActualColTotals computed property to use the helper function.
      monthlyActualColTotals() {
        return this.months.reduce((totals, month) => {
          totals[month] = this.calculateParentActualsForMonth(month).toFixed(2);
          return totals;
        }, {});
      },
      // Getter to calculate the total budget for all parents across all months
      totalBudget() {
        return this.budgetData
          .filter(parent => !parent.parent_expense_id) // Only consider parent items
          .reduce((total, parent) => {
            return total + this.months.reduce((monthTotal, month) => {
              return monthTotal + parseFloat(parent[month] || 0);
            }, 0);
          }, 0).toFixed(2); // Fix to two decimal places
      },

      // Getter to calculate the total actual for all parents across all months
      totalActual() {
        return this.budgetData
          .filter(parent => !parent.parent_expense_id) // Only consider parent items
          .reduce((total, parent) => {
            return total + this.months.reduce((monthTotal, month) => {
              return monthTotal + parseFloat(parent[`${month}_actual`] || 0);
            }, 0);
          }, 0).toFixed(2); // Fix to two decimal places
      },
    },
    actions: {
      fetchBudgetData() {
        // Mimicking an API call
        setTimeout(() => {
          this.budgetData = budgetDataJson.data;
        }, 1000);
      },
      toggleDrawer(id) {
        if (this.openDrawers.includes(id)) {
          this.openDrawers = this.openDrawers.filter((drawerId) => drawerId !== id);
        } else {
          this.openDrawers.push(id);
        }
      },
      // Method to handle the Enter key press for an input field
      handleEnter(event, item, month) {
        if (event.key === 'Enter') {
          this.handleBlur(item, month); // Call the handleBlur method or any other method you want to execute when Enter is pressed

          // Unfocused the input element
          event.target.blur(); // This removes the focus from the input
          this.copyIconCell = null;
        }
      },
      handleInput(event, item, month) {
        this.tempInputValue = event.target.value;
        this.copyIconCell =  `${month}_${item.id}`;
      },
      handleActualInput(event, item, month) {
        this.tempInputValue = event.target.value;
        this.copyIconCell =  `${month}_actual_${item.id}`;
      },
      handleBlur(item, month) {
        const parsedValue = parseFloat(this.tempInputValue);
        if (!isNaN(parsedValue)) {
          item[month] = parsedValue.toFixed(2);
        } else {
          item[month] = Number(item[month]).toFixed(2);
        }

        if (item.parent_expense_id) {
          const parent = this.budgetData.find((parentItem) => parentItem.id === item.parent_expense_id);
          if (parent) {
            parent[month] = this.calculateParentBudgetForMonth(parent, month).toFixed(2);
          }
        }
        this.focusedCell = null;
      },
      // On blur, the handleActualBlur function validates and saves the actual number.
      handleActualBlur(item, month) {
        const parsedValue = parseFloat(this.tempInputValue);
        if (!isNaN(parsedValue)) {
          item[`${month}_actual`] = parsedValue.toFixed(2);
        } else {
          item[`${month}_actual`] = Number(item[`${month}_actual`]).toFixed(2);
        }

        if (item.parent_expense_id) {
          const parent = this.budgetData.find((parentItem) => parentItem.id === item.parent_expense_id);
          if (parent) {
            parent[`${month}_actual`] = this.calculateParentActualForMonth(parent, month).toFixed(2);
          }
        }

        this.focusedCell = null;
      },
      calculateParentBudgetForMonth(parent, month) {
        if (!parent.children || parent.children.length === 0) {
          return parseFloat(parent[month] || 0);
        }

        return parent.children.reduce((total, child) => {
          return total + (parseFloat(child[month] || 0));
        }, 0);
      },
      copyBudget(item, month) {
        const monthIndex = this.months.indexOf(month);
        const subsequentMonths = this.months.slice(monthIndex + 1);
        subsequentMonths.forEach((m) => {
          item[m] = item[month];
        });

        if (item.parent_expense_id) {
          const parent = this.budgetData.find((parentItem) => parentItem.id === item.parent_expense_id);
          if (parent) {
            subsequentMonths.forEach((month) => {
              parent[month] = this.calculateParentBudgetForMonth(parent, month).toFixed(2);
            })
          }
        }
        this.copyIconCell = null;
      },
      generateRandomData() {
        return (Math.random() * 100).toFixed(2)
      },
      addNewItem() {
        const actualsValue = this.formData.integration === "None" ? 0.00 : this.generateRandomData();
        const newItem = {
          id: Date.now(),
          name: this.formData.customName,
          data_source: this.formData.integration,
          parent_expense_id: this.formData.parentID,
          ...Object.fromEntries(this.months.map((month) => [month, 0.00])),
          ...Object.fromEntries(this.months.map((month) => [`${month}_actual`, actualsValue])),
        }

        if (this.formData.parentID) {
          const parent = this.budgetData.find((parent) => parent.id === this.formData.parentID);
          if (parent) {
            parent.children = parent.children || [];
            parent.children.push(newItem);
            // Update the parent's 'Budget' column
            this.months.forEach((month) => {
              parent[month] = this.calculateParentBudgetForMonth(parent, month).toFixed(2);
            })
            // Update the parent's 'Actuals' column
            this.months.forEach((month) => {
              parent[`${month}_actual`] = this.calculateParentActualForMonth(parent, month).toFixed(2);
            });
          }
        } else {
          this.budgetData.push({
            data_source: this.formData.integration,
            name: this.formData.customName,
            id: newItem.id,
            children: [],
            ...Object.fromEntries(this.months.map((month) => [month, 0.00])),
            ...Object.fromEntries(this.months.map((month) => [`${month}_actual`, actualsValue])),
          })
        }
        this.formData = {
          integration: "",
          customName: "",
          parentID: "",
        }
      },
      // Helper function to calculate the total actual for a parent for a given month.
      calculateParentActualForMonth(parent, month) {
        if (!parent.children || parent.children.length === 0) {
          return parseFloat(parent[`${month}_actual`] || 0);
        }

        return parent.children.reduce((total, child) => {
          return total + (parseFloat(child[`${month}_actual`] || 0));
        }, 0);
      },
      // Helper function to calculate the total budget for an item (parent or child) across all months.
      calculateTotalBudgetForRow(item) {
        return this.months.reduce((total, month) => {
          return total + (parseFloat(item[month] || 0));
        }, 0);
      },
      // Helper function to calculate the total actual for an item (parent or child) across all months.
      calculateTotalActualForRow(item) {
        return this.months.reduce((total, month) => {
          return total + (parseFloat(item[`${month}_actual`] || 0));
        }, 0);
      },
      isActualGreaterThanBudget(actual, budget) {
        return parseFloat(actual) > parseFloat(budget);
      },
      // Function to check if total actual is over budget
      isTotalActualOverBudget(item) {
        const totalBudget = this.calculateTotalBudgetForRow(item);
        const totalActual = this.calculateTotalActualForRow(item);
        return parseFloat(totalActual) > parseFloat(totalBudget);
      },
      // Helper function to calculate the total actuals for parents for a given month.
      calculateParentActualsForMonth(month) {
        return this.budgetData.filter((item) => !item.parent_expense_id).reduce((total, item) => {
          return total + (parseFloat(item[`${month}_actual`] || 0));
        }, 0);
      },
      updateItem() {
        let selectedItem = null;
        let oldParent = null;
        let shouldRecalculateParentBudget = false;
        let shouldRecalculateParentActuals = false;

        // Find the item and its old parent
        for (const parent of this.budgetData) {
          if (parent.id === this.editItemData.id) {
            selectedItem = parent;
            break;
          }

          if (parent.children) {
            selectedItem = parent.children.find(child => child.id === this.editItemData.id);
            if (selectedItem) {
              oldParent = parent;
              break;
            }
          }
        }

        // Update item details
        if (selectedItem) {
          // Check if the integration has changed
          if (selectedItem.data_source !== this.editItemData.integration) {
            shouldRecalculateParentBudget = true;
            shouldRecalculateParentActuals = true;

            const actualsValue = this.editItemData.integration === "None" ? 0.00 : this.generateRandomData();
            this.months.forEach((month) => {
              selectedItem[`${month}_actual`] = actualsValue;
            });
          }

          selectedItem.name = this.editItemData.customName;
          selectedItem.data_source = this.editItemData.integration;

          // Handle parent change
          if (this.editItemData.parentID !== selectedItem.parent_expense_id) {
            // If parentID is changed to null, make the item a new parent
            if (this.editItemData.parentID === null && this.editItemData.children.length === 0) {
              if (oldParent) {
                oldParent.children = oldParent.children.filter(child => child.id !== selectedItem.id);
              }
              this.budgetData.push(selectedItem);
            } else {
              // Remove item from its old parent if any
              if (oldParent) {
                oldParent.children = oldParent.children.filter(child => child.id !== selectedItem.id);
              }

              // Then add it to the new parent
              const newParent = this.budgetData.find(item => item.id === this.editItemData.parentID);
              if (newParent) {
                newParent.children = newParent.children || [];
                newParent.children.push(selectedItem);
                // Remove the newParent from budgetData when you set it under a parent again
                this.budgetData = this.budgetData.filter(item => item.id !== selectedItem.id);
              }
            }
            // Update parent_expense_id
            selectedItem.parent_expense_id = this.editItemData.parentID === 'null' ? null : this.editItemData.parentID;
          }
        }

        // Recalculate parent's budget and actuals if needed
        if (shouldRecalculateParentBudget || shouldRecalculateParentActuals) {
          const parent = this.budgetData.find((parentItem) => parentItem.id === selectedItem.parent_expense_id);
          if (parent) {
            this.months.forEach((month) => {
              if (shouldRecalculateParentBudget) {
                parent[month] = this.calculateParentBudgetForMonth(parent, month).toFixed(2);
              }
              if (shouldRecalculateParentActuals) {
                parent[`${month}_actual`] = this.calculateParentActualForMonth(parent, month).toFixed(2);
              }
            });
          }
        }

        this.editItemData = {}; // Reset the edit data
      },
      openEditModal(item) {
        this.editItemData = {
          id: item.id,
          customName: item.name,
          integration: item.data_source,
          parentID: item.parent_expense_id || "",
          children: item.children || [],
        }
      },
      deleteItem(id, parentId = null) {
        if (parentId) {
          // Deleting a child item
          const parent = this.budgetData.find(item => item.id === parentId);
          if (parent && parent.children) {
            parent.children = parent.children.filter(child => child.id !== id);

            // Update the parent's 'Budget'
            this.months.forEach((month) => {
              parent[month] = this.calculateParentBudgetForMonth(parent, month).toFixed(2);
            });

            // Update the parent's 'Actual'
            this.months.forEach((month) => {
              parent[`${month}_actual`] = this.calculateParentActualForMonth(parent, month).toFixed(2);
            });

            // If there are no children left, set the parent's Actuals to 0.00
            if (parent.children.length === 0) {
              this.months.forEach((month) => {
                parent[`${month}_actual`] = "0.00";
              });
            }
          }
        } else {
          // Deleting a parent item (and its children)
          this.budgetData = this.budgetData.filter(item => item.id !== id);
        }
      },
      handleIconClicked(id) {
        this.showOverlay = this.showOverlay === id ? null : id;
      },
      handleOptionClicked(option, id, parentId = null) {
        const item = this.budgetData.find((item) => item.id === id);
        if (option === 'Edit') {
          this.openEditModal(item);
        } else if (option === 'Delete') {
          this.deleteItem(id, parentId);
        }
        this.showOverlay = false;
      }
    },
  })
