<!-- BudgetsPageHeader.vue -->
<script setup>
import AppIconGridview from '@/components/svg/AppIconGridview.vue';
import AppIconAnalytics from '@/components/svg/AppIconAnalytics.vue';
import BudgetsIconDotsMenu from '@/components/budgets/page-header-components/IconDotsMenu.vue';
import { useBudgetStore } from '@/stores/BudgetStore';

// Logic...
const budgetStore = useBudgetStore();

function toggleView(view) {
  budgetStore.toggleView(view);
}
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <h3>
          Property Name > Budget Name
          <div class="pill">2024</div>
        </h3>
      </div>
      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <!-- View Toggles -->
        <div class="budgets-view-options-container">
          <AppIconGridview @click="toggleView('table')" :isActive="budgetStore.currentBudgetView === 'table'" />
          <AppIconAnalytics @click="toggleView('analytics')" :isActive="budgetStore.currentBudgetView === 'analytics'" />
        </div>
        <BudgetsIconDotsMenu />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/settings/mixins';
@import '../../styles/settings/themes';

.budgets-view-options-container {
  width: 70px;
  height: 32px;
  padding: 5px;
  gap: 22px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: var(--primary-color);
    transition: all 0.4s;
  }
}

.pill {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  border-radius: 50px;
  margin: 0 10px;
  padding: 5px 13px;
  display: inline-block;
  font-size: 0.65em;
  color: var(--primary-text-color);
  display: inline;
}
</style>
