<script setup>
// Imports...
import TrendingReport from '@/components/reports/TrendingReport.vue';
import LandingPage from '@/components/reports/LandingPage.vue';
import { useReportsStore } from '@/stores/ReportsStore';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import { useModalStore } from '@/stores/ModalStore';
import EditTrendingReportModal from '@/components/ui/modals/EditTrendingReportModal.vue';
import BenchmarkReport from '@/components/reports/BenchmarkReport.vue';
import EditBenchmarkReportModal from '@/components/ui/modals/EditBenchmarkReportModal.vue';

// Logic...
const reportsStore = useReportsStore();
const modalStore = useModalStore();
</script>

<template>
  <div class="styles_view__body">
    <LandingPage v-if="reportsStore.landingPage && !reportsStore.isLoading" />
    <div v-if="reportsStore.isLoading" class="center-spinner">
      <AppSpinner />
    </div>
    <TrendingReport v-if="reportsStore.trending && !reportsStore.isLoading" />
    <BenchmarkReport v-if="reportsStore.benchmark && !reportsStore.isLoading" />
  </div>

  <!--  Modals Start -->
  <EditTrendingReportModal v-if="modalStore.isModalOpen('EditTrendingReportModal')" />
  <EditBenchmarkReportModal v-if="modalStore.isModalOpen('EditBenchmarkReportModal')" />

  <!--  Modals End -->
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.center-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
