<!-- AppSearch.vue -->
<script setup>
import { computed } from 'vue';

import AppIconClose from "@/components/svg/AppIconClose.vue";
import AppIconSearchSmall from "@/components/svg/AppIconSearchSmall.vue";

const props = defineProps({
  placeholder: String,
  label: String,
  modelValue: String
});

const emit = defineEmits(['update:modelValue']);

const clearInput = () => {
  emit('update:modelValue', '');
};

const inputClass = computed(() => {
  return props.label === 'Keywords' ? 'search__field keyword__field' : 'search__field';
});

</script>

<template>
  <div class="search-alt">
    <label v-if="props.label" for="q" class="input-label">{{ props.label }}</label>
    <form action="?" method="get">
      <div class="input-wrapper">
        <input
            type="search"
            :class="inputClass"
            name="q"
            id="q"
            :value="props.modelValue"
            :placeholder="props.placeholder"
            @input="$emit('update:modelValue', $event.target.value)"
            autocomplete="off"
        />

        <button v-if="props.label !== 'Keywords'" class="search__btn">
          <AppIconSearchSmall  />
        </button>

        <AppIconClose v-if="props.modelValue" @click="clearInput"  class="clear-icon" />
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.input-wrapper {
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 11px;
}

.search-alt {
  position: relative;

  form {
    position: relative;
  }

  .search__btn {
    position: absolute;
    top: 55%;
    left: 15px;
    transform: translateY(-50%);
    background: none;
    border: none;
  }

  .search__field {
    width: 100%;
    border: var(--border-size) solid var(--secondary-color);
    border-radius: 12px;
    height: 44px;
    background: none;
    padding: 0 0 0 45px;
    color: var(--primary-text-color);
    transition: .4s;

    &:focus,
    &:hover {
      border-color: var(--primary-color);
    }
  }
}


.input-label {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
  padding-left: 16px;
}

::placeholder {
  color: var(--secondary-color);
}
</style>
