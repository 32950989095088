// filterPropertiesBySelectedIds is a helper function that filters out properties
// that are selected by the user based on selected property IDs.
// This function will handle both 'AND' and 'OR' scenarios based on 'currentFilterType'
export function filterPropertiesBySelectedIds(properties, selectedGroups, currentFilterType) {
  if (currentFilterType === 'AND') {
    return properties.filter(property => {
      return selectedGroups.every(group => group.property_ids.includes(property.attributes.id));
    });
  } else if (currentFilterType === 'OR') {
    return properties.filter(property => {
      return selectedGroups.some(group => group.property_ids.includes(property.attributes.id));
    });
  }
}

// filterPropertiesBySearchQuery is a helper function that filters properties
// by a search query. If any attribute of a property (only strings)
// includes the search query, the property will be included in the result.
export function filterPropertiesBySearchQuery(properties, searchQuery) {
  // Make sure the searchQuery is in lowercase, do it once rather in the loop.
  const lowerCasedQuery = searchQuery.toLowerCase();

  return properties.filter(property => {
    return Object.values(property.attributes).some(attribute => {
      if (typeof attribute === 'string') {
        return attribute.toLowerCase().includes(lowerCasedQuery);
      }
      return false;
    });
  });
}

// sortProperties is a helper function that sorts properties based on a
// selected sort option. Uses pre-defined sortFunctions to perform the sort.
export function sortProperties(properties, selectedSortOption, sortFunctions) {
  const sortFunction = sortFunctions[selectedSortOption];
  if (sortFunction) return [...properties].sort(sortFunction); // Add a spread operator to avoid mutating the original array. This avoids unintended side effects, which can keep the code's behavior predictable.
  return properties;
}

// sortFunctions is a dictionary of different sorting functions
// for the 'sortProperties' function. Each function performs
// a different sort operation on the properties array.
export const sortFunctions = {
  "Recent": (a, b) => b.attributes.id - a.attributes.id,
  "Alphabetical": (a, b) => a.attributes.name.localeCompare(b.attributes.name),
  "Exposure": (a, b) => parseFloat(b.attributes.exposure_rate) - parseFloat(a.attributes.exposure_rate),
  "Occupancy": (b, a) => parseFloat(b.attributes.occupancy_rate) - parseFloat(a.attributes.occupancy_rate),
  "Status": (a, b) => b.attributes.is_disabled - a.attributes.is_disabled, // Disabled properties will come first
};