<!-- AppIconBellNotification.vue -->
<script setup>

</script>

<template>
<svg class="icon-color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.55 22">
	<path class="colorize" d="M19.38,15.37,17.3,11.53V10l-.25.07a5,5,0,0,1-1.3.17h-.39V12l1.86,3.41H2.33L4.18,12V7.53A5.6,5.6,0,0,1,12,2.38l.13.05.09-.1a5.09,5.09,0,0,1,1.28-1l.31-.17L13.45,1A7.52,7.52,0,0,0,2.25,7.53v4L.16,15.37A1.44,1.44,0,0,0,0,16a1.41,1.41,0,0,0,.19.67,1.33,1.33,0,0,0,.49.48,1.35,1.35,0,0,0,.67.18h4a1.21,1.21,0,0,0,0,.19,4.44,4.44,0,1,0,8.88,0v-.19h4a1.39,1.39,0,0,0,1.17-.66,1.41,1.41,0,0,0,.19-.67A1.34,1.34,0,0,0,19.38,15.37ZM7.82,19.13a2.61,2.61,0,0,1-.46-.91,2.22,2.22,0,0,1-.08-.85h5a1.21,1.21,0,0,1,0,.19,2.49,2.49,0,0,1-.21,1,2.46,2.46,0,0,1-.59.83,2.37,2.37,0,0,1-.87.53,2.52,2.52,0,0,1-1,.14,2.62,2.62,0,0,1-1-.28A2.59,2.59,0,0,1,7.82,19.13Z" />
	<path d="M15.71,8.77a3.3,3.3,0,1,0-3.3-3.3A3.3,3.3,0,0,0,15.71,8.77Z" fill="#c81f25"/>
</svg>

</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
