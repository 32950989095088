<script setup>
// Imports...
import UsersTable from '@/components/users/TableLayout.vue';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import { onMounted } from 'vue';
import { useUsersStore } from '@/stores/UsersStore';

const usersStore = useUsersStore();

// Logic...
onMounted(async () => {
  await usersStore.fetchUsers();
});

//End Logic
</script>

<template>
  <!-- Section Body -->
  <div v-if="!usersStore.initialLoad" class="styles_view__body">
    <!-- Users Table -->
    <UsersTable />
  </div>
  <!-- Show loading spinner while data is loading -->
  <div v-else class="center-spinner">
    <AppSpinner />
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
.center-spinner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.no-records {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
  font-size: 2em;
  font-weight: bold;
}
</style>
