<!-- AppIconLogout.vue -->
<script setup>

</script>

<template>
    <svg class="icon-color" width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="colorize" d="M3.15741 1.83333C2.80624 1.83333 2.46946 1.97283 2.22115 2.22115C1.97283 2.46946 1.83333 2.80624 1.83333 3.15741V18.8426C1.83333 19.1938 1.97283 19.5305 2.22115 19.7789C2.46946 20.0272 2.80624 20.1667 3.15741 20.1667H7.63889C8.14515 20.1667 8.55556 20.5771 8.55556 21.0833C8.55556 21.5896 8.14515 22 7.63889 22H3.15741C2.32001 22 1.51691 21.6673 0.924783 21.0752C0.332655 20.4831 0 19.68 0 18.8426L0 3.15741C0 2.32001 0.332654 1.51691 0.924783 0.924783C1.51691 0.332654 2.32001 0 3.15741 0L7.63889 0C8.14515 0 8.55556 0.410406 8.55556 0.916667C8.55556 1.42293 8.14515 1.83333 7.63889 1.83333H3.15741Z"/>
        <path class="colorize" d="M16.1297 4.74987C15.7717 4.39189 15.1913 4.39189 14.8333 4.74987C14.4754 5.10785 14.4754 5.68825 14.8333 6.04623L18.8704 10.0833H7.63899C7.13273 10.0833 6.72232 10.4937 6.72232 10.9999C6.72232 11.5062 7.13273 11.9166 7.63899 11.9166H18.8703L14.8333 15.9536C14.4754 16.3116 14.4754 16.892 14.8333 17.2499C15.1913 17.6079 15.7717 17.6079 16.1297 17.2499L21.7235 11.6562C21.7653 11.6153 21.8033 11.5705 21.8367 11.5224C21.8502 11.5031 21.8627 11.4834 21.8745 11.4633C21.9543 11.3274 22.0001 11.169 22.0001 10.9999C22.0001 10.8508 21.9645 10.71 21.9013 10.5856C21.8709 10.5256 21.8335 10.4682 21.789 10.4147C21.7693 10.391 21.7484 10.3682 21.7264 10.3466L16.1297 4.74987Z"/>
    </svg>

</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}</style>
