<!-- BudgetTableActualCol.vue -->
<script setup>
// Imports...
import { useBudgetTableStore } from '@/stores/BudgetTableStore';
import { computed } from 'vue';

// Logic...
const budgetTableStore = useBudgetTableStore();

const props = defineProps({
  dataItem: {
    type: Object,
    required: true,
  },
  month: {
    type: String,
    required: true,
  },
});

const formattedActual = computed(() => {
  return Number(props.dataItem[`${props.month}_actual`]).toFixed(2);
});

const actualCellIdentifier = computed(() => {
  if (props.month && props.dataItem && props.dataItem.id !== undefined) {
    return `${props.month}_actual_${props.dataItem.id}`;
  }
  return ''; // Default value
});
</script>

<template>
  <!-- Actual Cells -->
  <div class="data-cell" :class="{ 'text-red': budgetTableStore.isActualGreaterThanBudget(dataItem[`${month}_actual`], dataItem[month]) }">
    <div class="data-wrapper">
      <!-- If item has children, it's not editable -->
      <template v-if="dataItem.children && dataItem.children.length > 0">
        <p class="non-editable-cell">${{ formattedActual }}</p>
      </template>
      <!-- If item has no children (or children is null) but has an integration -->
      <template v-else-if="(!dataItem.children || dataItem.children.length === 0) && dataItem.data_source !== 'None'">
        <p class="non-editable-cell">${{ formattedActual }}</p>
      </template>
      <!-- If item has no children (or children is null) and has 'None' as an integration -->
      <input
        v-else
        class="editable-input-cell"
        type="text"
        :value="budgetTableStore.focusedCell === actualCellIdentifier ? budgetTableStore.tempInputValue : '$' + formattedActual"
        @input="budgetTableStore.handleActualInput($event, dataItem, month)"
        @focus="
          budgetTableStore.focusedCell = actualCellIdentifier;
          budgetTableStore.tempInputValue = formattedActual;
        "
        @blur="budgetTableStore.handleActualBlur(dataItem, month)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.data-cell {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: calc(100% / 13); // set a default flex basis
  border-bottom: var(--border-size) solid var(--secondary-color);
  border-right: var(--border-size) solid var(--secondary-color);
  min-width: 140px;
  padding: 15px 10px;
  text-align: right;
  font-weight: 400;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.25);
  }

  &.focused {
    box-shadow: inset 0 0 0 1px var(--primary-color);
  }

  &:last-child {
    border-right: none;
  }
}

.text-red {
  color: #ff6347;
}

.data-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.non-editable-cell {
  cursor: not-allowed;
}

.editable-input-cell {
  flex-grow: 1; // Takes up the rest of the space
  text-align: end; // Align text to the end
  margin-left: 0; // No margin on the left
  padding-left: 0; // No padding on the left
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
    z-index: 2; // Bring to front
  }
}
</style>
