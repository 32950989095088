<!-- AppListPills.vue -->
<script setup>
import { ref, computed, watch } from 'vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';
import { onClickOutside } from '@vueuse/core';

// Props
const props = defineProps({
  title: { type: String, required: false },
  listItems: { type: Array, required: true }, // List of items to display
  defaultSelectedItems: { type: Array, default: () => [] }, // Default selected items
  placeholder: { type: String, default: 'Click to add' },
});

// State for the selected items, search query, and dropdown visibility
const selectedItems = ref([...props.defaultSelectedItems]); // Initializing with any default selected items
const searchQuery = ref('');
const dropdownOpen = ref(false);

// Computed list for filtering items based on search query
const filteredItems = computed(() => {
  return props.listItems.filter((item) => item.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
});

// Add item to selected list
const addItem = (item) => {
  if (!selectedItems.value.some((selected) => selected.id === item.id)) {
    selectedItems.value.push(item);
  }
  searchQuery.value = ''; // Clear search after adding
};

// Remove item from selected list
const removeItem = (itemId) => {
  selectedItems.value = selectedItems.value.filter((item) => item.id !== itemId);
};

// Toggle dropdown on click
const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

// Watch searchQuery to open dropdown when typing in search
watch(searchQuery, (newValue) => {
  if (newValue.length > 0) {
    dropdownOpen.value = true;
  }
});

// Close dropdown if clicked outside the dropdown area
const target = ref(null);
onClickOutside(target, () => {
  if (dropdownOpen.value) {
    dropdownOpen.value = false;
  }
});
</script>

<template>
  <div class="listbox-pill-dropdown-container" ref="target">
    <label v-if="title" class="input-label">{{ title }}</label>
    <div class="selected-items" @click="toggleDropdown">
      <div v-for="item in selectedItems" :key="item.id" class="pill">
        {{ item.name }}
        <button @click.stop="removeItem(item.id)" class="remove-button">x</button>
      </div>
      <div v-if="selectedItems.length === 0" class="placeholder">{{ placeholder }}</div>
    </div>
    <div v-if="dropdownOpen" class="dropdown-wrapper">
      <div class="dropdown-body">
        <AppSearch v-model="searchQuery" />
        <div v-if="filteredItems.length" class="dropdown-list">
          <div class="dropdown-section">
            <h5>Search Campaigns</h5>
            <ul>
              <li v-for="item in filteredItems" :key="item.id" class="dropdown-item">
                <label class="checkbox-container">
                  <input type="checkbox" :checked="selectedItems.includes(item)" @click="addItem(item)" />
                  <span class="custom-checkbox"></span>
                  {{ item.name }}
                </label>
              </li>
            </ul>
          </div>
          <div class="dropdown-section">
            <h5>Performance Max Campaign</h5>
            <ul>
              <li @click="addItem({ id: 'performanceMax', name: 'Performance Max Audiences' })" class="dropdown-item">
                <label class="checkbox-container">
                  <input type="checkbox" :checked="selectedItems.some((i) => i.id === 'performanceMax')" />
                  <span class="custom-checkbox"></span>
                  Performance Max Audiences
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

.input-label {
  display: block;
  font-weight: 400;
  padding-left: 12px;
  font-size: 13px;
  line-height: 1;
  color: var(--primary-text-color);
  margin-bottom: 5px;
}

.listbox-pill-dropdown-container {
  padding: 10px 0;
  position: relative;
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: auto;
  min-height: 45px;
  margin-bottom: 10px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 12px;
  background: none;
  padding: 12px;
  color: var(--primary-text-color);
  transition: 0.4s;
  cursor: pointer;

  .pill {
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    color: #fff;
    padding: 5px 8px 5px 20px;
    border-radius: 15px;
    font-size: 14px;
    height: 30px;
    transition: all 0.4s ease;

    &:hover {
      .remove-button {
        visibility: visible;
      }
    }

    .remove-button {
      background: none;
      border: none;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      margin-left: 8px;
      position: relative;
      bottom: 1px;
      visibility: hidden;
    }
  }

  .placeholder {
    color: var(--secondary-color);
    font-size: 14px;
  }
}

.dropdown-wrapper {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  background-color: var(--dropdown-bg);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  margin-top: 5px;
  padding: 18px 18px 9px 18px;
  max-height: 275px;
  overflow-y: auto;
  z-index: 10;

  .dropdown-body {
    position: relative;
  }

  .dropdown-list {
    list-style-type: none;
    margin: 0;
    padding: 9px 0px;

    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 7px 4px;
      font-size: 16px;
      border-radius: 5px;

      &:hover {
        background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
      }
    }
  }

  .dropdown-section {
    margin-bottom: 10px;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    h5 {
      font-size: 14px;
      color: var(--secondary-color);
      margin: 10px 5px 5px;
      font-weight: 500;
      border-bottom: 1px solid var(--secondary-color);
      padding-bottom: 10px;
    }
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .custom-checkbox {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: none;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    margin-right: 10px;
    transition:
      background-color 0.3s,
      border-color 0.3s;
  }

  input[type='checkbox']:checked + .custom-checkbox {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .custom-checkbox::after {
    content: '';
    position: absolute;
    display: none;
  }

  input[type='checkbox']:checked + .custom-checkbox::after {
    display: block;
  }

  .custom-checkbox::after {
    left: 10px;
    top: 4px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
</style>
