export const startOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1);

export const endOfMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const subMonths = (date, months) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - months);
  return newDate;
};

export const subDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return newDate;
};
