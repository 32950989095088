<!-- AppIconTableMenu.vue -->
<script setup>
import { ref } from "vue";

defineProps({
  width: String,
  height: String
});

const emit = defineEmits(['icon-clicked']);
const iconRef = ref(null);

const handleIconClick = (event) => {
  if (event.target.tagName.toLowerCase() === 'circle') {
    const rect = iconRef.value.getBoundingClientRect();
    emit('icon-clicked', { x: rect.left, y: rect.top });
  }
};
</script>

<template>
  <svg ref="iconRef" @click="handleIconClick" class="icon-color" width="20" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
        cx="3.02251"
        cy="3.47124"
        r="2.49419"
        fill="icon-color"
        stroke="transparent"
        stroke-width="10"
        pointer-events="all"
    />
    <circle
        cx="3.02263"
        cy="10.9539"
        r="2.49419"
        fill="icon-color"
        stroke="transparent"
        stroke-width="10"
        pointer-events="all"
    />
    <circle
        cx="3.02263"
        cy="18.4366"
        r="2.49419"
        fill="icon-color"
        stroke="transparent"
        stroke-width="10"
        pointer-events="all"
    />
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
