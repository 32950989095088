<!-- BudgetTableBudgetCol.vue -->
<script setup>
// Imports...
import AppIconCopy from '@/components/svg/AppIconCopy.vue';
import { useBudgetTableStore } from '@/stores/BudgetTableStore';
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

// Logic...
const props = defineProps({
  entry: {
    type: Object,
    required: true,
  },
  month: {
    type: String,
    required: true,
  },
});

const budgetTableStore = useBudgetTableStore();

// Computed properties for edit ability logic
const isParent = computed(() => Boolean(props.entry.children && props.entry.children.length > 0));
const hasIntegration = computed(() => props.entry.data_source !== 'None');
const isEditable = computed(() => {
  // New condition: If it is a parent and has 'None' as an integration, not editable
  if (isParent.value && !hasIntegration.value) {
    return false;
  }
  // Existing condition: If it is a parent with any integration other than 'None', not editable
  if (isParent.value && hasIntegration.value) {
    return false;
  }
  return true;
});

// New computed property for the formatted number
const formattedNumber = computed(() => {
  if (props.entry && props.month) {
    return `${Number(props.entry[props.month]).toFixed(2)}`;
  }
  return 0.0; // Default value
});

// New computed property for cell identification
const cellIdentifier = computed(() => {
  if (props.month && props.entry && props.entry.id !== undefined) {
    return `${props.month}_${props.entry.id}`;
  }
  return ''; // Default value
});

const target = ref(null);

onClickOutside(target, (event) => {
  if (event.target !== target.value) {
    budgetTableStore.copyIconCell = budgetTableStore.focusedCell;
  }
});
</script>

<template>
  <div :class="['data-cell', { focused: budgetTableStore.focusedCell === cellIdentifier }]">
    <div class="data-wrapper">
      <AppIconCopy v-if="budgetTableStore.copyIconCell === cellIdentifier" @click="budgetTableStore.copyBudget(entry, month)" />
      <template v-if="!isEditable">
        <p class="non-editable-cell">${{ formattedNumber }}</p>
      </template>
      <template v-else>
        <input
          ref="target"
          class="editable-input-cell"
          type="text"
          :value="budgetTableStore.focusedCell === cellIdentifier ? budgetTableStore.tempInputValue : '$' + formattedNumber"
          @input="budgetTableStore.handleInput($event, entry, month)"
          @focus="
            budgetTableStore.focusedCell = cellIdentifier;
            budgetTableStore.tempInputValue = formattedNumber;
          "
          @blur="budgetTableStore.handleBlur(entry, month)"
          @keyup.enter="budgetTableStore.handleEnter($event, entry, month)"
        />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.data-cell {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  border-bottom: var(--border-size) solid var(--secondary-color);
  border-right: var(--border-size) solid var(--secondary-color);
  transition: background-color 0.3s ease;
  flex-basis: calc(100% / 13);
  padding: 15px 10px;
  text-align: center;
  font-weight: 400;
  min-width: 140px;

  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.25);
  }

  &.focused {
    box-shadow: inset 0 0 0 2px var(--primary-color);
  }

  &:last-child {
    border-right: none;
  }
}

.data-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;

  svg.ico {
    padding-right: 4px;
  }
}

.non-editable-cell {
  cursor: not-allowed;
}

.editable-input-cell {
  flex-grow: 1; // Takes up the rest of the space
  text-align: end; // Align text to the start
  margin-left: 0; // No margin on the left
  padding-left: 0; // No padding on the left
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
    z-index: 2; // Bring to front
  }
}
</style>
