// modalStore.js
import { defineStore } from 'pinia';

export const useModalStore = defineStore('ModalStore', {
  state: () => ({
    currentModal: null,
    modalData: {},
    modalLoading: false, // Tracking whether the modal is currently loading data or not
  }),

  getters: {
    isModalOpen: (state) => (modalId) => {
      return state.currentModal === modalId;
    },
  },

  actions: {
    openModal(modalId, data = {}) {
      this.currentModal = modalId;
      this.modalData = data;
      this.modalLoading = true;

      // Simulating our modal data fetching with a timeout
      setTimeout(() => {
        this.modalLoading = false;
      }, 800);
    },
    closeModal(modalId) {
      if (this.currentModal === modalId) {
        this.currentModal = null;
        this.modalData = {};
        this.modalLoading = false;
      } else {
        console.error(`Modal with ID ${modalId} is not open.`);
      }
    },
  },
});
