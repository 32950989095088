import { defineStore } from "pinia";
import axios from "axios";
import { propertiesFilterData } from "@/data/properties";
import { filterPropertiesBySearchQuery, filterPropertiesBySelectedIds, sortProperties, sortFunctions } from "@/helpers/PropertiesHelper";

// Export store to manage state variables for properties data
export const usePropertiesStore = defineStore("PropertiesStore", {

  // State variables for this store. Attaches initial values to the properties of this store
  state: () => ({
    // Stores properties currently selected by the user
    selectedProperties: [],
    // Stores the original set of properties that is fetched initially
    originalProperties: [],
    // The current view style for properties (default view: grid)
    currentPropertyView: "grid",
    // Boolean flag to indicate if the properties data is still loading
    isLoading: false,
    filterLoading: false,
    // String to hold the selected sort criteria (order by)
    selectedOrderBy: "Recent",
    // Array to hold different order by options selected
    selectedOrderByOptions: [],
    // Import sorting criteria data from a separate file
    propertiesOrderByData: propertiesFilterData,
    // Search query to filter properties
    searchQuery: "",
    // Stores the resulted properties after filtering
    filteredPropertiesResult: [],
    currentFilterType: "AND",
  }),

  // Getter functions to compute derived state based on state variables
  getters: {
    isFilterApplied: (state) => {
      return state.selectedProperties.length > 0 || state.searchQuery !== "" || state.selectedOrderBy !== "";
    },
    // Returns set of ids of the selected properties
    selectedPropertyIds: (state) => new Set(state.selectedProperties.flatMap(selectedGroup => selectedGroup.property_ids)),

    // Returns properties after filtering by selected property ids
    filteredProperties: (state) => {
      let result = state.originalProperties;
      if (state.selectedProperties.length > 0) {
        // Only when there are selected properties, create a copy and perform the filtering.
        result = filterPropertiesBySelectedIds([...state.originalProperties], new Set(state.selectedProperties.flatMap(selectedGroup => selectedGroup.property_ids)));
      }
      return result;
    },

    // Returns properties after filtering by search query and selected ids
    searchedProperties: (state) => {
      let result = state.originalProperties;
      if (state.searchQuery) {
        // Only when a search query exists, create a copy and perform the search.
        result = filterPropertiesBySearchQuery([...state.originalProperties], state.searchQuery);
      }
      return result;
    },

    // Returns the final computed properties based on applied selections
    properties: (state) => state.filteredPropertiesResult,
  },

  // Actions perform side effects and can involve asynchronous operations.
  actions: {
    // Fetch properties from server and store in originalProperties
    async getProperties() {
      this.isLoading = true;
      try {
        const response = await axios.get("https://kyzen.io/v1/properties", {
          headers: { Authorization: "Bearer 557d30a7b4147c72a424b7869f5b94c7" },
        });
        // Only take first 12 properties
        this.originalProperties = response.data.data.slice(0, 12);
        // console.log(this.originalProperties);
        this.filteredPropertiesResult = [...this.originalProperties];
        this.filteredPropertiesResult = sortProperties(this.originalProperties, this.selectedOrderBy, sortFunctions);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    updateCurrentFilterType(newFilterType) {
      this.currentFilterType = newFilterType;
    },
    clearFilters() {
      this.selectedProperties = [];
      this.searchQuery = "";
      this.selectedOrderBy = "";
      this.applyFilters();
    },
    // Toggles between 'grid' and 'list' view for properties, and saves the preference in localStorage
    toggleView(view) {
      this.currentPropertyView = view;
      localStorage.setItem("currentPropertyView", view);
    },
    async applyFilters() {
      this.filterLoading = true;

      await new Promise(resolve => setTimeout(resolve, 500));


      // Fetch properties based on selected groups
      let filteredProperties;
      if (this.selectedProperties.length > 0) {
        filteredProperties = filterPropertiesBySelectedIds([...this.originalProperties], this.selectedProperties, this.currentFilterType);
      } else {
        filteredProperties = [...this.originalProperties];
      }

      // Further filter the properties based on the search query, if any
      if (this.searchQuery) {
        filteredProperties = filterPropertiesBySearchQuery(filteredProperties, this.searchQuery);
      }

      this.filteredPropertiesResult = sortProperties(filteredProperties, this.selectedOrderBy, sortFunctions);

      this.filterLoading = false;
    }

  },
  });
