<!-- AppIconClose.vue -->
<script setup>

</script>

<template>
    <svg class="icon-color" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="colorize" d="M20.0356 0.465273C19.73 0.167359 19.3155 0 18.8833 0C18.4511 0 18.0366 0.167359 17.731 0.465273L10.2564 7.75298L2.78184 0.465273C2.47619 0.167359 2.0617 0 1.62952 0C1.19734 0 0.782849 0.167359 0.477204 0.465273C0.17165 0.763278 0 1.1674 0 1.58878C0 2.01016 0.17165 2.41429 0.477204 2.71229L7.95178 10L0.477204 17.2877C0.17165 17.5857 0 17.9898 0 18.4112C0 18.8326 0.17165 19.2367 0.477204 19.5347C0.782849 19.8326 1.19734 20 1.62952 20C2.0617 20 2.47619 19.8326 2.78184 19.5347L10.2564 12.247L17.731 19.5347C18.0366 19.8326 18.4511 20 18.8833 20C19.3155 20 19.73 19.8326 20.0356 19.5347C20.3412 19.2367 20.5128 18.8326 20.5128 18.4112C20.5128 17.9898 20.3412 17.5857 20.0356 17.2877L12.561 10L20.0356 2.71229C20.3412 2.41429 20.5128 2.01016 20.5128 1.58878C20.5128 1.1674 20.3412 0.763278 20.0356 0.465273Z" />
</svg>

</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}</style>
