import { previews } from '@/helpers/IconRegistry';
import { largeWidget, smallWidget } from '@/helpers/WidgetSizes';
import { v4 as uuidv4 } from 'uuid';
import { mediumWidget } from '../WidgetSizes';

const commonFields = {
  icon: null,
  integration: 'Availability',
};

export const availabilityWidgets = [
  {
    title: 'Exposure',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'ExposureMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Leased',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'LeasedMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Occupied',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'OccupiedMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Units Available',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'UnitsAvailableMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Trending Occupancy',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'TrendingOccupancyBar',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Floor Plans',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'FloorPlansTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Unit Types',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'UnitTypesTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Units',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'UnitsTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Occupancy',
    type: 'Donut Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppDonutPreview,
    i: uuidv4(),
    component: 'OccupancyDonut',
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Availability',
    type: 'Donut Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppDonutPreview,
    i: uuidv4(),
    component: 'AvailabilityDonut',
    ...mediumWidget,
    ...commonFields,
  },
];
