<!-- AppCopyTextInput.vue -->
<script setup>
import { ref, watch } from 'vue';
import AppIconCopy from '@/components/svg/AppIconCopy.vue';

const props = defineProps({
  title: String,
  placeholder: String,
  modelValue: String,
  visible: Boolean,
  readonly: {
    type: Boolean,
    default: true, // Default is false, meaning editable by default
  },
  type: {
    type: String,
    default: 'text',
  },
});

const textValue = ref(props.modelValue);
const emit = defineEmits(['update:modelValue']);

function handleInputChange() {
  emit('update:modelValue', textValue.value);
}

// Watching for changes in the prop and then updating the local value
watch(
  () => props.modelValue,
  (newValue) => {
    textValue.value = newValue; // Update internal value
  }
);

// Copy the value to the clipboard
function copyToClipboard() {
  navigator.clipboard.writeText(textValue.value).then(() => {
    // The copy notification/alert would go here. Unsure of how we'll be handling notifications yet.
  });
}
</script>

<template>
  <div class="text-input-container">
    <div>
      <div class="text-input-header">
        <label for="text-input" class="input-label">{{ title }}</label>
        <div class="input-with-icon">
          <input
            id="text-input"
            class="text-input-style"
            :type="type"
            :placeholder="placeholder"
            v-model="textValue"
            @input="handleInputChange"
            :readonly="readonly"
          />
          <button v-if="readonly" class="copy-button" @click="copyToClipboard">
            <AppIconCopy />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.input-label {
  display: block;
  font-weight: 400;
  padding-left: 12px;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
}

// Adjusting this label color for labels inside of a modal
.modal-content {
  .input-label {
    color: var(--primary-text-color);
  }

  .modal-items {
    .text-input-container {
      padding: 10px 0;

      &:first-child {
        padding-top: 0;
      }
    }
  }
}

.text-input-container {
  position: relative;

  div {
    .text-input-header {
      .input-with-icon {
        position: relative;
        display: flex;
        align-items: center;

        .text-input-style {
          flex: 1;
          font-size: 16px;
          font-family: 'Lato', sans-serif;
          background-color: transparent;
          font-weight: 500;
          border: var(--border-size) solid var(--secondary-color);
          border-radius: 12px;
          height: 45px;
          line-height: 40px;
          padding: 0 15px;
          padding-right: 45px; // Adjust padding-right to make space for the button
          box-sizing: border-box;

          &:focus {
            border-color: var(--primary-color);
            outline: none;
            transition: 0.4s ease;
          }

          &::placeholder {
            color: var(--secondary-color);
          }
        }

        .copy-button {
          cursor: pointer;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          background: none;
          border: none;
          padding: 0;
          width: 20px;
          height: 20px;
          z-index: 2;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .flex-auto {
    flex: auto;
  }

  .mobile-input-sm {
    @include tablet {
      flex: 33%;
    }

    @include mobile {
      flex: 33%;
      min-width: 33%;
    }
  }

  .hidden-mobile {
    /* Mobile */
    @include mobile {
      display: none !important;
    }

    &.is-visible-mobile {
      /* Mobile */
      @include mobile {
        display: block !important;
      }
    }
  }

  .hidden-tablet {
    /* Tablet */
    @include tablet {
      display: none !important;
    }

    &.is-visible-tablet {
      /* Tablet */
      @include tablet {
        display: block !important;
      }
    }
  }
}
</style>
