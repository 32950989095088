<!-- AppProgressBarOccupancy.vue -->
<script setup>
import { ref, watch } from 'vue';

// Props definition
const props = defineProps({
  amount: {
    type: String,
  },
});

// Define the method before using it
const getOccupancyColor = (amount) => {
  if (!amount) return 'color-default';  // Handle no amount case
  const numericAmount = parseFloat(amount.replace('%', ''));

  if (numericAmount >= 90) {
    return 'color-good';
  } else if (numericAmount >= 80) {
    return 'color-warning';
  } else {
    return 'color-danger';
  }
};

const amount = ref(props.amount ?? '0%');
const occupancyColor = ref(getOccupancyColor(amount.value));

// Watch for changes in amount
watch(amount, (newAmount) => {
  occupancyColor.value = getOccupancyColor(newAmount);
});

</script>

<template>
  <svg class="ico" width="101" height="18" viewBox="0 0 101 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="6" width="101" height="7" rx="3.5" :class="occupancyColor" fill-opacity="0.25"/>
    <rect y="6" :width="amount" height="7" rx="3.5" :class="occupancyColor" />
  </svg>
</template>

<style lang="scss" scoped></style>

