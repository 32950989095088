<!-- BudgetEditExpenseModal.vue -->
<script setup>
// Imports...
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppSingleSelector from "@/components/ui/selects/AppSingleSelector.vue";
import AppModal from "@/components/ui/AppModal.vue";
import AppTextInput from "@/components/ui/AppTextInput.vue";
import { useBudgetTableStore } from "@/stores/BudgetTableStore";
import { useModalStore } from "@/stores/ModalStore";
import {watch } from "vue";

// Logic...
const budgetTableStore = useBudgetTableStore();
const modalStore = useModalStore();

const addNewItem = () => {
  budgetTableStore.addNewItem();
  modalStore.closeModal("addExpenseModal");
};

const addExpenseOptions = [
  { label: "None", value: "None" },
  { label: "Paid Search", value: "Paid Search" },
  { label: "Site Remarketing", value: "Site Remarketing" },
  { label: "Facebook", value: "Facebook" },
  { label: "Digital Advertising", value: "Digital Advertising" },
];

// Watch for changes in formData.integration
watch(
  () => budgetTableStore.formData.integration,
  (newIntegration) => {
    // Update formData.customName whenever the integration changes
    budgetTableStore.formData.customName = newIntegration;
  }
);
</script>

<template>
  <teleport to="body">
    <AppModal
      title="New Expense"
      modalId="addExpenseModal"
    >
      <template #modalBody>
        <!-- Form to Add New Item -->
        <AppSingleSelector
          bodyWidth="350px"
          title="Assign Existing Integration"
          placeholder="Select Integration"
          :options="addExpenseOptions"
          v-model="budgetTableStore.formData.integration"
        />

        <AppTextInput
          title="Custom Name"
          placeholder="Custom Name"
          v-model="budgetTableStore.formData.customName"
        />

        <AppSingleSelector
          body-width="350px"
          title="Add Under Existing Expense"
          placeholder="Select Parent"
          :options="budgetTableStore.parentOptions"
          v-model="budgetTableStore.formData.parentID"
        />
      </template>

      <template #modalFooter>
        <AppButton @click="addNewItem">Add</AppButton>
      </template>
    </AppModal>
  </teleport>
</template>
