<script setup>
import AlertsTable from '@/components/alerts/TableLayout.vue';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import AddAlertsModal from '@/components/ui/modals/AddAlertsModal.vue';
import { useAlertsStore } from '@/stores/AlertsStore';
import { onMounted } from 'vue';
import { useModalStore } from '@/stores/ModalStore';

const alertsStore = useAlertsStore();
const modalStore = useModalStore();
onMounted(async () => {
  await alertsStore.getAlerts();
});
</script>

<template>
  <!-- Section Body -->
  <div v-if="alertsStore.initialLoad === false" class="styles_view__body">
    <!-- Alerts Table -->
    <AlertsTable />
  </div>
  <!-- Show loading spinner while data is loading -->
  <div v-if="alertsStore.initialLoad === true" class="center-spinner">
    <AppSpinner />
  </div>
  <div v-else class="spinner-container">
    <span class="spinner"></span>
  </div>
  <!--  Modals Start -->
  <AddAlertsModal v-if="modalStore.isModalOpen('AddAlertsModal')" />
  <!--  Modals End -->
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
.center-spinner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}
</style>
