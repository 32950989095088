<!-- BudgetOptionsSelectorFooter.vue -->
<script setup>
// Imports...
import AppIconAdd from '@/components/svg/AppIconAdd.vue';
import { useModalStore } from '@/stores/ModalStore';

// Logic...
const modalStore = useModalStore();
</script>

<template>
  <div class="footer-container">
    <div class="footer-item">
      <AppIconAdd />
      <a @click="modalStore.openModal('manageBudgetsModal')"> Manage budgets </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../styles/settings/mixins';

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.footer-item {
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  background: none;
  border: none;
  border-radius: 8px;
  display: flex;
  width: 100%;
  text-align: left;
  padding: 7px 42px 7px 8px;
  color: inherit;
  cursor: pointer;
}

.footer-item:hover {
  background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);

  & svg {
    fill: var(--primary-color);
  }
}
</style>
