<!-- TheThemeSwitcher.vue -->
<script setup>
// Imports...
import {onMounted, ref} from "vue";
import {useThemeStore} from "@/stores/ThemeStore";
// Logic...
const themeStore = useThemeStore()
const themes = ref(['Dark', 'Light', 'Matrix', 'Dyverse'])
const themeColors = ref({
  Dark: {
    primaryColor: '#617086',
    secondaryColor: '#1C3144'
  },
  Light: {
    primaryColor: '#CCCCCC',
    secondaryColor: '#FFFFFF'
  },
  Matrix: {
    primaryColor: '#00B43C',
    secondaryColor: '#000000'
  },
  Dyverse: {
    primaryColor: '#C81F25',
    secondaryColor: '#000000'
  }
})

function changeTheme(theme) {
  if (themeColors.value[theme]) {
    themeStore.setSelectedTheme(theme);
    document.documentElement.setAttribute('data-theme', theme.toLowerCase());
    localStorage.setItem('theme', theme);
  } else {
    console.error(`Theme ${theme} is not defined in themeColors`);
  }
}

onMounted(() => {
  themeStore.setInitialTheme(themes.value);
})
</script>

<template>
  <div class="theme-picker">
    <div
        v-for="theme in themes"
        :key="theme"
        :class="['theme-option', { active: themeStore.selectedTheme === theme }]"
        :style="{
        background: `linear-gradient(-45deg, ${themeColors[theme].primaryColor} 50%, ${themeColors[theme].secondaryColor} 50%)`
      }"
        @click="changeTheme(theme)"
    ></div>
  </div>
</template>

<style lang="scss">
.theme-picker {
  display: flex;
  justify-content: right;
  align-items: center;
}

.theme-option {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: var(--border-size) solid var(--secondary-color);
  margin-right: 3px;
  cursor: pointer;
}

.theme-option.active {
  border-color: var(--primary-text-color);
}

</style>

