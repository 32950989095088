import { ref, computed } from 'vue';

export default function useSearch(initialOptions) {
  const searchValue = ref(''); // Holds the value for the search filter

  const filteredOptions = computed(() => {
    const currentOptions = initialOptions.value || initialOptions; // Handle both reactive and non-reactive options

    if (searchValue.value.trim() === '') {
      return currentOptions;
    }

    // Apply the filter to all properties of the options
    const result = currentOptions.filter(option => {
      return Object.values(option).some(value =>
        String(value).toLowerCase().includes(searchValue.value.toLowerCase())
      );
    });

    return result;
  });

  return {
    searchValue,
    filteredOptions,
  };
}
