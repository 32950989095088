<!-- BudgetTableFooter.vue -->
<script setup>
import { useBudgetTableStore } from '@/stores/BudgetTableStore';

const budgetTableStore = useBudgetTableStore();
</script>

<template>
  <div class="div-row">
    <div class="first-row-container">
      <p class="total-text">Total</p>
    </div>
    <template v-for="month in budgetTableStore.months" :key="month">
      <div class="footer-cell">{{ `$${budgetTableStore.monthlyBudgetColTotals[month]}` }}</div>
      <div class="footer-cell">{{ `$${budgetTableStore.monthlyActualColTotals[month]}` }}</div>
    </template>
    <div class="footer-cell">{{ `$${budgetTableStore.totalBudget}` }}</div>
    <div class="footer-cell">{{ `$${budgetTableStore.totalActual}` }}</div>
  </div>
</template>

<style scoped lang="scss">
.div-row {
  display: flex;
  flex-wrap: nowrap;
  cursor: default;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }
}

.first-row-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  min-width: 400px;
  border-right: var(--border-size) solid var(--secondary-color);
  border-top: 2px solid var(--secondary-color);
}

.first-row-container > :last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.first-row-container > :last-child {
  width: 50px;
  align-self: center;
}

.first-row-container > p {
  flex-grow: 1;
  margin: 0 10px;
  text-align: left;
  align-self: center;
}

.total-text {
  font-weight: 700;
}

.footer-cell {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: var(--border-size) solid var(--secondary-color);
  border-top: 2px solid var(--secondary-color);
  flex-basis: calc(100% / 13);
  padding: 15px 20px 15px 0;
  text-align: end;
  font-weight: 700;
  transition: background-color 0.3s ease;
  min-width: 140px;

  &.focused {
    box-shadow: inset 0 0 0 1px var(--primary-color);
  }
}
</style>
