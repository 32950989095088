<!-- AppIconTableDropdownArrow.vue -->
<script setup>

</script>

<template>
    <svg class="dropdown-arrow" width="12" height="11" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            class="colorize"
            d="M1.23751 3.96566L1.23751 3.96565C0.466273 3.1957 0.466273 1.9471 1.23751 1.17714L1.23753 1.17713C2.00841 0.407624 3.25793 0.407624 4.02881 1.17713L4.02882 1.17714L10.5036 7.64102L16.9784 1.17714L17.261 1.46022L16.9784 1.17714C17.7492 0.40762 18.9988 0.40762 19.7697 1.17714L19.7697 1.17715C20.5409 1.9471 20.5409 3.19569 19.7697 3.96565L19.7697 3.96566L11.8993 11.8228C11.8993 11.8228 11.8992 11.8228 11.8992 11.8228C11.529 12.1925 11.0269 12.4 10.5036 12.4C9.98031 12.4 9.47819 12.1925 9.10796 11.8228C9.10795 11.8228 9.10795 11.8228 9.10794 11.8228L1.23751 3.96566Z"
            stroke-width="0.8" />
    </svg>
</template>

<style lang="scss">

svg.dropdown-arrow {
    width: 12px;
    height: 11px;
    margin: 0 5px;
    transform: rotate(-90deg);
    transition: transform 0.1s ease-in-out;
    fill: var(--secondary-color);

    &:hover {
      fill: var(--primary-color);
    }
}
</style>
