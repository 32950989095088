<!-- BudgetsView.vue -->
<script setup>
// Imports...
import { onMounted } from 'vue';
import BudgetsTable from '@/components/budgets/TableLayout.vue';
import { useBudgetStore } from '@/stores/BudgetStore';
import BudgetNewBudgetModal from '@/components/ui/modals/BudgetNewBudgetModal.vue';
import BudgetEditExpenseModal from '@/components/ui/modals/BudgetEditExpenseModal.vue';
import BudgetAddExpenseModal from '@/components/ui/modals/BudgetAddExpenseModal.vue';
import { useModalStore } from '@/stores/ModalStore';

// Logic...
const budgetStore = useBudgetStore();
const modalStore = useModalStore();

onMounted(() => {
  budgetStore.loadInitialView();
});
</script>

<template>
  <div class="styles_view__body">
    <!-- Table View -->
    <BudgetsTable v-if="budgetStore.currentBudgetView === 'table'" />
    <!-- Analytical View -->
    <div v-if="budgetStore.currentBudgetView === 'analytics'">Nothing here yet</div>
  </div>
  <!--  Modals Start -->
  <BudgetEditExpenseModal v-if="modalStore.isModalOpen('editExpenseModal')" />
  <BudgetAddExpenseModal v-if="modalStore.isModalOpen('addExpenseModal')" />
  <BudgetNewBudgetModal v-if="modalStore.isModalOpen('newBudgetModal')" />
  <!--  Modals End -->
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
</style>
