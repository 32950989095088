<script setup>
import { computed } from 'vue';

// Import your data
import ga4WebsitePerformance from '@/data/trending-report/ga4WebsitePerformance.json';

// Define props
const props = defineProps({
  numOfPeriods: {
    type: Number,
    required: true,
  },
});

// Process data to only include values up to numOfPeriods
const processedMetrics = computed(() => {
  return ga4WebsitePerformance.metrics.map((metric) => {
    // Slice the values array to match the number of periods
    const values = metric.values.slice(0, props.numOfPeriods);

    // Recalculate total based on displayed values
    let total;

    if (typeof values[0] === 'number') {
      // Sum numerical values
      total = values.reduce((sum, val) => sum + val, 0);
    } else if (typeof values[0] === 'string' && values[0].includes('%') && !values[0].startsWith('<')) {
      // Handle percentage values
      const percentages = values.map((val) => parseFloat(val.replace('%', ''))).filter((val) => !isNaN(val));
      const avg = percentages.reduce((sum, val) => sum + val, 0) / percentages.length;
      total = `${avg.toFixed(2)}%`;
    } else if (typeof values[0] === 'string' && values[0].startsWith('$')) {
      // Handle dollar amounts
      const amounts = values.map((val) => parseFloat(val.replace(/[$,]/g, ''))).filter((val) => !isNaN(val));
      const sum = amounts.reduce((sum, val) => sum + val, 0);
      total = `$${sum.toFixed(2)}`;
    } else if (values[0].startsWith('<')) {
      // Set total to < 10% which is the values we get from the API
      total = values[0];
    } else {
      // Handle other data types or non-numeric values
      total = 'N/A';
    }

    return {
      name: metric.name,
      values,
      total,
    };
  });
});
</script>

<template>
  <tr v-for="(metric, index) in processedMetrics" :key="index">
    <td>{{ metric.name }}</td>
    <td v-for="(value, valueIndex) in metric.values" :key="valueIndex">
      {{ value }}
    </td>
    <td>{{ metric.total }}</td>
  </tr>
</template>

<style scoped>
@import 'simplebar-vue/dist/simplebar.min.css';

tr {
  transition: background-color 0.3s ease;

  &:nth-child(odd) {
    background-color: transparent;
  }

  &:nth-child(even) {
    background-color: var(--table-even);
  }

  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }

  td {
    padding: 12px;
    border: 1px var(--secondary-color) solid;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: rgba(0, 180, 60, 0.25);
    }
  }

  /* Remove right border on the last td in each row */
  td:last-child {
    border-right: none;
  }

  /* Remove left border on the first td in each row */
  td:first-child {
    border-left: none;
  }
}

/* Remove top border from the first row of cells */
tbody tr:first-child td {
  border-top: none;
}

/* Remove bottom border from the last row of cells */
tbody tr:last-child td {
  border-bottom: none;
}

/* Remove border from header's first and last th to avoid double borders */
thead th:first-child {
  border-left: none;
}

thead th:last-child {
  border-right: none;
}

/* Responsive table adjustments */
@media (max-width: 768px) {
  .custom-table {
    font-size: 12px;

    th,
    td {
      padding: 8px;
    }
  }
}
</style>
