<!-- PropertiesNewGroupsModal.vue -->
<script setup>
import {ref} from 'vue';
import { groupTypeOptions } from "@/data/properties";
import AppModal from '@/components/ui/AppModal.vue';
import AppTextInput from "@/components/ui/AppTextInput.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppSingleSelector from "@/components/ui/selects/AppSingleSelector.vue";
import AppPropertiesSelector from "@/components/ui/selects/AppPropertiesSelector.vue";

const isLoading = ref(false);
const selectedType = ref('');

const handleSpinnerButton = () => {
  isLoading.value = true
  setTimeout(() => {
    isLoading.value = false
  }, 2000)
}

</script>

<template>
  <teleport to="body">
  <AppModal title="New Group" modalId="newGroupsModal">
    <template #modalBody>
      <!-- Name input -->
      <AppTextInput title="Name" placeholder="Enter a name for the new group." class="modal-input" />
      <!-- Type input -->
      <AppSingleSelector title="Type" placeholder="Select a group type" :options="groupTypeOptions" v-model="selectedType" class="modal-input"/>
      <!-- Properties input -->
      <AppPropertiesSelector class="modal-input"/>
    </template>

    <template #modalFooter>
      <AppButton label="Save" :isLoading="isLoading" @clicked="handleSpinnerButton" class="button-override" />
    </template>
  </AppModal>
  </teleport>
</template>

<style scoped lang="scss">
.input-label {
  font-weight: 400;
}

.button-override {
  :deep(.label-style) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.modal-input {
  margin-top: 30px;
  margin-bottom: 30px;

  :deep(.input-label) {
    margin-bottom: 7px;
    font-weight: 400;
  }
}
</style>
