<!-- BudgetTableTotalColRow.vue -->
<script setup>
import { useBudgetTableStore } from '@/stores/BudgetTableStore';
import { computed } from 'vue';

const budgetTableStore = useBudgetTableStore();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

// New computed properties
const totalBudgetFormatted = computed(() => {
  return Number(budgetTableStore.calculateTotalBudgetForRow(props.item)).toFixed(2);
});

const totalActualFormatted = computed(() => {
  return Number(budgetTableStore.calculateTotalActualForRow(props.item)).toFixed(2);
});

const isTotalActualOverBudget = computed(() => {
  return budgetTableStore.isTotalActualOverBudget(props.item);
});
</script>

<template>
  <div class="data-cell total-cell">
    <div class="data-wrapper">
      <span class="dollar-sign">$</span>
      <p>{{ totalBudgetFormatted }}</p>
    </div>
  </div>
  <div class="data-cell total-cell" :class="{ 'text-red': isTotalActualOverBudget }">
    <div class="data-wrapper">
      <span class="dollar-sign">$</span>
      <p>{{ totalActualFormatted }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.data-cell {
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  border-bottom: var(--border-size) solid var(--secondary-color);
  border-right: var(--border-size) solid var(--secondary-color);
  flex-basis: calc(100% / 13);
  padding: 15px 10px;
  text-align: center;
  font-weight: 400;
  min-width: 140px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.25);
  }

  &.focused {
    box-shadow: inset 0 0 0 1px var(--primary-color);
  }

  &:last-child {
    border-right: none;
  }
}

.data-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.text-red {
  color: #ff6347;
}
</style>
