// SettingsStore.js

import { defineStore } from 'pinia';
import { properties, goals } from '@/data/settings.js';

export const useSettingsStore = defineStore('SettingsStore', {
  state: () => ({
    selectedProperty: '',
    selectedSetting: '',
    currentView: '',
    isLoading: false,
    searchPerformed: false,
    settings: [...properties, ...goals],
  }),
  getters: {
    filteredData: (state) => {
      let filteredData = [...state.settings];

      if (state.searchPerformed) {
        filteredData = filteredData.filter(
          (setting) =>
            (!state.selectedProperty || setting.name === state.selectedProperty) && (!state.selectedSetting || setting.name === state.selectedSetting)
        );
      }
      return filteredData;
    },
  },
  actions: {
    fetchSettings() {
      this.isLoading = true;
      setTimeout(() => {
        this.settings = [...properties, ...goals];
        this.isLoading = false;
      }, 1800);
    },
    handleSearch(selectedProperty, selectedSetting) {
      this.selectedProperty = selectedProperty;
      this.selectedSetting = selectedSetting;
      this.currentView = selectedSetting;
      this.searchPerformed = true;
    },
    resetSearch() {
      this.isLoading = true;

      setTimeout(() => {
        this.selectedProperty = '';
        this.selectedSetting = '';
        this.currentView = '';
        this.searchPerformed = false;

        this.settings = [...properties, ...goals];

        this.isLoading = false;
      }, 700);
    },
    applyFilters() {
      this.isLoading = true;
      this.handleSearch(this.selectedProperty, this.selectedSetting);
      setTimeout(() => {
        this.isLoading = false;
      }, 1400);
    },
  },
});
