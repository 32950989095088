export const propertiesFilterData = [
  {
    id: 1,
    label: "Northeast",
    checked: false,
    category: "custom-group",
  },
  {
    id: 2,
    label: "Southeast",
    checked: false,
    category: "custom-group",
  },
  // portfolio group
  {
    id: 3,
    label: "Dweck",
    checked: false,
    category: "portfolio-group",
  },
  {
    id: 4,
    label: "Avenue5",
    checked: false,
    category: "portfolio-group",
  },
  {
    id: 5,
    label: "Lerner",
    checked: false,
    category: "portfolio-group",
  },
  // Tags
  {
    id: 6,
    label: "Arizona",
    checked: false,
    category: "tag-group",
  },
  {
    id: 7,
    label: "Washington DC",
    checked: false,
    category: "tag-group",
  },
  {
    id: 8,
    label: "California",
    checked: false,
    category: "tag-group",
  },
  {
    id: 9,
    label: "Lease Up",
    checked: false,
    category: "tags-group",
  },
  // Admin
  {
    id: 10,
    label: "Disabled",
    checked: false,
    category: "admin-group",
  },
  {
    id: 11,
    label: "Demo",
    checked: false,
    category: "admin-group",
  },
  // Filters
  {
    id: 12,
    label: "High Exposure",
    checked: false,
    category: "filter-type",
  },
  {
    id: 13,
    label: "Low occupancy",
    checked: false,
    category: "filter-type",
  },
  {
    id: 14,
    label: "No recent guest cards",
    checked: false,
    category: "filter-type",
  },
  {
    id: 15,
    label: "Showing errors",
    checked: false,
    category: "admin-option",
  },
  {
    id: 16,
    label: "Recently onboarded",
    checked: false,
    category: "admin-option",
  },
  // Order by
  {
    id: 17,
    label: "Type",
    checked: false,
    category: "sort",
  },
  {
    id: 18,
    label: "Alphabetical",
    checked: false,
    category: "sort",
  },
  {
    id: 19,
    label: "Date",
    checked: false,
    category: "sort",
  },
  {
    id: 20,
    label: "Pinned",
    checked: false,
    category: "sort",
  },
];

export const propertiesGroupByOptions = [
  {
    label: "Alphabetical by name",
    value: "Alphabetical"
  },
  {
    label: "Exposure high to low",
    value: "Exposure"
  },
  {
    label: "Occupancy low to high",
    value: "Occupancy"
  },
  {
    label: "Recently added",
    value: "Recent"
  },
  {
    label: "Error status",
    value: "Error"
  },
];


export const groupTypeOptions = [
  {
    id: 1,
    label: "Default",
    groupOptionsId: [1, 2],
    value: "default"
  },
  {
    id: 2,
    label: "Portfolio",
    groupOptionsId: [3, 4, 5],
    value: "portfolio"
  },
  {
    id: 3,
    label: "Category",
    groupOptionsId: [6, 7, 8, 9],
    value: "tag"
  }
];

  
  export const propertyGroupOptions = [
    {
      id: 1,
      label: '100 Prince',
      name: '100 Prince',
      value: '100-prince'
    },
    {
      id: 2,
      label: '1000 West',
      name: '1000 West',
      value: '1000-west'
    },
    {
      id: 3,
      label: '12 Central Square',
      name: '12 Central Square',
      value: '12-central-square'
    },
    {
      id: 4,
      label: 'Alexan Memorial',
      name:  'Alexan Memorial',
      value: 'alexan-memorial'
    },
    {
      id: 5,
      label: 'Alexan River Oaks',
      name:  'Alexan River Oaks',
      value: 'alexan-river-oaks'
    },
    {
      id: 6,
      label: 'Alta 801',
      name:  'Alta 801',
      value: 'alta-801'
    }
  ];
