import {defineStore } from "pinia";

export const useThemeStore = defineStore("ThemeStore", {
  state: () => ({
    selectedTheme: null,
  }),
  getters: {
    currentThemeColor: () => {
      return getComputedStyle(document.documentElement)
        .getPropertyValue('--primary-color').trim();
    },
  },
  actions: {
    setSelectedTheme(theme) {
      this.selectedTheme = theme;
    },
    // on mount, set the initial theme (See ThemeSwitcher.vue)
    setInitialTheme(themes) {
      const savedTheme = localStorage.getItem('theme');
      if (savedTheme && themes.includes(savedTheme)) {
        this.setSelectedTheme(savedTheme);
      } else {
        this.setSelectedTheme(themes[0]);
      }
      document.documentElement.setAttribute('data-theme', this.selectedTheme.toLowerCase());
      localStorage.setItem('theme', this.selectedTheme);
    }
  },
});

