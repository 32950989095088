<!-- AppIconExport.vue -->
<script setup>

</script>

<template>
    <svg class="icon-color" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="colorize"
            d="M7.41041 2.01562L0.807835 8.61867C0.774617 8.65198 0.750632 8.69405 0.73921 8.73926L0.00737865 11.6767C-0.0145125 11.7651 0.0114714 11.8591 0.0760027 11.9237C0.12483 11.9725 0.19136 11.9995 0.259413 11.9995C0.280257 11.9995 0.301577 11.997 0.322326 11.9917L3.25974 11.2598C3.30552 11.2484 3.34712 11.2245 3.38033 11.1913L9.98348 4.5887L7.41041 2.01562Z"
            />
        <path class="colorize"
            d="M11.6195 1.11517L10.8845 0.380199C10.3933 -0.11102 9.53719 -0.110544 9.04654 0.380199L8.14624 1.2805L10.7192 3.85347L11.6195 2.95317C11.8649 2.7079 12 2.38143 12 2.03422C12 1.68701 11.8649 1.36054 11.6195 1.11517Z"
            />
    </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>

