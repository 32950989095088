<script setup>
import { nextTick, ref } from 'vue';
import { useLoginStore } from '@/stores/LoginStore';
import { useAuthStore } from '@/stores/AuthStore';
import { useRouter } from 'vue-router';
import LoginSubmitButton from '@/components/login/SubmitButton.vue';

const authStore = useAuthStore();
const loginStore = useLoginStore();
const router = useRouter();
const email = ref('');
const password = ref('');
const emailInput = ref(null);
const passwordInput = ref(null);
const isLoading = ref(false);
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validateEmail = (fieldRef) => {
  console.log('Validating email:', fieldRef.value); // Debugging line
  if (emailRegex.test(fieldRef.value)) {
    loginStore.setValidationError(null);
    loginStore.setEmailInvalid(false);
    toPassword();
  } else {
    loginStore.setValidationError('Invalid input');
    loginStore.setEmailInvalid(true);
    email.value = '';
  }
};

const toPassword = () => {
  loginStore.setStep('password');
  nextTick(() => {
    passwordInput.value.focus();
  });
  console.log('Email grabbed - moving to password', email.value); // Debugging line
};

const handleSubmit = async () => {
  if (loginStore.step === 'password' && password.value && email.value) {
    isLoading.value = true;
    const success = await authStore.startLogin(email.value, password.value);
    isLoading.value = false;
    if (success) {
      authStore.isLoggedIn = true;
      await router.push('/Properties').catch((err) => console.error('Router navigation error:', err));
    } else {
      console.log('Failed to login');
    }
  } else {
    console.log('Validation failed or missing information'); // Adjusted message for clarity
  }
};
</script>

<template>
  <div>
    <form @submit.prevent>
      <input
        ref="emailInput"
        v-if="loginStore.step === 'email'"
        @keyup.enter="validateEmail(emailInput)"
        type="text"
        v-model="email"
        :class="{
          'custom-input': true,
          invalidInput: loginStore.isEmailInvalid,
        }"
      />
      <input
        ref="passwordInput"
        v-if="loginStore.step === 'password'"
        type="password"
        v-model="password"
        :class="{
          'custom-input': true,
          invalidInput: loginStore.isPasswordInvalid,
        }"
        @keyup.enter="handleSubmit"
      />
      <button type="submit">
        <LoginSubmitButton :isLoading="isLoading" />
      </button>
    </form>
  </div>
</template>

<style lang="scss" scoped>
form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 110px auto 24px;
  position: relative;
  max-width: 755px;
  width: 100%;

  button {
    background: transparent;
    border: none;
  }
}

.custom-input {
  width: 100%;
  height: 100px;
  padding: 25px 50px;
  font-size: 25px;
  font-weight: 290;
  font-style: normal;
  font-family: 'Hero New', sans-serif;
  outline: none;
  border-radius: 90px;
  border: 3px solid #03ff41;
  background: rgba(217, 217, 217, 0.163);
  cursor: text;
  color: #fff;
  transition: all 0.2s ease;

  &::placeholder {
    color: #ccc;
  }

  &.invalidInput {
    border-color: #c81f25;
    animation: shake 0.3s ease-in-out;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

@mixin mobile {
  margin: 0 2rem;
}

@mixin tablet {
  margin: 0 2rem;
}

.forgot-info {
  display: flex;
  justify-content: center;
}

input[type='text'] {
  caret-color: #03ff41;
}
</style>
