<!-- AppVisibleFilterWrapper.vue -->
<script setup>
// Store instances
import {useGlobalStore} from "@/stores/GlobalStore";
import {computed} from "vue";

const globalStore = useGlobalStore();

// To access the global visibleFilter in the template
const visibleFilter = computed(() => globalStore.visibleFilter);
</script>

<template>
  <div class="flex-auto mobile-input-sm hidden-mobile hidden-tablet"
       :class="{ 'is-visible-mobile': visibleFilter, 'is-visible-tablet': visibleFilter }"
       :visible="visibleFilter">
    <slot/>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

.flex-auto {
  flex: auto;
}

.mobile-input-sm {
  @include tablet {
    flex: 33%;
  }
  @include mobile {
    flex: 33%;
    min-width: 33%;
  }
}

.hidden-mobile {
  /*  Mobile  */

  &.is-visible-mobile {
    /*  Mobile  */

    @include mobile {
      display: block !important;
    }
  }
}

.hidden-tablet {
  /*  Tablet  */

  &.is-visible-tablet {
    /*  Mobile  */

    @include tablet {
      display: block !important;
    }
  }
}
</style>