<!-- SettingsGTMModal.vue -->
<script setup>
import { ref, computed } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import { useModalActions } from '@/composables/useModalActions';
import AppModal from '@/components/ui/AppModal.vue';
import AppMultiSelector from '@/components/ui/selects/AppMultiSelector.vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';
import AppCheckboxButton from '@/components/ui/buttons/AppCheckboxButton.vue';
import AppListPills from '@/components/ui/AppListPills.vue';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import useSearch from '@/composables/useSearch';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppIconCopy from '@/components/svg/AppIconCopy.vue';
import AppIconDownload from '@/components/svg/AppIconDownload.vue';
import AppIconLink from '@/components/svg/AppIconLink.vue';
import { goals, accountOptions, properties } from '@/data/settings';

const modalStore = useModalStore();
const { simulateSave, simulateResync } = useModalActions();

const isModalOpen = computed(() => modalStore.isModalOpen('syncedGoogleTagManagerModal'));
const isLoading = computed(() => modalStore.modalLoading); // Using loading state from store

// Create separate search logic for accounts and properties
const { searchValue: accountSearchValue, filteredOptions: filteredAccounts } = useSearch(accountOptions);
const { searchValue: propertySearchValue, filteredOptions: filteredProperties } = useSearch(properties);

// Separate selected options for accounts and properties
const selectedAccounts = ref([]);
const selectedProperties = ref([]);

// Handle selection for accounts
function handleAccountSelection(option) {
  const index = selectedAccounts.value.findIndex((acc) => acc.id === option.id);
  if (index > -1) {
    selectedAccounts.value.splice(index, 1);
  } else {
    selectedAccounts.value.push(option);
  }
}

// Handle selection for properties
function handlePropertySelection(option) {
  const index = selectedProperties.value.findIndex((prop) => prop.id === option.id);
  if (index > -1) {
    selectedProperties.value.splice(index, 1);
  } else {
    selectedProperties.value.push(option);
  }
}

// Check if an account, property or page path is selected
function isAccountChecked(accountId) {
  return selectedAccounts.value.some((acc) => acc.id === accountId);
}

function isPropertyChecked(propertyId) {
  return selectedProperties.value.some((prop) => prop.id === propertyId);
}
</script>

<template>
  <teleport to="body">
    <AppModal title="Google Tag Manager" modal-id="syncedGoogleTagManagerModal" v-model:isModalOpen="isModalOpen">
      <template #modalBody>
        <div v-if="isLoading" class="spinner-wrapper">
          <AppSpinner />
        </div>

        <div v-else>
          <!-- Container Input -->
          <AppMultiSelector title="Account" v-model:selectedOptions="selectedAccounts" placeholder="Select one or more accounts">
            <template v-slot:body>
              <AppSearch placeholder="Search accounts..." v-model="accountSearchValue" @click.stop />
              <ul class="list">
                <li class="list-item" v-for="account in filteredAccounts" :key="account.id">
                  <div class="list-item-contents-wrapper">
                    <AppCheckboxButton
                      :label="account.name"
                      :is-check="true"
                      :is-checked="isAccountChecked(account.id)"
                      @checkboxClicked="handleAccountSelection(account)"
                    />
                  </div>
                </li>
              </ul>
            </template>
          </AppMultiSelector>

          <!-- Workspace Input -->
          <AppMultiSelector title="Properties" v-model:selectedOptions="selectedProperties" placeholder="Select one or more properties">
            <template v-slot:body>
              <AppSearch placeholder="Search properties..." v-model="propertySearchValue" @click.stop />
              <ul class="list">
                <li class="list-item" v-for="property in filteredProperties" :key="property.id">
                  <div class="list-item-contents-wrapper">
                    <AppCheckboxButton
                      :label="property.name"
                      :is-check="true"
                      :is-checked="isPropertyChecked(property.id)"
                      @checkboxClicked="handlePropertySelection(property)"
                    />
                  </div>
                </li>
              </ul>
            </template>
          </AppMultiSelector>

          <!-- Goals ListBox -->
          <AppListPills title="Goals" :listItems="goals" />
        </div>
      </template>

      <!-- Modal Sub-Footer -->
      <template #modalSubFooter>
        <div class="modal-sync-buttons">
          <AppButton label="Unlink" :isLoading="isLoading" @click="simulateSave('syncedGoogleTagManagerModal')">
            <AppIconLink />
          </AppButton>
          <AppButton label="Resync" :isLoading="isLoading" @clicked="simulateResync">
            <AppIconLink />
          </AppButton>
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modalFooter>
        <div class="modal-controls">
          <div class="script-buttons">
            <span>
              <AppIconDownload />
              GTM
            </span>
            <span>
              <AppIconCopy />
              GTM
            </span>
          </div>
          <div>
            <AppButton label="Save" :isLoading="isLoading" @click="simulateSave('syncedGoogleTagManagerModal')" />
          </div>
        </div>
      </template>
    </AppModal>
  </teleport>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.list {
  list-style: none;
  padding: 10px 0;
}

.list-item-contents-wrapper {
  padding: 2px 4px;
  border-radius: 5px;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}

.copy-button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  z-index: 2;

  svg {
    width: 100%;
    height: 100%;
  }
}

.modal-sync-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  button {
    background: none;
    border-radius: 12.799px;
    border: 0.853px solid var(--secondary-color);
    color: var(--primary-text-color);
    font-size: 17.066px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.452px;
    /* 155% */
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.modal-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  button {
    border-radius: 8px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;

    &:first-child {
      margin-right: 5px;
    }
  }
}

.script-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  line-height: 39.378px;
  letter-spacing: -0.342px;

  span {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  :deep(.icon-color) {
    fill: var(--primary-text-color);

    &:hover {
      fill: var(--primary-text-color);
    }
  }
}
</style>
