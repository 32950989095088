export const sidebarIntegrationsOptions = [
  {
    label: 'View all',
    // value needs to match the widget.subtitle
    // you can find this by console logging the widget object in the Dashboard.vue file
    // in the filteredWidgets computed function after the first return statement
    value: 'View all',
  },
  {
    label: 'Attribution',
    value: 'Attribution',
  },
  {
    label: 'Availability',
    value: 'Availability',
  },
  {
    label: 'Facebook',
    value: 'Facebook',
  },
  {
    label: 'Google Ads',
    value: 'Google Ads',
  },
  {
    label: 'Google My Business',
    value: 'Google My Business',
  },
  {
    label: 'Guest Cards',
    value: 'Guest Cards',
  },
  {
    label: 'Leasing',
    value: 'Leasing',
  },
  {
    label: 'Reputation',
    value: 'Reputation',
  },
  {
    label: 'Website (GA4)',
    value: 'Google Analytics 4',
  },
  {
    label: 'Website (UA)',
    value: 'Website (UA)',
  },
];

export const sidebarTypesOptions = [
  {
    label: 'View all',
    value: 'View all', // value needs to match the widget.icon.__file
  },
  {
    label: 'Bar Charts',
    value: 'Bar Chart',
  },
  {
    label: 'Donut Charts',
    value: 'Donut Chart',
  },
  {
    label: 'Line Charts',
    value: 'Line Chart',
  },
  {
    label: 'Tables',
    value: 'Table',
  },
  {
    label: 'Widgets',
    value: 'Metric',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

// Dashboard Landing Page Data - This can be moved to the DashboardsJson repo if needed.

export const myDashboards = [
  { title: 'Website (GA4)', lastEdited: 'Last edited on May 14 2024', buttonText: 'View' },
  { title: 'Attribution', lastEdited: 'Last edited on May 14 2024', buttonText: 'View' },
  { title: 'Google Ads', lastEdited: 'Last edited on May 14 2024', buttonText: 'View' },
  { title: 'Facebook', lastEdited: 'Last edited on May 14 2024', buttonText: 'View' },
  { title: 'Google Business Profile', lastEdited: 'Last edited on May 14 2024', buttonText: 'View' },
  { title: 'Guest Cards', lastEdited: 'Last edited on May 14 2024', buttonText: 'View' },
];

export const communityDashboards = [
  { title: 'Attribution', creator: 'Created by John Todero', buttonText: 'Add', buttonType: 'add' },
  { title: 'Website (GA4)', creator: 'Created by John Todero', buttonText: 'Add', buttonType: 'add' },
];
