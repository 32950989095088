<!-- TheFooter.vue -->
<script setup></script>

<template>
  <footer class="footer">
    <div class="shell">
      <p class="copyright">Kyzen Analytics v4.0.0 | ©2024 Dyverse, LLC.</p>

      <p class="credits">Crafted with <i class="ico-heart"></i> by <a href="#">Dyverse</a></p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.footer {
  width: 100%;
  background-color: var(--page-bg);
  border-top: var(--border-size-bar) solid var(--secondary-color);
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary-color);

  @include mobile {
    text-align: center;
  }

  @media screen and (max-width: 375px) {
    font-size: 14px;
  }

  p {
    i {
      margin: 0 5px 3px 5px;
    }

    a {
      text-decoration: none;
    }

    + p {
      @include mobile {
        margin-top: 5px;
      }
    }
  }

  .shell {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      display: block;
    }
  }
}
</style>
