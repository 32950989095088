<!-- BudgetNewBudgetModal.vue -->
<script setup>
import {ref} from 'vue';
import AppModal from '@/components/ui/AppModal.vue';
import AppTextInput from "@/components/ui/AppTextInput.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppToggleButton from '@/components/ui/buttons/AppToggleButton.vue';

const isLoading = ref(false);
const isPrimary = ref(false);

const handleSpinnerButton = () => {
  isLoading.value = true
  setTimeout(() => {
    isLoading.value = false
  }, 2000)
}

</script>

<template>
  <teleport to="body">
    <AppModal
        title="New Budget"
        modalId="newBudgetModal"
    >
      <template #modalBody>
        <!-- Name input -->
        <AppTextInput title="Name" placeholder="Enter a name for your budget" class="modal-input"/>
        <!-- Make primary toggle -->
        <AppToggleButton id="primary-toggle" label="Make this my primary budget" v-model="isPrimary"
                         class="modal-input"/>
      </template>

      <template #modalFooter>
        <AppButton label="Save" :isLoading="isLoading" @clicked="handleSpinnerButton"/>
      </template>
    </AppModal>
  </teleport>
</template>

<style scoped lang="scss">
.input-label {
  font-weight: 400;
}

.modal-input {
  margin-top: 30px;

  &:last-child {
    margin-bottom: 30px;
  }
}
</style>
