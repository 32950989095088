<!-- AppDatePicker.vue -->
<script setup>
import { ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { endOfMonth, startOfMonth, subDays, subMonths } from '@/helpers/Date';

const date = ref([subDays(new Date(), 29), new Date()]);

const presetDates = ref([
  { label: 'Last 30 days', value: [subDays(new Date(), 29), new Date()] },
  { label: 'Last 14 days', value: [subDays(new Date(), 13), new Date()] },
  { label: 'This month', value: [startOfMonth(new Date()), endOfMonth(new Date())] },
  { label: 'Last month', value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
  { label: 'Yesterday', value: [subDays(new Date(), 1), subDays(new Date(), 1)] },
  { label: 'Today', value: [new Date(), new Date()] },
]);

// Convert date to ISO string for better comparison
const dateToStringFunc = (date) => date.toISOString().slice(0, 10);

// Format the selected date range and return the corresponding preset label
const format = (dates) => {
  if (Array.isArray(dates) && dates.length === 2) {
    const [start, end] = dates.map(dateToStringFunc);
    // Find a matching preset
    const matchedPreset = presetDates.value.find(
      (preset) => dateToStringFunc(preset.value[0]) === start && dateToStringFunc(preset.value[1]) === end
    );
    return matchedPreset ? matchedPreset.label : `${start} - ${end}`;
  } else if (dates) {
    return dateToStringFunc(dates); // Single date picker case
  }
  return 'Select a date range'; // Default text when no date is selected
};
</script>

<template>
  <div>
    <p class="date-picker-label-text">Date</p>
    <VueDatePicker
      v-model="date"
      range
      placeholder="Select a date range"
      class="custom-date-picker"
      input-class-name="dp-custom-input"
      menu-class-name="dp-custom-menu"
      calendar-class-name="dp-custom-calendar"
      calendar-cell-class-name="dp-custom-cell"
      calendar-diamond="dp__arrow_top"
      :enable-time-picker="false"
      :action-row="{ showPreview: false }"
      :preset-dates="presetDates"
      :format="format"
    >
      <template #preset-date-range-button="{ label, value, presetDate }">
        <span
          role="button"
          :tabindex="0"
          @click="presetDate(value)"
          @keyup.enter.prevent="presetDate(value)"
          @keyup.space.prevent="presetDate(value)"
        >
          {{ label }}
        </span>
      </template>
    </VueDatePicker>
  </div>
</template>

<style lang="scss">
.dp__input_icon {
  color: var(--secondary-color);
}

.dp__today {
  border: 1px solid var(--secondary-color);
}

.dp--preset-range {
  display: block;
  padding: 5px;
  white-space: nowrap;
  color: var(--secondary-color);
  border-radius: var(--dp-border-radius);
  transition: var(--dp-common-transition);

  &:hover {
    background: var(--secondary-color);
    color: var(--primary-text-color);
  }
}

.dp__overlay_container {
  flex-direction: column;
  overflow-y: auto;
  background: var(--dropdown-bg);
  color: var(--primary-text-color);
}

.dp__overlay_cell_active {
  cursor: pointer;
  border-radius: var(--dp-border-radius);
  text-align: center;
  background: var(--primary-color);
  color: var(--dp-primary-text-color);
}

.dp__button.dp__overlay_action {
  position: absolute;
  bottom: 0;
  background: var(--dropdown-bg);
  color: var(--primary-text-color);

  &:hover {
    background: var(--secondary-color);
    color: var(--primary-text-color);
  }
}

.dp__btn,
.dp--time-overlay-btn,
.dp--time-invalid {
  border: none;
  font: inherit;
  cursor: pointer;
  transition: var(--dp-common-transition);
  line-height: normal;
  color: white;
}

.dp__action_cancel {
  color: var(--primary-text-color);
  border: var(--border-size) solid var(--secondary-color);
  background: transparent;

  &:hover {
    border-color: var(--primary-color);
  }
}

.dp__action_select {
  background: transparent;
  color: var(--dp-primary-text-color);
  border: var(--border-size) solid var(--primary-color);

  &:hover {
    background: var(--primary-color);
    color: var(--primary-text-color);
  }
}

.dp__selection_preview {
  color: var(--primary-text-color);
}

.dp__time_col_block {
  font-size: var(--dp-time-font-size);
  color: white;
}

.dp__arrow_top {
  display: none;
}

.dp__range_between {
  background: var(--primary-color);
  border: var(--border-size) solid var(--primary-color);
  opacity: 0.55;

  &.dp__today {
    border: 1px solid var(--primary-color);
  }
}

.dp__range_end,
.dp__range_start,
.dp__active_date {
  background: var(--primary-color);
  color: var(--dp-primary-text-color);
}

.dp__calendar_header_item {
  text-align: center;
  flex-grow: 1;
  height: var(--dp-cell-size);
  padding: var(--dp-cell-padding);
  width: var(--dp-cell-size);
  box-sizing: border-box;
  color: var(--primary-text-color);
}

.dp__btn {
  background: none;
  color: white;
}

.date-picker-label-text {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
  padding-left: 16px;
}

.dp-custom-menu {
  border-radius: 12px;
  border: var(--border-size) solid var(--secondary-color) !important;
  background: var(--dropdown-bg);
  color: var(--primary-text-color);
}

.dp-custom-calendar {
  background: var(--dropdown-bg);

  .dp__calendar_item {
    color: var(--primary-text-color);
  }
}

.dp-custom-input {
  background-color: transparent;
  color: var(--primary-text-color);
  border: var(--border-size) solid var(--secondary-color);
  font-family: 'Lato', sans-serif;
  border-radius: 12px;
  height: 45px;

  &:hover {
    border-color: var(--primary-color);
    transition: 0.4s ease;
  }
}

.dp-custom-input::placeholder {
  color: var(--primary-text-color);
  opacity: 1;
}

.dp--preset-dates {
  padding: 12px 5px 5px;
  border-inline-end: var(--border-size) solid var(--secondary-color);
}

.dp__calendar_header_separator {
  background: var(--secondary-color);
}
</style>
