<!-- TheNotificationsMenu.vue -->
<script setup>
// Imports...
import AppIconBellNotification from "@/components/svg/AppIconBellNotification.vue";
import AppOverlayMenu from "@/components/ui/AppOverlayMenu.vue";
import {ref} from 'vue';
import {onClickOutside} from "@vueuse/core/index";
import {notificationsData} from "@/data/multiUseData";

// Logic...
const isOverlayVisible = ref(false);
const target = ref(null);

const notificationOptions = notificationsData.map(notification => `notification-${notification.id}`);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  // Logic for option clicked
  console.log(`Option ${option} clicked`);
}
</script>

<template>
  <div class="notifications-menu" ref="target">
    <div class="ico-bell" @click="toggleOverlay">
      <AppIconBellNotification  />
    </div>
    <AppOverlayMenu
        v-if="isOverlayVisible"
        @option-clicked="handleOptionClicked"
        :options="notificationOptions"
    >
      <template #heading>
        <div class="widget__head-alt">
          <h3>Notifications</h3>
        </div>
      </template>
      <template v-for="notification in notificationsData" #[`notification-${notification.id}`] :key="notification.id">
        <div class="notification">
          <a :href="notification.url"/>

          <i
              class="notification__ico"
              :class="notification.icon"
          />

          <h5>{{ notification.title }}</h5>

          <h6>{{ notification.date }}</h6>

          <p>{{ notification.text }}</p>

          <button class="notification__btn">
            <i class="ico-x"/>
          </button>
        </div>
      </template>
    </AppOverlayMenu>
  </div>
</template>


<style scoped lang="scss">
.widget__head-alt {
  border-bottom: var(--border-size) solid var(--secondary-color);
  padding: 14px 10px;
  text-align: center;

  h3 {
    font-size: 16px;
  }
}

.notifications-menu {
  position: relative;
  .overlay-menu li {
    padding: 0;
}

}



.notification {
  position: relative;
  border-bottom: var(--border-size) solid var(--secondary-color);
  padding: 5px 10px 5px 50px;
  font-size: 14px;
  line-height: 1.2;
  transition: background .4s;
  border-radius: 7px;

  &:hover {
    transition: all .3s ease;
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }

  &:last-of-type {
    border-bottom: 0;
  }

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h5 {
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-color);
    letter-spacing: -0.019em;
    margin: 0 0 2px;
  }

  h6 {
    color: var(--secondary-color);
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    letter-spacing: -0.019em;
    margin: 0 0 5px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
  }

  .notification__ico {
    position: absolute;
    top: 23px;
    left: 7px;
  }

  .notification__btn {
    position: absolute;
    top: 2px;
    right: 2px;
    background: none;
    border: none;
    opacity: .5;
    transition: opacity .4s;

    &:hover {
      opacity: 1;
    }

  }
}

:deep(.overlay-menu li) {
  padding: 5px;
  &:hover {
    background: transparent;
  }
}

:deep(.overlay-menu) {
  min-width: 350px;
}

</style>
