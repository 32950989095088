<!-- AlertsTopFilterSection.vue -->
<script setup>
import AppButton from '@/components/ui/buttons/AppButton.vue';
import { alertTypeOptions, alertOrderByOptions } from '@/data/multiUseData';
import { useGlobalStore } from '@/stores/GlobalStore';
import { useAlertsStore } from '@/stores/AlertsStore';
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import AppKeywordSearch from '@/components/ui/search/AppKeywordSearch.vue';
import { debounce } from '@/helpers/debounce';

// Store instances
const globalStore = useGlobalStore();
const alertsStore = useAlertsStore();

// Handle visibility for the top filter navbar inputs and selectors
const handleClick = () => {
  globalStore.toggleVisibleFilter();
};

const debouncedSearch = debounce(() => {
  alertsStore.applyFilters();
}, 500);
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!-- Search Keywords Input -->
      <AppVisibleFilterWrapper>
        <AppKeywordSearch
          label="Keywords"
          placeholder="Alert title, metric, property assigned, etc.."
          v-model="alertsStore.searchKeyword"
          @submit.prevent="debouncedSearch"
          @keyup.enter="debouncedSearch"
        />
      </AppVisibleFilterWrapper>
      <!-- Type Dropdown -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Type" placeholder="Select an alert type" :options="alertTypeOptions" v-model="alertsStore.selectedType" />
      </AppVisibleFilterWrapper>
      <!-- OrderBy Dropdown -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Order by" placeholder="Select an option" :options="alertOrderByOptions" v-model="alertsStore.selectedOrderBy" />
      </AppVisibleFilterWrapper>
      <!-- filter button-->
      <div class="filter-button-container">
        <div class="filter-clear-all" v-if="alertsStore.searchPerformed" @click="alertsStore.resetSearch">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>
        <AppButton label="Search" class="filter-button-bottom" :isLoading="alertsStore.isLoading" @click="alertsStore.applyFilters" />
      </div>
      <!-- Toggle Button for mobile -->
      <AppButton toggle_btn @click="handleClick">
        <AppIconAngleDownPair />
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-basis {
  flex-basis: content;
}

.visible-mobile {
  display: none;

  @include tablet {
    display: block;
  }
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  text-decoration: underline;
  height: 15px;
  visibility: hidden;
}

.filter-clear-all {
  visibility: visible;
  text-decoration: underline;

  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }

  &:active {
    opacity: 0.7;
  }
}
</style>
