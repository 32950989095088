<!-- ReportsPageHeader.vue -->
<script setup>
// Imports...
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core/index';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import AppIconQuestion from '@/components/svg/AppIconQuestion.vue';
import AppTooltip from '@/components/ui/AppTooltip.vue';
import { useReportsStore } from '@/stores/ReportsStore';
import { useModalStore } from '@/stores/ModalStore';

// Logic...

const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  // Handle the option clicked logic here
  console.log(`Option ${option.text} clicked`);
}

const reportsStore = useReportsStore();

const modalStore = useModalStore();

const handleOpenEditReportModal = (report) => {
  if (report === 1) {
    modalStore.openModal('EditBenchmarkReportModal');
  } else if (report === 6) {
    modalStore.openModal('EditTrendingReportModal');
  } else {
    console.error('Invalid report type');
  }
};
</script>

<template>
  <template v-if="reportsStore.report">
    <div class="styles_pageHeader__section">
      <div class="styles_pageHeader__container">
        <!-- Left side of page header -->
        <div class="left-side-container">
          <h3>
            {{ reportsStore.reportName }}
            <span class="pill">Property and/or group names</span>
          </h3>
          <AppTooltip>
            <AppIconQuestion style="width: 17px; height: 17px" />
            <template #text>
              <span class="description">Default report settings: 30 day, GA4 & Google Ads Search selected if applicable.</span>
            </template>
          </AppTooltip>
        </div>
        <!-- Right side of page header -->
        <div class="styles_pageHeader__icon-container">
          <div ref="target">
            <AppIconBoxWrapper @click="toggleOverlay">
              <AppIconDotsVertical />
            </AppIconBoxWrapper>
            <AppOverlayMenu
              v-if="isOverlayVisible"
              @option-clicked="handleOptionClicked"
              :options="['edit-settings', 'make-recurring', 'export-to-csv', 'export-to-pdf', 'share-link']"
              bodyWidth="150px"
            >
              <template #edit-settings>
                <div @click="handleOpenEditReportModal(reportsStore.selectedReport)">Edit settings</div>
              </template>
              <template #make-recurring>
                <div>Make recurring</div>
              </template>
              <template #export-to-csv>
                <div>Export to CSV</div>
              </template>
              <template #export-to-pdf>
                <div>Export to PDF</div>
              </template>
              <template #share-link>
                <div>Share link</div>
              </template>
            </AppOverlayMenu>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="styles_pageHeader__section">
      <div class="styles_pageHeader__container">
        <div class="left-side-container">
          <h3>Reports Landing Page</h3>
        </div>
        <!-- Right side of page header -->
        <div class="styles_pageHeader__icon-container">
          <div>
            <AppIconBoxWrapper>
              <AppIconDotsVertical />
            </AppIconBoxWrapper>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

.pill {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  border-radius: 50px;
  margin: 0 10px;
  padding: 5px 13px;
  font-size: 0.65em;
  color: var(--primary-text-color);
}

.left-side-container {
  display: flex;
  align-items: center;
  height: 34px;
}
</style>
