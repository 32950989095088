export const smallWidget = {
  w: 3,
  minW: 3,
  h: 4,
  minH: 4,
  maxH: 4,
};

export const mediumWidget = {
  w: 6,
  minW: 4,
  h: 8,
  minH: 8,
  maxH: 8,
};

export const largeWidget = {
  w: 12,
  minW: 12,
  h: 8,
  minH: 8,
  maxH: 8,
};

export const halfWidget = {
  w: 12,
  minW: 6,
  h: 8,
  minH: 8,
  maxH: 8,
};
