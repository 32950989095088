<!-- AppIconLink.vue -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M15.5731 11.4187V16.5384C15.5731 16.991 15.3933 17.4251 15.0732 17.7451C14.7532 18.0651 14.3191 18.2449 13.8665 18.2449H4.48048C4.02787 18.2449 3.5938 18.0651 3.27376 17.7451C2.95372 17.4251 2.77393 16.991 2.77393 16.5384V7.15235C2.77393 6.69975 2.95372 6.26568 3.27376 5.94564C3.5938 5.6256 4.02787 5.4458 4.48048 5.4458H9.60014"
      stroke="white"
      stroke-width="1.70655"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M13.0132 2.88574H18.1328V8.0054" stroke="white" stroke-width="1.70655" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.74707 12.2718L18.1331 2.88574" stroke="white" stroke-width="1.70655" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
