// store.js
import { defineStore } from 'pinia';
import { getWidgets } from '@/helpers/WidgetLib';
import { cloneDeep } from 'lodash';
import {
  deleteDashboard,
  deleteDashboardOptions,
  fetchDashboard,
  fetchDashboardOptions,
  saveNewDashboard,
  saveNewDashboardOptions,
  updateDashboard,
  updateDashboardOptions,
} from '@/helpers/DashboardApi';

export const useDashboardsStore = defineStore('DashboardsStore', {
  state: () => ({
    isSideBarVisible: false,
    wrapper: null,
    gridLayout: null,
    dashboardData: [],
    originalDashboardData: [],
    widgets: getWidgets(),
    isEditMode: false,
    selectedDashboard: null,
    dashboardOptions: [],
    isLoading: false,
    dashboardError: false,
    layoutLoaded: false,
    personalDragging: false,
    maxDashboardId: 0,
  }),
  getters: {},
  actions: {
    async loadLayout(dashboardId) {
      this.isLoading = true;
      try {
        this.selectedDashboard = dashboardId;
        // Simulate a delay to show the loading spinner
        await new Promise((resolve) => setTimeout(resolve, 800));
        const data = await fetchDashboard(dashboardId);
        this.dashboardData = cloneDeep(data);
        this.originalDashboardData = cloneDeep(data);
        this.isLoading = false;
        this.layoutLoaded = true;
        this.dashboardError = false;
      } catch (error) {
        console.error('Failed to load dashboard:', error);
        this.dashboardData = { layout: [], name: '' };
        this.dashboardError = true;
        this.isLoading = false;
      }
    },
    async loadDashboardOptions() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 800));
        this.dashboardOptions = await fetchDashboardOptions();
        this.maxDashboardId = this.dashboardOptions.reduce((max, option) => {
          return typeof option.id === 'number' ? Math.max(max, option.id) : max;
        }, 0);
      } catch (error) {
        console.error('Failed to load dashboard options:', error);
        this.dashboardOptions = [];
      }
    },
    async createNewDashboard() {
      const newId = this.maxDashboardId + 1; // Increment the max ID
      this.maxDashboardId = newId; // Update the max ID in the state
      console.log('Creating new dashboard with ID:', newId);
      const newDashboardData = {
        id: newId,
        name: 'Untitled Dashboard',
        layout: [],
      };

      const newDashboardOption = {
        id: newDashboardData.id,
        label: newDashboardData.name,
        value: newDashboardData.id,
      };

      try {
        await saveNewDashboard(newDashboardData);
        await saveNewDashboardOptions(newDashboardOption);
        // Update local state with new dashboard options
        this.dashboardOptions.push(newDashboardOption);

        // Set the new dashboard as the selected dashboard
        this.selectedDashboard = newDashboardData.id;

        // Load the layout for the new dashboard
        await this.loadLayout(this.selectedDashboard);
        this.dashboardError = false;
        this.isEditMode = true;
      } catch (error) {
        console.error('Error creating new dashboard:', error);
        this.dashboardError = true;
      }
    },
    async updateDashboardAndOptions() {
      const updatedDashboard = {
        id: this.selectedDashboard,
        name: this.dashboardData.name,
        layout: this.dashboardData.layout,
      };
      try {
        await updateDashboard(updatedDashboard);

        const updatedOptions = {
          id: this.selectedDashboard,
          label: this.dashboardData.name,
          value: this.selectedDashboard,
        };

        await updateDashboardOptions(updatedOptions);

        this.dashboardOptions = await fetchDashboardOptions();
        this.originalDashboardData = cloneDeep(this.dashboardData);
        this.isEditMode = false;
      } catch (error) {
        console.error('Error updating dashboard:', error);
        this.dashboardError = true;
      }
    },
    async removeDashboard() {
      const id = this.selectedDashboard;
      try {
        // First, delete the dashboard options
        await deleteDashboardOptions(id);

        // After the options are deleted, delete the dashboard
        await deleteDashboard(id);

        // If both deletions are successful, update UI state
        if (this.selectedDashboard === this.dashboardData.id) {
          this.dashboardData = [];
        }

        this.selectedDashboard = null;
        this.layoutLoaded = false;
        this.dashboardError = false;
        this.dashboardOptions = await fetchDashboardOptions();
        this.isEditMode = false;
      } catch (error) {
        console.error('Error deleting dashboard:', error.message);
        this.dashboardError = true;
      }
    },
    revertDashboard() {
      if (this.originalDashboardData) {
        this.dashboardData = cloneDeep(this.originalDashboardData);
      }
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    removeItem(i) {
      const index = this.dashboardData.layout.findIndex((item) => item.i === i);
      if (index > -1) {
        this.dashboardData.layout.splice(index, 1);
      }
    },
    setWrapper(wrapper) {
      this.wrapper = wrapper;
    },
    setGridLayout(gridLayout) {
      this.gridLayout = gridLayout;
    },
    resetState() {
      this.isSideBarVisible = false;
      this.wrapper = null;
      this.gridLayout = null;
      this.isEditMode = false;
      this.selectedDashboard = null;
      this.isLoading = false;
      this.dashboardError = false;
      this.layoutLoaded = false;
      this.personalDragging = false;
    },
  },
});
