<!-- AppHeaderSearch.vue -->
<script setup>
// Imports...
import AppIconSearch from '../../svg/AppIconSearch.vue';
import {ref} from "vue";
// Logic...
const dataVisible = ref(false)

</script>

<template>
	<div
		class="search"
		:class="{ 'is-active': dataVisible }"
	>
		<div class="search__body">
			<AppIconSearch  class="ico-search"/>

			<form
				action="?"
				method="get"
				autocomplete="off"
			>

				<input
					type="search"
					class="search__field"
					name="search-field"
					id="search-field"
					value=""
					@focus="dataVisible = true"
					@blur="dataVisible = false"
				/>

			</form>
		</div>

		<div class="search__foot">
			<ul>
				<li>
					<a href="#">Budgets</a>
				</li>

				<li>
					<a href="#">Dashboards</a>
				</li>

				<li>
					<a href="#">Reports</a>
				</li>

				<li>
					<a href="#">Alerts</a>
				</li>

				<li>
					<a href="#">Settings</a>
				</li>
			</ul>
		</div>
	</div>
</template>


<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.ico-search {
	position: absolute;
    top: 52%;
    left: 20px;
    transform: translateY(-50%);
}
/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 496px;
  z-index: 1;

  /*  Large Desktop  */

  @include large-desktop {
    width: 355px;
  }

  /*  Mobile  */

  @include mobile {
    width: 300px;
  }

  &:hover {
    form {
      opacity: 1;
      visibility: visible;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 44px;
    transform: translateY(-50%);
    font-size: 21px;
    font-weight: 500;
    border-right: var(--border-size) solid var(--primary-color);
    padding-right: 12px;

    /*  Mobile  */

    @include mobile {
      font-size: 9px;
      left: 25px;
      padding-right: 5px;
      white-space: nowrap;
    }
  }

  form {
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s, visibility .4s;
  }

  .search__field {
    width: 100%;
    border: var(--border-size) solid var(--primary-color);
    border-radius: 12px;
    height: 50px;
    background: none;
    padding-left: 45px;
    padding-right: 20px;

    /*  Mobile  */

    @include mobile {
      height: 35px;
      border-radius: 6px;
      padding: 0 10px 0 37px;
    }
  }

  .search__body {
    position: relative;

    .ico-search {
      position: absolute;
      top: 52%;
      left: 12px;
      transform: translateY(-50%);
    }

    .search__btn {
      position: absolute;
      top: 55%;
      left: 12px;
      transform: translateY(-50%);
      opacity: 0;

      /*  Mobile  */

      @include mobile {
        left: 5px;
      }
    }
  }

  .search__foot {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: var(--dropdown-bg);
    border: var(--border-size) solid var(--secondary-color);
    box-shadow: 0 33px 22px rgba(0, 0, 0, .25);
    border-radius: 14px;
    margin-top: 2px;
    display: none;

    /*  Mobile  */

    @include mobile {
      border-radius: 7px;
      margin-top: 5px;
    }

    h6 {
      border-bottom: var(--border-size) solid var(--primary-text-color);
      padding: 19px 27px;
      font-size: 21px;

      /*  Mobile  */

      @include mobile {
        //font-size: 10px;
        //padding: 10px;
      }
    }

    ul {
      list-style: none;
      font-size: 16px;
      padding: 6px 14px 16px;

      /*  Mobile  */

      @include mobile {
        //font-size: 10px;
        //padding: 3px 7px 8px;
      }

      a {
        text-decoration: none;
        position: relative;
        padding: 10px 42px;
        border-radius: 7px;
        display: block;
        transition: background .4s, color .4s;

        /*  Mobile  */

        @include mobile {
          //padding: 6px 25px;
          //border-radius: 4px;
        }

        &:hover {
          background: var(--primary-color);
          // color: var(--page-bg);

          &:after {
            opacity: .3;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 10px;
          background: url(@/assets/svg/ico-angle-down.svg) no-repeat 0 0;
          width: 14px;
          height: 14px;
          transform: translateY(-50%) rotate(45deg);
          background-size: 100% 100%;
          transition: opacity .4s;

          /*  Mobile  */

          @include mobile {
            //width: 12px;
            //height: 7px;
            //left: 5px;
          }
        }
      }

      li {
        + li {
          margin-top: 5px;
        }
      }
    }
  }

  &.is-active {
    .search__foot {
      display: block;
    }
  }
}

/* ------------------------------------------------------------ *\
	Search Alt
\* ------------------------------------------------------------ */

.search-alt {
  position: relative;

  form {
    position: relative;
  }

  .hidden {
    display: none;
  }

  .search__btn {
    position: absolute;
    top: 55%;
    left: 15px;
    transform: translateY(-50%);
    background: none;
    border: none;

    /*  Mobile  */

    // @include mobile {
    // 	line-height: 0;
    // 	left: 10px;
    // }
  }

  .search__field {
    width: 100%;
    border: var(--border-size) solid var(--secondary-color);
    border-radius: 12px;
    height: 44px;
    background: none;
    padding: 0 0 0 45px;
    color: var(--primary-text-color);
    transition: .4s;

    /*  Mobile  */

    // @include mobile {
    // 	height: 35px;
    // 	font-size: 12px;
    // 	padding-left: 30px;
    // }

    &:focus,
    &:hover {
      border-color: var(--primary-color);
    }
  }
}

#properties, #users, #alerts {
  .search__field.keyword__field {
    padding: 0 10px 0 15px;
  }
}

.input-label {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
  padding-left: 16px;
}

::placeholder {
  color: var(--secondary-color);
}
</style>
