// Imports...
import { defineStore } from "pinia";

// Logic...

export const useGlobalStore = defineStore("GlobalStore", {
  state: () => {
    return {
      isLoading: false,
      visibleFilter: false, // For the top Filter NavBar Inputs/Selectors
      collapsedNav: false, // For the top Filter NavBars
      topFilterVisibility: {
        'Properties': true,
        'Dashboards': true,
        'Reports': true,
        'BudgetView': true,
        'Alerts': true,
        'Admin': true,
        'Users': true,
        'Settings': true,
        // Add more views as needed...
      },
    }
  },

  actions: {
    handleSpinnerButtonClick() { // Usually used for spinner button animations
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 2000)
    },
    toggleVisibleFilter() {
      this.visibleFilter = !this.visibleFilter; // For the top Filter NavBar Inputs/Selectors
    },
  }
})
