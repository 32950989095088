<!-- AppIconDotsVertical.vue -->
<script setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
    <path
      d="M19.3437 14.7852V18.4935C19.3437 18.9852 19.1484 19.4569 18.8007 19.8046C18.453 20.1523 17.9813 20.3477 17.4896 20.3477H4.51042C4.01866 20.3477 3.54705 20.1523 3.19932 19.8046C2.8516 19.4569 2.65625 18.9852 2.65625 18.4935V14.7852"
      stroke="white"
      stroke-width="1.48333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6.36426 10.1499L10.9997 14.7853L15.6351 10.1499" stroke="white" stroke-width="1.48333" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M11 14.7852V3.66016" stroke="white" stroke-width="1.48333" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
