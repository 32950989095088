// dashboardApi.js
const LOCAL_API_URL = 'http://localhost:3000/';
const PROD_API_URL = 'https://nodejs-production-75ff.up.railway.app/';
const BASE_URL = process.env.NODE_ENV === 'production' ? PROD_API_URL : LOCAL_API_URL;

async function fetchDashboard(dashboardId) {
  const response = await fetch(`${BASE_URL}data/${dashboardId}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}


async function updateDashboard(dashboard) {
  console.log('Saving dashboard:', dashboard); // Log the dashboard data
  const dashboardJson = JSON.stringify(dashboard);
  const response = await fetch(`${BASE_URL}data/${dashboard.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: dashboardJson
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

async function updateDashboardOptions(dashboardOptions) {
  console.log('Saving dashboard options:', dashboardOptions);
  const dashboardOptionsJson = JSON.stringify(dashboardOptions);
  const response = await fetch(`${BASE_URL}dashboardOptions/${dashboardOptions.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: dashboardOptionsJson
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();

}

async function fetchDashboardOptions() {
  const response = await fetch(`${BASE_URL}dashboardOptions`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

async function saveNewDashboardOptions(dashboardOptions) {
  console.log('Saving dashboard options:', dashboardOptions);
  const dashboardOptionsJson = JSON.stringify(dashboardOptions);
  const response = await fetch(`${BASE_URL}dashboardOptions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: dashboardOptionsJson
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

async function saveNewDashboard(dashboard) {
  console.log('Saving new dashboard:', dashboard); // Log the dashboard data
  const dashboardJson = JSON.stringify(dashboard);
  const response = await fetch(`${BASE_URL}data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: dashboardJson
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

async function deleteDashboard(dashboardId) {
  const response = await fetch(`${BASE_URL}data/${dashboardId}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

async function deleteDashboardOptions(dashboardOptionsId) {
  const response = await fetch(`${BASE_URL}dashboardOptions/${dashboardOptionsId}`, {
    method: 'DELETE'
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

export { fetchDashboard, updateDashboard, updateDashboardOptions,  fetchDashboardOptions, saveNewDashboardOptions, saveNewDashboard, deleteDashboard, deleteDashboardOptions};

