<!-- BudgetsTable.vue -->
<script setup>
// Imports...
import { useBudgetTableStore } from '@/stores/BudgetTableStore';
import BudgetTableHeader from '@/components/budgets/table-components/TheHeader.vue';
import BudgetTableSubHeader from '@/components/budgets/table-components/SubHeader.vue';
import BudgetTableFooter from '@/components/budgets/table-components/TheFooter.vue';
import BudgetTableFirstRowCol from '@/components/budgets/table-components/FirstRowCol.vue';
import BudgetTableActualCol from '@/components/budgets/table-components/ActualCol.vue';
import BudgetTableTotalColRow from '@/components/budgets/table-components/TotalColRow.vue';
import BudgetTableBudgetCol from '@/components/budgets/table-components/BudgetCol.vue';
import SimpleBar from 'simplebar-vue';
// Logic...
const budgetTableStore = useBudgetTableStore();
budgetTableStore.fetchBudgetData();
</script>

<template>
  <div class="div-table-budgets">
    <SimpleBar>
      <!-- Table Header -->
      <div class="div-thead">
        <BudgetTableHeader />
        <BudgetTableSubHeader />
      </div>
      <!-- Table Body -->
      <div v-for="item in budgetTableStore.budgetData" :key="item.id">
        <!-- Parent Rows -->
        <div class="div-row parent-row">
          <!-- Parent First Row -->
          <BudgetTableFirstRowCol :item="item" />
          <template v-for="month in budgetTableStore.months" :key="`${month}_${item.id}`">
            <!-- Parent Budget Cells -->
            <BudgetTableBudgetCol :entry="item" :month="month" />
            <!-- Parent Actual Cells -->
            <BudgetTableActualCol :data-item="item" :month="month" />
          </template>
          <!-- Parent Total Cells -->
          <BudgetTableTotalColRow :item="item" />
        </div>
        <!-- Child Rows -->
        <template v-if="budgetTableStore.openDrawers.includes(item.id)">
          <div class="div-row child-row" v-for="child in item.children" :key="child.id">
            <!-- Child First Row -->
            <BudgetTableFirstRowCol :item="child" :parentId="item.id" />
            <template v-for="month in budgetTableStore.months" :key="`${month}_${child.id}`">
              <!-- Child Budget Cells -->
              <BudgetTableBudgetCol :entry="child" :month="month" />
              <!-- child Actual Cells -->
              <BudgetTableActualCol :data-item="child" :month="month" />
            </template>
            <!-- Child Total Cells -->
            <BudgetTableTotalColRow :item="child" />
          </div>
        </template>
      </div>
      <!-- Table Footer -->
      <BudgetTableFooter />
    </SimpleBar>
  </div>
</template>

<style scoped lang="scss">
@import '../../../node_modules/simplebar-vue/dist/simplebar.min.css';

.div-table-budgets {
  font-size: 16px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 18px;
  margin: auto;
  width: 100%;
  height: 100%;
  overflow: auto;
}

:deep(.simplebar-scrollbar::before) {
  background-color: var(--secondary-color);
}

.div-thead {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.div-row {
  display: flex;
  flex-wrap: nowrap;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }
}
</style>
