<!-- AppIconCopy.vue -->
<script setup>

</script>

<template>
    <svg class="icon-color" width="19" height="19" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="colorize" d="M15.9295 13.5501V2.81418C15.9295 1.542 14.8942 0.506714 13.622 0.506714H2.88613C1.61394 0.506714 0.578659 1.542 0.578659 2.81418V13.5501C0.578659 14.8222 1.61394 15.8575 2.88613 15.8575H13.622C14.8942 15.8575 15.9295 14.8222 15.9295 13.5501ZM2.11697 13.5501V2.81418C2.11697 2.39012 2.46206 2.04503 2.88613 2.04503H13.622C14.0461 2.04503 14.3912 2.39012 14.3912 2.81418V13.5501C14.3912 13.9741 14.0461 14.3192 13.622 14.3192H2.88613C2.46206 14.3192 2.11697 13.9741 2.11697 13.5501ZM19.2712 6.45126H17.4016C16.9771 6.45126 16.6325 6.79533 16.6325 7.22042C16.6325 7.6455 16.9771 7.98957 17.4016 7.98957H19.2712C19.6953 7.98957 20.0403 8.33467 20.0403 8.75873V19.4946C20.0403 19.9187 19.6953 20.2638 19.2712 20.2638H8.53583C8.11177 20.2638 7.76668 19.9187 7.76668 19.4946V17.3681C7.76668 16.9436 7.42261 16.599 6.99752 16.599C6.57243 16.599 6.22836 16.9436 6.22836 17.3681V19.4946C6.22836 20.7668 7.26365 21.8021 8.53583 21.8021H19.2712C20.5434 21.8021 21.5787 20.7668 21.5787 19.4946V8.75873C21.5787 7.48654 20.5434 6.45126 19.2712 6.45126Z"
         />
     </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}</style>
