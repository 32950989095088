<!-- AppTooltip.vue -->
<script setup>
import { nextTick, reactive, ref, useSlots } from 'vue';

const showTooltip = ref(false);
const overlayPosition = reactive({ top: 0, left: 0 });
const tooltipContainerRef = ref(null);
const tooltipContentRef = ref(null);

const slots = useSlots();

function updateTooltipPosition() {
  if (tooltipContainerRef.value) {
    showTooltip.value = true;

    nextTick(() => {
      if (tooltipContentRef.value) {
        const triggerRect = tooltipContainerRef.value.getBoundingClientRect();
        const margin = 10; // Space between tooltip and trigger element

        // Calculate position relative to the viewport
        overlayPosition.top = triggerRect.top - tooltipContentRef.value.offsetHeight - margin;
        overlayPosition.left = triggerRect.left + (triggerRect.width - tooltipContentRef.value.offsetWidth) / 2;

        // Ensure the tooltip doesn't go off-screen
        if (overlayPosition.left < 0) {
          overlayPosition.left = 0;
        }
        if (overlayPosition.left + tooltipContentRef.value.offsetWidth > window.innerWidth) {
          overlayPosition.left = window.innerWidth - tooltipContentRef.value.offsetWidth;
        }
      } else {
        console.error('tooltipContentRef.value is null');
      }
    });
  } else {
    console.error('tooltipContainerRef.value is null');
  }
}

const handleShowToolTip = () => {
  updateTooltipPosition();
};

const handleHideTooltip = () => {
  showTooltip.value = false;
};

const hasTextContent = () => {
  const content = slots.text?.();
  return content && content.length > 0;
};
</script>

<template>
  <div class="tooltip-container" @mouseenter="handleShowToolTip" @mouseleave="handleHideTooltip" ref="tooltipContainerRef" style="position: relative">
    <!-- Trigger Element -->
    <slot></slot>
    <!-- Tooltip Content -->
    <div
      v-if="showTooltip && hasTextContent()"
      class="tooltip-text"
      :style="{ top: overlayPosition.top + 'px', left: overlayPosition.left + 'px' }"
      ref="tooltipContentRef"
    >
      <slot name="text"></slot>
    </div>
  </div>
</template>

<style scoped>
@import '@/styles/settings/_mixins.scss';
.tooltip-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.tooltip-text {
  min-width: 75px;
  max-width: 160px;
  white-space: normal; /* Allows text to wrap within the max-width */
  word-wrap: break-word; /* Breaks long words to prevent overflow */
  position: fixed;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  background-color: var(--element-bg);
  color: var(--primary-text-color);
  font-size: 15px;
  z-index: 99999;
}
</style>
