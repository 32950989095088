<script setup>

</script>

<template>
  <div>
    <span class="spinner"></span>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh; /* Adjust this value according to your needs */
}

.spinner {
  transition: all 0.4s cubic-bezier(.25, .8, .5, 1), opacity .6s cubic-bezier(.25, .8, .5, 1);

  &:before {
    content: "";
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-top: var(--border-size) solid var(--secondary-color);
    border-right: var(--border-size) solid transparent;
    animation: spinner 0.6s linear infinite;
    transition: all 0.4s cubic-bezier(.25, .8, .5, 1), opacity .6s cubic-bezier(.25, .8, .5, 1)
  }

  span {
    transition: opacity .3s;
  }
}

</style>
