<script setup>
import AppButton from '@/components/ui/buttons/AppButton.vue';
import { adminPropertyOptions, adminSettingsOptions } from '@/data/multiUseData'; // Import necessary filter options
import { useGlobalStore } from '@/stores/GlobalStore';
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import { useSettingsStore } from '@/stores/SettingsStore';
import { debounce } from '@/helpers/debounce';

// Store instances
const globalStore = useGlobalStore();
const settingsStore = useSettingsStore();

// Handle visibility for the top filter navbar inputs and selectors
const handleClick = () => {
  globalStore.toggleVisibleFilter();
};

// Debounce the search input to apply filters efficiently
const debouncedSearch = debounce(() => {
  settingsStore.applyFilters();
}, 500);
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!-- Property Selector -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          title="Property"
          :options="adminPropertyOptions"
          v-model="settingsStore.selectedProperty"
          placeholder="Select a property"
          :show-search="true"
          footer-link="/properties"
          footer-text="New Property"
          @change="debouncedSearch"
        ></AppSingleSelector>
      </AppVisibleFilterWrapper>
      <!-- Select Settings -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          title="Settings"
          :options="adminSettingsOptions"
          v-model="settingsStore.selectedSetting"
          placeholder="Select a setting"
          @change="debouncedSearch"
        ></AppSingleSelector>
      </AppVisibleFilterWrapper>
      <!-- filter button-->
      <div class="filter-button-container">
        <div class="filter-clear-all" v-if="settingsStore.searchPerformed" @click="settingsStore.resetSearch">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>
        <AppButton label="Search" class="filter-button-bottom" :isLoading="settingsStore.isLoading" @click="settingsStore.applyFilters" />
      </div>
      <!-- Toggle Button for mobile -->
      <AppButton toggle_btn @click="handleClick">
        <AppIconAngleDownPair />
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  font-weight: 400;
  font-size: 13px;
  text-decoration: underline;
  margin-bottom: 5px;
  height: 15px;
  visibility: hidden;
}

.filter-clear-all {
  visibility: visible;
  cursor: pointer;

  &:hover {
    color: var(--primary-text-color);
  }
}
</style>
