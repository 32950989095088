<!-- AppCategoryList.vue -->
<script setup>
// Imports...
import AppCheckboxButton from "@/components/ui/buttons/AppCheckboxButton.vue";
import {usePropertiesStore} from "@/stores/PropertiesStore";

// Logic...
defineProps({
   filteredOptions: { type: Array, required: true},
});

const propertiesStore = usePropertiesStore();
defineEmits(['update:selectedOption']);

function handleSelectedOption(option) {
  const index = propertiesStore.selectedProperties.findIndex(cat => cat.id === option.id);

  if (index > -1) {
    propertiesStore.selectedProperties.splice(index, 1);
  } else {
    propertiesStore.selectedProperties.push(option);
  }
}

function isChecked(categoryId) {
  return propertiesStore.selectedProperties.some(prop => prop.id === categoryId);
}

</script>

<template>
  <div v-for="group in filteredOptions" :key="group.category_type">
    <h6 v-if="group.category_type !== 'Default'">
      {{ group.category_type }}
    </h6>
    <ul class="list-checkboxes">
      <li v-for="category in group.categories" :key="category.id">
        <div class="category-container">
          <AppCheckboxButton
              :label="category.name"
              :is-check="true"
              :is-checked="isChecked(category.id)"
              @checkboxClicked="handleSelectedOption(category)"
          />
          <span v-if="category.tags" class="pill">{{ category.tags }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">

.pill {
  border: 1px solid var(--primary-color);
  background-color: var(--dropdown-bg);
  border-radius: 50px;
  margin: 0 10px 0 5px;
  padding: 5px 10px;
  display: inline-block; /* To align it next to the h6 */
  font-size: 0.50em;
  color: var(--primary-color);
}

.category-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category-container:hover {
  border-radius: 5px;
  background: var(--property-metric-bg);
  background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
}

</style>