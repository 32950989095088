<!-- AppIconAngleDown.vue -->
<script setup>

</script>

<template>
    <svg class="icon-color" width="14" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="colorize" d="M12.1347 13.68C11.7046 13.68 11.2745 13.5158 10.9466 13.188L0.627106 2.86896C-0.0293478 2.21254 -0.0293478 1.14827 0.627106 0.492116C1.28329 -0.164039 2.34741 -0.164039 3.00392 0.492116L12.1347 9.62299L21.2656 0.492435C21.922 -0.16372 22.9861 -0.16372 23.6422 0.492435C24.299 1.14859 24.299 2.21286 23.6422 2.86928L13.3229 13.1883C12.9948 13.5161 12.5647 13.68 12.1347 13.68Z"/>
    </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
