<script setup>
import combinedData from '@/data/benchmark-report/benchmarkData.json';
import { useBenchmarkReport } from '@/stores/BenchmarkReportStore';

const benchmarkReportStore = useBenchmarkReport();
</script>

<template>
  <div class="table-container">
    <table class="custom-table">
      <thead>
        <!-- Section Header -->
        <tr class="t-head">
          <th>Property</th>
          <!-- Conditionally show Website Performance header if enabled -->
          <th v-if="benchmarkReportStore.report.ga4_website_performance" :colspan="combinedData[0].ga4_website_performance.length">
            Ga4 Website Performance
          </th>
          <!-- Conditionally show Google Ads Search header if enabled -->
          <th v-if="benchmarkReportStore.report.ads_search" :colspan="combinedData[0].ads_search.length">Google Ads: Search</th>
          <!-- Conditionally show Google Ads Site Remarketing header if enabled -->
          <th v-if="benchmarkReportStore.report.ads_site_remarketing" :colspan="combinedData[0].ads_site_remarketing.length">
            Google Ads: Site Remarketing
          </th>
          <!-- Conditionally show Google Ads In-Market Audiences header if enabled -->
          <th v-if="benchmarkReportStore.report.ads_in_market_audiences" :colspan="combinedData[0].ads_in_market_audiences.length">
            Google Ads: In-Market Audiences
          </th>
          <!-- Conditionally show Google Ads Custom Intent Audiences header if enabled -->
          <th v-if="benchmarkReportStore.report.ads_custom_intent_audiences" :colspan="combinedData[0].ads_custom_intent_audiences.length">
            Google Ads: Custom Intent Audiences
          </th>
          <!-- Conditionally show Google Ads Demand Gen header if enabled -->
          <th v-if="benchmarkReportStore.report.ads_demand_gen" :colspan="combinedData[0].ads_demand_gen.length">Google Ads: Demand Gen</th>
          <!-- Conditionally show Google Ads Pmax header if enabled -->
          <th v-if="benchmarkReportStore.report.ads_pmax" :colspan="combinedData[0].ads_pmax.length">Google Ads: Pmax</th>
          <!-- Conditionally show Google Ads Video header if enabled -->
          <th v-if="benchmarkReportStore.report.ads_video" :colspan="combinedData[0].ads_video.length">Google Ads: Video</th>
          <!-- Conditionally show Google Business Profile header if enabled -->
          <th v-if="benchmarkReportStore.report.google_business_profile" :colspan="combinedData[0].google_business_profile.length">
            Google Business Profile
          </th>
          <!-- Conditionally show Meta Ads header if enabled -->
          <th v-if="benchmarkReportStore.report.meta_ads" :colspan="combinedData[0].meta_ads.length">Meta Ads</th>
          <!-- Conditionally show Leasing Performance header if enabled -->
          <th v-if="benchmarkReportStore.report.leasing_performance" :colspan="combinedData[0].leasing_performance.length">Leasing Performance</th>
          <!-- Conditionally show LP Conversion Rate header if enabled -->
          <th v-if="benchmarkReportStore.report.lp_conversion_rate" :colspan="combinedData[0].lp_conversion_rate.length">LP Conversion Rate</th>
          <!-- Conditionally show LP Contact Type header if enabled -->
          <th v-if="benchmarkReportStore.report.lp_contact_type" :colspan="combinedData[0].lp_contact_type.length">LP Contact Type</th>
          <!-- Conditionally show Availability header if enabled -->
          <th v-if="benchmarkReportStore.report.availability" :colspan="combinedData[0].availability.length">Availability</th>
          <!-- Conditionally show Reputation Overall header if enabled -->
          <th v-if="benchmarkReportStore.report.reputation_overall" :colspan="combinedData[0].reputation_overall.length">Reputation: Overall</th>
          <!-- Conditionally show Reputation Google header if enabled -->
          <th v-if="benchmarkReportStore.report.reputation_google" :colspan="combinedData[0].reputation_google.length">Reputation: Google</th>
          <!-- Conditionally show Other header if enabled -->
          <th v-if="benchmarkReportStore.report.other" :colspan="combinedData[0].other.length">Other</th>
        </tr>
        <!-- First Header Row: Property and Dynamic Metrics Names -->
        <tr class="sub-t-head">
          <th class="name-th">Name</th>
          <!-- Dynamically loop over GA4 metrics for headers if Website Performance is enabled -->
          <template v-if="benchmarkReportStore.report.ga4_website_performance">
            <th v-for="metric in combinedData[0].ga4_website_performance" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Google Ads metrics for headers if Google Ads Search is enabled -->
          <template v-if="benchmarkReportStore.report.ads_search">
            <th v-for="metric in combinedData[0].ads_search" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Google Ads metrics for headers if Google Ads Site Remarketing is enabled -->
          <template v-if="benchmarkReportStore.report.ads_site_remarketing">
            <th v-for="metric in combinedData[0].ads_site_remarketing" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Google Ads metrics for headers if Google Ads In-Market Audiences is enabled -->
          <template v-if="benchmarkReportStore.report.ads_in_market_audiences">
            <th v-for="metric in combinedData[0].ads_in_market_audiences" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Google Ads metrics for headers if Google Ads Custom Intent Audiences is enabled -->
          <template v-if="benchmarkReportStore.report.ads_custom_intent_audiences">
            <th v-for="metric in combinedData[0].ads_custom_intent_audiences" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Google Ads metrics for headers if Google Ads Demand Gen is enabled -->
          <template v-if="benchmarkReportStore.report.ads_demand_gen">
            <th v-for="metric in combinedData[0].ads_demand_gen" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Google Ads metrics for headers if Google Ads Pmax is enabled -->
          <template v-if="benchmarkReportStore.report.ads_pmax">
            <th v-for="metric in combinedData[0].ads_pmax" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Google Ads metrics for headers if Google Ads Video is enabled -->
          <template v-if="benchmarkReportStore.report.ads_video">
            <th v-for="metric in combinedData[0].ads_video" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Google Business Profile metrics for headers if Google Business Profile is enabled -->
          <template v-if="benchmarkReportStore.report.google_business_profile">
            <th v-for="metric in combinedData[0].google_business_profile" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Meta Ads metrics for headers if Meta Ads is enabled -->
          <template v-if="benchmarkReportStore.report.meta_ads">
            <th v-for="metric in combinedData[0].meta_ads" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Leasing Performance metrics for headers if Leasing Performance is enabled -->
          <template v-if="benchmarkReportStore.report.leasing_performance">
            <th v-for="metric in combinedData[0].leasing_performance" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over LP Conversion Rate metrics for headers if LP Conversion Rate is enabled -->
          <template v-if="benchmarkReportStore.report.lp_conversion_rate">
            <th v-for="metric in combinedData[0].lp_conversion_rate" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over LP Contact Type metrics for headers if LP Contact Type is enabled -->
          <template v-if="benchmarkReportStore.report.lp_contact_type">
            <th v-for="metric in combinedData[0].lp_contact_type" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Availability metrics for headers if Availability is enabled -->
          <template v-if="benchmarkReportStore.report.availability">
            <th v-for="metric in combinedData[0].availability" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Reputation Overall metrics for headers if Reputation Overall is enabled -->
          <template v-if="benchmarkReportStore.report.reputation_overall">
            <th v-for="metric in combinedData[0].reputation_overall" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Reputation Google metrics for headers if Reputation Google is enabled -->
          <template v-if="benchmarkReportStore.report.reputation_google">
            <th v-for="metric in combinedData[0].reputation_google" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
          <!-- Dynamically loop over Other metrics for headers if Other is enabled -->
          <template v-if="benchmarkReportStore.report.other">
            <th v-for="metric in combinedData[0].other" :key="metric.name">
              {{ metric.name }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <!-- Data Rows: Loop through properties -->
        <tr v-for="property in combinedData" :key="property.id">
          <!-- Property name -->
          <td>{{ property.property }}</td>
          <!-- Conditionally loop through GA4 metrics values if Website Performance is enabled -->
          <template v-if="benchmarkReportStore.report.ga4_website_performance">
            <td v-for="metric in property.ga4_website_performance" :key="metric.name + '-ga4'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Google Ads metrics values if Google Ads Search is enabled -->
          <template v-if="benchmarkReportStore.report.ads_search">
            <td v-for="metric in property.ads_search" :key="metric.name + '-ads'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Google Ads metrics values if Google Ads Site Remarketing is enabled -->
          <template v-if="benchmarkReportStore.report.ads_site_remarketing">
            <td v-for="metric in property.ads_site_remarketing" :key="metric.name + '-ads'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Google Ads metrics values if Google Ads In-Market Audiences is enabled -->
          <template v-if="benchmarkReportStore.report.ads_in_market_audiences">
            <td v-for="metric in property.ads_in_market_audiences" :key="metric.name + '-ads'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Google Ads metrics values if Google Ads Custom Intent Audiences is enabled -->
          <template v-if="benchmarkReportStore.report.ads_custom_intent_audiences">
            <td v-for="metric in property.ads_custom_intent_audiences" :key="metric.name + '-ads'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Google Ads metrics values if Google Ads Demand Gen is enabled -->
          <template v-if="benchmarkReportStore.report.ads_demand_gen">
            <td v-for="metric in property.ads_demand_gen" :key="metric.name + '-ads'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Google Ads metrics values if Google Ads Pmax is enabled -->
          <template v-if="benchmarkReportStore.report.ads_pmax">
            <td v-for="metric in property.ads_pmax" :key="metric.name + '-ads'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Google Ads metrics values if Google Ads Video is enabled -->
          <template v-if="benchmarkReportStore.report.ads_video">
            <td v-for="metric in property.ads_video" :key="metric.name + '-ads'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Google Business Profile metrics values if Google Business Profile is enabled -->
          <template v-if="benchmarkReportStore.report.google_business_profile">
            <td v-for="metric in property.google_business_profile" :key="metric.name + '-google'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Meta Ads metrics values if Meta Ads is enabled -->
          <template v-if="benchmarkReportStore.report.meta_ads">
            <td v-for="metric in property.meta_ads" :key="metric.name + '-meta'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Leasing Performance metrics values if Leasing Performance is enabled -->
          <template v-if="benchmarkReportStore.report.leasing_performance">
            <td v-for="metric in property.leasing_performance" :key="metric.name + '-leasing'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through LP Conversion Rate metrics values if LP Conversion Rate is enabled -->
          <template v-if="benchmarkReportStore.report.lp_conversion_rate">
            <td v-for="metric in property.lp_conversion_rate" :key="metric.name + '-lp'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through LP Contact Type metrics values if LP Contact Type is enabled -->
          <template v-if="benchmarkReportStore.report.lp_contact_type">
            <td v-for="metric in property.lp_contact_type" :key="metric.name + '-lp'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Availability metrics values if Availability is enabled -->
          <template v-if="benchmarkReportStore.report.availability">
            <td v-for="metric in property.availability" :key="metric.name + '-availability'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Reputation Overall metrics values if Reputation Overall is enabled -->
          <template v-if="benchmarkReportStore.report.reputation_overall">
            <td v-for="metric in property.reputation_overall" :key="metric.name + '-reputation'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Reputation Google metrics values if Reputation Google is enabled -->
          <template v-if="benchmarkReportStore.report.reputation_google">
            <td v-for="metric in property.reputation_google" :key="metric.name + '-reputation'">
              {{ metric.values[0] }}
            </td>
          </template>
          <!-- Conditionally loop through Other metrics values if Other is enabled -->
          <template v-if="benchmarkReportStore.report.other">
            <td v-for="metric in property.other" :key="metric.name + '-other'">
              {{ metric.values[0] }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
.table-container {
  overflow-x: auto;
  border: 1px var(--secondary-color) solid;
  border-radius: 7px;

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 9px;
    transition:
      width 0.5s ease,
      background-color 0.5s ease;
    scrollbar-gutter: auto;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.t-head {
  background-color: var(--property-card-bg);
}

.sub-t-head {
  background-color: var(--table-even);
}

th,
td {
  border: 1px solid var(--secondary-color);
  padding: 12px;

  &:first-child {
    border-left: none;
    border-right: none;
  }

  &:nth-child(2) {
    border-left: none;
  }
}

th {
  background-color: var(--light-grey);
  text-align: left;
  font-size: 16px;
  color: var(--secondary-color);
  border-top: none;
  // don't wrap text
  white-space: nowrap;
}

td {
  text-align: center;
}

th:first-child,
td:first-child {
  position: sticky;
  left: 0;
  background-color: var(--property-card-bg);
  z-index: 2;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-left: none;
  min-width: 250px;
  text-align: left;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: var(--secondary-color);
    z-index: 2;
  }
}

td.name-th {
  background-color: var(--table-even);
  z-index: 2;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-left: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: var(--secondary-color);
    z-index: 2;
  }
}
</style>
