// Imports...
import { ga4Widgets } from '@/helpers/widget-cards/Ga4';
import { googleAdsWidgets } from '@/helpers/widget-cards/GoogleAds';
import { metaAdsWidgets } from '@/helpers/widget-cards/MetaAds';
import { leasingWidgets } from '@/helpers/widget-cards/Leasing';
import { gbpWidgets } from '@/helpers/widget-cards/GoogleBusinessProfile';
import { otherWidgets } from '@/helpers/widget-cards/Other';
import { availabilityWidgets } from '@/helpers/widget-cards/Availability';
import { reputationWidgets } from '@/helpers/widget-cards/Reputation';

// Logic...
export function getWidgets() {
  return [
    ...ga4Widgets,
    ...googleAdsWidgets,
    ...metaAdsWidgets,
    ...leasingWidgets,
    ...availabilityWidgets,
    ...gbpWidgets,
    ...otherWidgets,
    ...reputationWidgets,
  ];
}
