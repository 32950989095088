<!-- PropertiesTableView.vue -->
<script setup>
// imports
import { usePropertiesStore } from '@/stores/PropertiesStore';
import { computed } from 'vue';
import { useTableSortingStore } from '@/stores/TableSortingStore';
import AppProgressBarOccupancy from '@/components/svg/AppProgressBarOccupancy.vue';
import AppProgressBarExposure from '@/components/svg/AppProgressBarExposure.vue';
import AppIconSynced from '@/components/svg/AppIconSynced.vue';
import SimpleBar from 'simplebar-vue';

// Logic...

const propertiesStore = usePropertiesStore();
const tableStore = useTableSortingStore();
const tableId = 'propertiesTable';
const handleSort = (dataKey) => tableStore.setSorting(tableId, dataKey);
const arrowDirection = (dataKey) => tableStore.arrowDirection(tableId, dataKey);
const sortedData = computed(() => tableStore.getSortedData(tableId, propertiesStore.properties));

// *******min width calculation logic START********
const getLongestString = (data) => {
  let longestLength = 0;
  data.forEach((item) => {
    const value = item.attributes.name;
    if (typeof value === 'string' && value.length > longestLength) {
      longestLength = value.length;
    }
  });
  return longestLength;
};

const longestString = getLongestString(propertiesStore.properties);

const minColumnWidth = computed(() => longestString * 10);

const firstCellStyle = computed(() => ({ '--cell-base-size': `${minColumnWidth.value}px` }));
const otherCellStyle = { '--cell-base-size': '150px' };

// *******min width calculation logic END********

const columns = [
  { key: 'name', displayName: 'Name' },
  { key: 'address', displayName: 'Location' },
  { key: 'status', displayName: 'Status' },
  { key: 'occupancy_rate', displayName: 'Occupancy' },
  { key: 'exposure_rate', displayName: 'Exposure' },
  { key: 'pms', displayName: 'PMS' },
];
</script>

<template>
  <div class="wrapper">
    <SimpleBar>
      <div class="div-column-row">
        <div
          v-for="(column, index) in columns"
          :key="column.key"
          :style="index === 0 ? firstCellStyle : otherCellStyle"
          class="header-cell sortable"
          @click="handleSort(column.key)"
        >
          {{ column.displayName }}
          <span class="sort-styles" v-html="arrowDirection(column.key)"></span>
        </div>
      </div>
      <div class="row-container" v-for="row in sortedData" :key="row">
        <div v-for="(column, index) in columns" :key="column.key" :style="index === 0 ? firstCellStyle : otherCellStyle" class="cell">
          <div v-if="column.key === 'name'" class="name-col-container">
            <div
              class="table-avatar"
              :style="`background-image: url(${row.attributes.avatar_url});`"
              role="img"
              :aria-label="`${row.attributes.name}'s avatar`"
            ></div>
            {{ row.attributes[column.key] }}
          </div>
          <div v-else-if="column.key === 'address'">City, State</div>
          <div v-else-if="column.key === 'occupancy_rate' || column.key === 'exposure_rate'" class="progress-bar-container">
            <AppProgressBarOccupancy v-if="column.key === 'occupancy_rate'" :amount="row.attributes[column.key]" />
            <AppProgressBarExposure v-else :amount="row.attributes[column.key]" />
            {{ row.attributes[column.key] }}
          </div>
          <div v-else-if="column.key === 'status'">
            <AppIconSynced />
          </div>
          <div v-else-if="column.key === 'pms'">
            <span class="pill">PMS</span>
          </div>
          <div v-else>
            {{ row.attributes[column.key] }}
          </div>
        </div>
      </div>
    </SimpleBar>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';
@import 'simplebar-vue/dist/simplebar.min.css';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 18px;
  overflow-x: auto;
}

:deep(.simplebar-scrollbar::before) {
  background-color: var(--secondary-color);
}

.div-column-row {
  display: flex;
  min-width: max-content;
  border-bottom: var(--border-size) solid var(--secondary-color);
}

.header-cell {
  padding: 15px;
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary-color);
  cursor: default;
  transition: color 0.3s ease;
  text-align: left;
  flex: 1 0 var(--cell-base-size);
  min-width: var(--cell-base-size);
}

.sort-styles {
  font-size: 12px;
  margin-left: 10px;
  align-self: start;
  vertical-align: middle;
}

.sortable:hover {
  cursor: pointer;
}

.row-container {
  display: flex;
  min-width: max-content;
  border-bottom: var(--border-size) solid var(--secondary-color);
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }

  &:last-child {
    border-bottom: none;
  }
}

.cell {
  padding: 12px 18px;
  font-size: 16px;
  text-align: left;
  transition: background-color 0.3s ease;
  font-weight: 400;
  flex: 1 0 var(--cell-base-size);
  min-width: var(--cell-base-size);
}

.table-avatar {
  border-radius: 8px;
  width: 30px;
  height: 30px;
  background-color: var(--property-img-overlay);
  background-blend-mode: multiply;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 12px;
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: smaller;
}

.name-col-container {
  display: flex;
  align-items: center;
}

.pill {
  border: 1px solid var(--primary-color);
  border-radius: 50px;
  margin: 0 5px;
  padding: 5px 15px;
  display: inline-block;
  font-size: 0.65em;
  color: var(--primary-text-color);
}
</style>
