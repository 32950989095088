<!-- BudgetEditExpenseModal.vue -->
<script setup>
// Imports...
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppSingleSelector from "@/components/ui/selects/AppSingleSelector.vue";
import AppModal from "@/components/ui/AppModal.vue";
import AppTextInput from "@/components/ui/AppTextInput.vue";
import {useBudgetTableStore} from "@/stores/BudgetTableStore";
import {useModalStore} from "@/stores/ModalStore";

// Logic...
const budgetTableStore = useBudgetTableStore();
const modalStore = useModalStore();


const updateItem = () => {
  budgetTableStore.updateItem()
  modalStore.closeModal('expenseModal')
};

const addExpenseOptions = [
  { label: 'None', value: 'None' },
  { label: 'Paid Search', value: 'Paid Search' },
  { label: 'Site Remarketing', value: 'Site Remarketing' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Digital Advertising', value: 'Digital Advertising' },
]
</script>

<template>
  <teleport to="body">
    <AppModal
        title="Expense Settings"
        modalId="editExpenseModal"
    >
      <template #modalBody>
        <!-- Edit form here -->
        <div class="edit-expense-container">
          <AppSingleSelector
              body-width="350px"
              title="Assign Existing Integration"
              placeholder="Select Integration"
              :options="addExpenseOptions"
              v-model="budgetTableStore.editItemData.integration"
          />

          <AppTextInput
              title="Custom Name"
              placeholder="Custom Name"
              v-model="budgetTableStore.editItemData.customName"
          />

          <AppSingleSelector
              body-width="350px"
              title="Add Under Existing Expense"
              placeholder="Select Parent"
              :options="budgetTableStore.parentOptions"
              v-model="budgetTableStore.editItemData.parentID"
              v-if="budgetTableStore.editItemData.parentID !== '' || (budgetTableStore.editItemData.children.length === 0 || null)"
          />
        </div>
      </template>

      <template #modalFooter>
        <div class="edit-expense-footer-container">
          <AppButton @click="updateItem">Update</AppButton>
        </div>
      </template>
    </AppModal>
  </teleport>
</template>
