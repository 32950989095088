<!-- BudgetsIconDotsMenu -->
<script setup>
import { ref } from 'vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import { onClickOutside } from '@vueuse/core/index';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';

// Logic...

const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  // Handle the option clicked logic here
  console.log(`Option ${option.text} clicked`);
}
</script>

<template>
  <div class="menu" ref="target">
    <AppIconBoxWrapper @click="toggleOverlay">
      <AppIconDotsVertical />
    </AppIconBoxWrapper>
    <AppOverlayMenu
      v-if="isOverlayVisible"
      @option-clicked="handleOptionClicked"
      :options="['edit-settings', 'import-expenses', 'export-to-csv', 'copy-budget']"
    >
      <template #edit-settings>
        <div>Edit settings</div>
      </template>
      <template #import-expenses>
        <div>Import expenses</div>
      </template>
      <template #export-to-csv>
        <div>Export to CSV</div>
      </template>
      <template #copy-budget>
        <div>Copy budget</div>
      </template>
    </AppOverlayMenu>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../styles/settings/mixins';

:deep(.overlay-menu) {
  min-width: 160px;
}
</style>
