<!-- AppCheckboxButton.vue -->
<script setup>
import AppIconCheckMark from "@/components/svg/AppIconCheckMark.vue";

const props = defineProps({
  isChecked: Boolean,
  label: String,
});

const emit = defineEmits(['checkboxClicked']);

function handleCheckboxClick() {
  emit('checkboxClicked', props.label);
}
</script>

<template>
  <span class="btn-container" @click="handleCheckboxClick">
    <span v-if="!isChecked" class="checkbox"></span>
    <span v-else class="checked">
      <AppIconCheckMark class="check-icon" />
    </span>
      {{ label }}
  </span>
</template>

<style scoped>

.btn-container {
  font-size: 16px;
  background: none;
  border: none;
  position: relative;
  padding: 7px 41px;
  border-radius: 5px;
  display: block;
  width: 100%;
  text-align: left;
  min-height: 10px;
  transition: background 0.4s, color 0.4s;
  cursor: pointer;
}

.checkbox {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  background-color: var(--dropdown-bg);
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 5px;
}

.checked {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
</style>
