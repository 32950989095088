<!-- AppIconAngleDownPair.vue -->
<script setup>
</script>

<template>
  <svg class="icon-color" width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.786526 15.2135C1.16856 15.5955 1.78796 15.5955 2.17 15.2135L8.69174 8.69173C9.07377 8.3097 9.07377 7.6903 8.69174 7.30826L2.17 0.786526C1.78796 0.404491 1.16856 0.404491 0.786526 0.786526C0.404491 1.16856 0.404491 1.78796 0.786526 2.16999L6.61653 8L0.786526 13.83C0.404491 14.212 0.404491 14.8314 0.786526 15.2135Z" fill="currentColor"/>
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
