<!-- UsersTopFilterSection.vue -->
<script setup>
// Imports...
import AppButton from '@/components/ui/buttons/AppButton.vue';
import { orderByOptionsData, clientOptionsData, userLevelOptionsData } from '@/data/usersFilterOptions';
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import AppKeywordSearch from '@/components/ui/search/AppKeywordSearch.vue';
import { useUsersStore } from '@/stores/UsersStore';
import { debounce } from '@/helpers/debounce';

// Logic...

// fetched data
const orderByOptions = orderByOptionsData;
const clientOptions = clientOptionsData;
// const ownerOptions = ownerOptionsData
const userLevelOptions = userLevelOptionsData;

// Store instances
const globalStore = useGlobalStore();
const usersStore = useUsersStore();

// Handle visibility for the top filter navbar inputs and selectors
const handleClick = () => {
  globalStore.toggleVisibleFilter();
};

//Debounce the search input
const debouncedSearch = debounce(() => {
  usersStore.applyFilters();
}, 500);
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <!--Search section -->
      <div class="flex-auto">
        <AppKeywordSearch
          label="Keywords"
          placeholder="Email, name, property, etc."
          v-model="usersStore.selectedKeyword"
          @Submit.prevent="debouncedSearch"
          @keyup.enter="debouncedSearch"
        />
      </div>
      <!-- Client Selector -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Client" placeholder="Select a client" :options="clientOptions" v-model="usersStore.selectedClient" />
      </AppVisibleFilterWrapper>
      <!-- User Level Dropdown -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="User level" placeholder="Select a user level" :options="userLevelOptions" v-model="usersStore.selectedUserLevel" />
      </AppVisibleFilterWrapper>
      <!-- OrderBy Dropdown -->
      <AppVisibleFilterWrapper>
        <AppSingleSelector title="Order by" placeholder="Select an option" :options="orderByOptions" v-model="usersStore.selectedOrderBy" />
      </AppVisibleFilterWrapper>
      <!-- filter button-->
      <div class="filter-button-container">
        <div class="filter-clear-all" v-if="usersStore.searchPerformed" @click="usersStore.resetSearch">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>
        <AppButton label="Search" class="filter-button-bottom" :isLoading="usersStore.isLoading" @click="usersStore.applyFilters" />
      </div>
      <!-- Toggle Button for mobile -->
      <AppButton toggle_btn @click="handleClick">
        <AppIconAngleDownPair />
      </AppButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.grid {
  display: flex;
  align-items: center; // Vertically center items
  flex-wrap: wrap;
  gap: 15px;
}

.flex-auto {
  flex: 1 1 auto; // Flex item can grow and shrink but is initially sized to content
}

.flex-basis {
  flex-basis: content;
}

.visible-mobile {
  display: none;

  /*  Tablet  */

  @include tablet {
    display: block;
  }
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  text-decoration: underline;
  height: 15px;
  visibility: hidden;
}

.filter-clear-all {
  visibility: visible;
  text-decoration: underline;

  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }

  &:active {
    opacity: 0.7;
  }
}
</style>
