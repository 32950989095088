<!-- AppTextInput.vue -->
<script setup>
import { ref } from 'vue';

const props = defineProps({
  title: String,
  placeholder: String,
  modelValue: String,
  visible: Boolean,
  type: {
    type: String,
    default: 'text',
  },
});

const textValue = ref(props.modelValue);
const emit = defineEmits(['update:modelValue']);

function handleInputChange() {
  emit('update:modelValue', textValue.value);
}

import { watch } from 'vue';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';

watch(
  () => props.modelValue,
  (newValue) => {
    textValue.value = newValue; // Update internal value
  }
);
</script>

<template>
  <AppVisibleFilterWrapper>
    <div class="text-input-container">
      <div>
        <div class="text-input-header">
          <label for="text-input" class="input-label">{{ title }}</label>
          <input id="text-input" :type="type" :placeholder="placeholder" v-model="textValue" @input="handleInputChange" />
        </div>
      </div>
    </div>
  </AppVisibleFilterWrapper>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.input-label {
  display: block;
  font-weight: 400;
  padding-left: 12px;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
}

// Adjusting this label color for labels inside of a modal
.modal-content {
  .input-label {
    color: var(--primary-text-color);
  }

  .modal-items {
    .text-input-container {
      padding: 10px 0;

      &:first-child {
        padding-top: 0;
      }
    }
  }
}

.text-input-container {
  position: relative;

  .text-input-header {
    cursor: pointer;

    > input {
      font-size: 16px;
      font-family: 'Lato', sans-serif;
      background-color: transparent;
      font-weight: 500;
      border: var(--border-size) solid var(--secondary-color);
      border-radius: 12px;
      height: 45px;
      line-height: 40px;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;

      &:focus {
        border-color: var(--primary-color);
        outline: none;
        transition: 0.4s ease;
      }

      &::placeholder {
        color: var(--secondary-color);
      }
    }
  }
}

.flex-auto {
  flex: auto;
}

.mobile-input-sm {
  @include tablet {
    flex: 33%;
  }
  @include mobile {
    flex: 33%;
    min-width: 33%;
  }
}

.hidden-mobile {
  /*  Mobile  */

  @include mobile {
    display: none !important;
  }

  &.is-visible-mobile {
    /*  Mobile  */

    @include mobile {
      display: block !important;
    }
  }
}

.hidden-tablet {
  /*  Tablet  */

  @include tablet {
    display: none !important;
  }

  &.is-visible-tablet {
    /*  Mobile  */

    @include tablet {
      display: block !important;
    }
  }
}
</style>
