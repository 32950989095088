import { previews } from '@/helpers/IconRegistry';
import { largeWidget, smallWidget } from '@/helpers/WidgetSizes';
import { v4 as uuidv4 } from 'uuid';

const commonFields = {
  icon: null,
  integration: 'Reputation',
};

export const reputationWidgets = [
  {
    title: 'Total Reviews',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'TotalReviewsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Average Rating',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'AverageRatingMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Reviews Responded',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'ReviewsRespondedMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Responded To Rate',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'RespondedToRateMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: '1 Star Reviews',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'OneStarReviewsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: '5 Star Reviews',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'FiveStarReviewsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Overall Reviews',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'OverallReviewsBarChart',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Review Comps',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'ReviewCompsTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Listing Review Summary',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'ListingReviewSummaryTable',
    ...largeWidget,
    ...commonFields,
  },
];
