<!-- AppIconSearch.vue -->
<script setup>

</script>

<template>
<svg class="icon-color" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="colorize" d="M12.9568 14.5925L20.0242 21.6581C20.4747 22.1085 21.2075 22.1085 21.6635 21.6581C22.114 21.2077 22.114 20.4751 21.6635 20.0192L14.5961 12.9591C15.6112 11.6078 16.2082 9.92551 16.2082 8.10755C16.2137 3.63049 12.5823 0 8.10412 0C3.63138 0 0 3.63049 0 8.10212C0 12.5738 3.63138 16.2042 8.10412 16.2042C9.92253 16.2097 11.6052 15.6073 12.9568 14.5925ZM8.10412 2.31722C11.3013 2.31722 13.8959 4.9112 13.8959 8.10755C13.8959 11.3039 11.3013 13.8979 8.10412 13.8979C4.90698 13.8979 2.31236 11.3039 2.31236 8.10755C2.31779 4.9112 4.91241 2.31722 8.10412 2.31722Z" />
</svg>

</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}</style>
