<!-- UsersPageHeader.vue -->
<script setup>
// Imports...
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core/index';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';

// Logic...

const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  // Handle the option clicked logic here
  console.log(`Option ${option.text} clicked`);
}
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <h3>Users</h3>
      </div>
      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <div ref="target">
          <AppIconBoxWrapper @click="toggleOverlay">
            <AppIconDotsVertical />
          </AppIconBoxWrapper>
          <AppOverlayMenu v-if="isOverlayVisible" @option-clicked="handleOptionClicked" :options="['export', 'import', 'settings']">
            <template #export>
              <div>Export</div>
            </template>
            <template #import>
              <div>Import</div>
            </template>
            <template #settings>
              <div>Settings</div>
            </template>
          </AppOverlayMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';
</style>
