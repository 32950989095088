import { icons, previews } from '@/helpers/IconRegistry';
import { largeWidget, smallWidget } from '@/helpers/WidgetSizes';
import { v4 as uuidv4 } from 'uuid';
import { mediumWidget } from '../WidgetSizes';

const commonFields = {
  icon: icons.AppGoogleAdsIcon,
  integration: 'Google Ads',
};

export const googleAdsWidgets = [
  {
    title: 'Search Ad Groups',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'SearchAdGroupsTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Avg CPC',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    i: uuidv4(),
    component: 'AvgCpcLine',
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Avg. CTR',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    i: uuidv4(),
    component: 'AvgCtrLine',
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Clicks & Conversions Activity',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    i: uuidv4(),
    component: 'ClickConvActivityLine',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Clicks',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'AdsClicksMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Clicks',
    type: 'Bar Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppBarPreview,
    i: uuidv4(),
    component: 'ClicksBar',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Click-Through-Rate',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'ClickThroughRateMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Conversions',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'AdsConversionsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Conversions',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    i: uuidv4(),
    component: 'AdsConversionsLine',
    ...mediumWidget,
    ...commonFields,
  },
  {
    title: 'Conversion Rate',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'ConvRateMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Cost',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'CostMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Cost-Per-Click',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'CostPerClickMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Cost-Per-Conversion',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'AdsCostPerConvMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Impressions',
    type: 'Metric',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppWidgetPreview,
    i: uuidv4(),
    component: 'AdsImpressionsMetric',
    ...smallWidget,
    ...commonFields,
  },
  {
    title: 'Impression Share',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'ImpressionShareTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Keywords & Search Queries',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'KeywordsSearchQTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Search Ads',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'SearchAdsTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Search Campaigns',
    type: 'Table',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppTablePreview,
    i: uuidv4(),
    component: 'SearchCampaignsTable',
    ...largeWidget,
    ...commonFields,
  },
  {
    title: 'Total Cost',
    type: 'Line Chart',
    description: 'Gorem ipsum dolor sit amet, adipiscing elit. Nunc vulputate libero et velit interdum...',
    preview: previews.AppLinePreview,
    i: uuidv4(),
    component: 'TotalCostLine',
    ...mediumWidget,
    ...commonFields,
  },
];
