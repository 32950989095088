import { defineStore } from 'pinia';

// end day should always be current date transformed to YYYY-MM-DD
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;
const currentDay = currentDate.getDate();
const currentDateString = `${currentYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${currentDay < 10 ? `0${currentDay}` : currentDay}`;

// start day should always be the first day of the month but 6 periods back from the current date
const startDay = new Date(currentYear, currentMonth - 6, 1);
const startYear = startDay.getFullYear();
const startMonth = startDay.getMonth() + 1;
const startDayOfMonth = startDay.getDate();
const startDayString = `${startYear}-${startMonth < 10 ? `0${startMonth}` : startMonth}-${startDayOfMonth < 10 ? `0${startDayOfMonth}` : startDayOfMonth}`;

export const useTrendingReportStore = defineStore('TrendingReportStore', {
  state: () => ({
    trendingReport: {
      report_type: 'tending_report',
      period_type: 3, // 1: daily, 2: weekly, 3: monthly
      num_of_periods: 6,
      startDay: startDayString,
      endDay: currentDateString,
    },
    reportName: 'Trending Report',
    recurringOptions: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    selectedRecurring: 'no',
    periodOptions: [
      { label: 'Daily', value: 1 },
      { label: 'Weekly', value: 2 },
      { label: '30 day', value: 3 },
      { label: 'Monthly', value: 4 },
      { label: 'Yearly', value: 5 },
    ],
    selectedPeriod: 3,
    numPeriodOptions: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 },
    ],
    numSelectedPeriod: 6,
    reportOptions: [
      { label: 'Website Performance', value: 'website_performance' },
      { label: 'Ads Search', value: 'ads_search' },
      { label: 'Ads Site Remarketing', value: 'ads_site_remarketing' },
      { label: 'Ads In-Market Audiences', value: 'ads_in_market_audiences' },
      { label: 'Ads Custom Intent Audiences', value: 'ads_custom_intent_audiences' },
      { label: 'Ads Demand Gen', value: 'ads_demand_gen' },
      { label: 'Ads P-Max', value: 'ads_p_max' },
      { label: 'Ads Video', value: 'ads_video' },
      { label: 'Google Business Profile', value: 'google_business_profile' },
      { label: 'Meta Ads Performance', value: 'meta_ads_performance' },
      { label: 'Leasing Performance', value: 'leasing_performance' },
      { label: 'LP Conversion Rate', value: 'lp_conversion_rate' },
      { label: 'LP Contact Type', value: 'lp_contact_type' },
      { label: 'Availability', value: 'availability' },
      { label: 'Reputation Overall', value: 'reputation_overall' },
      { label: 'Reputation Google', value: 'reputation_google' },
    ],
    selectedReportOptions: [
      'website_performance', // 'website_performance'
      'ads_search', // 'ads_search'
    ],
  }),

  getters: {
    report(state) {
      const report = {};
      state.reportOptions.forEach((option) => {
        report[option.value] = state.selectedReportOptions.includes(option.value);
      });
      return report;
    },
  },

  actions: {
    toggleOption(option) {
      const index = this.selectedReportOptions.indexOf(option.value);
      if (index > -1) {
        this.selectedReportOptions.splice(index, 1);
      } else {
        this.selectedReportOptions.push(option.value);
      }
    },
    updateReportSettings(newSettings) {
      this.reportName = newSettings.reportName;
      this.selectedRecurring = newSettings.selectedRecurring;
      this.selectedPeriod = newSettings.selectedPeriod;
      this.numSelectedPeriod = newSettings.numSelectedPeriod;
      this.selectedReportOptions = [...newSettings.selectedReportOptions];

      // Update trendingReport properties
      this.trendingReport.period_type = parseInt(newSettings.selectedPeriod);
      this.trendingReport.num_of_periods = parseInt(newSettings.numSelectedPeriod);
    },
  },
});
