<!-- AppKeywordSearch.vue -->
<script setup>

import AppIconClose from "@/components/svg/AppIconClose.vue";

const props = defineProps({
  placeholder: String,
  modelValue: String,
  label: String,
});

const emit = defineEmits(['update:modelValue']);

const clearInput = () => {
  emit('update:modelValue', '');
};

</script>

<template>
  <div class="search-alt">
    <label for="q" class="input-label">{{ props.label }}</label>
    <form action="?" method="get">
      <div class="input-wrapper">
        <input
            type="search"
            class="search__field keyword__field"
            name="q"
            id="q"
            :value="props.modelValue"
            :placeholder="props.placeholder"
            @input="$emit('update:modelValue', $event.target.value)"
            autocomplete="off"
        />

        <AppIconClose v-if="props.modelValue" @click="clearInput"  class="clear-icon" />
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.clear-icon {
  cursor: pointer;
  width: 11px;
  height: 11px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-alt {
  position: relative;
  width: 100%;

  form {
    display: flex;
    width: 100%;
  }

  .search__btn {
    background: none;
    border: none;
    margin-right: 10px;
  }

  .search__field {
    flex-grow: 1;
    border: var(--border-size) solid var(--secondary-color);
    border-radius: 12px;
    height: 45px;
    background: none;
    padding: 0 15px;
    color: var(--primary-text-color);
    transition: .4s;

    &:focus,
    &:hover {
      border-color: var(--primary-color);
    }
  }
}

.input-label {
  display: block;
  font-weight: 400;
  padding-left: 16px;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
}

::placeholder {
  color: var(--secondary-color);
}
</style>

