<script setup>
import { defineAsyncComponent, computed, onBeforeMount } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import { useSettingsStore } from '@/stores/SettingsStore';
import AppSpinner from '@/components/ui/AppSpinner.vue';

// Modal imports
import SettingsGa4Modal from '@/components/ui/modals/SettingsGa4Modal.vue';
import SettingsGoogleAdsModal from '@/components/ui/modals/SettingsGoogleAdsModal.vue';
import SettingsGBPModal from '@/components/ui/modals/SettingsGBPModal.vue';
import SettingsGTMModal from '@/components/ui/modals/SettingsGTMModal.vue';
import SettingsKyzenApiModal from '@/components/ui/modals/SettingsKyzenApiModal.vue';
import SettingsChatMeterModal from '@/components/ui/modals/SettingsChatMeterModal.vue';
import SettingsNotSyncedModal from '@/components/ui/modals/SettingsNotSyncedModal.vue';

// Store instances
const modalStore = useModalStore();
const settingsStore = useSettingsStore();

// Tracking if the view is loading to display a spinner in the middle of the screen for UX feedback
const isViewLoading = computed(() => settingsStore.isLoading);

// Dynamically importing our views based on the selected setting
const currentViewComponent = computed(() => {
  switch (settingsStore.currentView) {
    case 'Profile':
      return defineAsyncComponent(() =>
        import('@/components/settings/ProfileGridView.vue').then((module) => {
          settingsStore.isLoading = false; // Set loading to false once loaded
          return module;
        })
      );
    case 'Permissions':
      return defineAsyncComponent(() =>
        import('@/components/settings/PermissionsGridView.vue').then((module) => {
          settingsStore.isLoading = false;
          return module;
        })
      );
    case 'Integrations':
      return defineAsyncComponent(() =>
        import('@/components/settings/IntegrationsGridView.vue').then((module) => {
          settingsStore.isLoading = false;
          return module;
        })
      );
    default:
      return defineAsyncComponent(() =>
        import('@/components/settings/IntegrationsGridView.vue').then((module) => {
          settingsStore.isLoading = false;
          return module;
        })
      );
  }
});

// Fetch settings data when the component is mounted
onBeforeMount(() => {
  settingsStore.fetchSettings();
});
</script>

<template>
  <div class="styles_view__body">
    <!-- Show loading spinner in the middle of the layout while switching views -->
    <div v-if="isViewLoading" class="center-spinner">
      <AppSpinner />
    </div>
    <component v-else :is="currentViewComponent" />
  </div>
  <!-- Show loading spinner while initially fetching settings data -->
  <div v-if="settingsStore.isLoading && !isViewLoading" class="center-spinner">
    <AppSpinner />
  </div>
  <!-- Modals -->
  <SettingsGa4Modal v-if="modalStore.isModalOpen('syncedGoogleAnalytics4Modal')" />
  <SettingsGoogleAdsModal v-if="modalStore.isModalOpen('syncedGoogleAdsModal')" />
  <SettingsGBPModal v-if="modalStore.isModalOpen('syncedGoogleBusinessProfileModal')" />
  <SettingsGTMModal v-if="modalStore.isModalOpen('syncedGoogleTagManagerModal')" />
  <SettingsKyzenApiModal v-if="modalStore.isModalOpen('syncedKyzenAPIModal')" />
  <SettingsChatMeterModal v-if="modalStore.isModalOpen('syncedChatmeterAPIModal')" />
  <SettingsNotSyncedModal v-if="modalStore.isModalOpen('settingsNotSyncedModal')" />
  <!-- Modals End -->
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.styles_view__body {
  overflow: visible;
}

.section__body-alt {
  max-width: 2100px;
  margin: auto;
  padding: 178px 30px;

  @include mobile {
    padding: 178px 20px;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(97, 112, 134, 0.25);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border-radius: 5px;
  }
}

.center-spinner {
  display: flex;
  justify-content: center;
}
</style>
