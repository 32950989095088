<!-- SettingsGa4Modal.vue -->
<script setup>
import { ref, computed } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import { useModalActions } from '@/composables/useModalActions';
import AppModal from '@/components/ui/AppModal.vue';
import AppMultiSelector from '@/components/ui/selects/AppMultiSelector.vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';
import AppCheckboxButton from '@/components/ui/buttons/AppCheckboxButton.vue';
import AppCopyTextInput from '@/components/ui/AppCopyTextInput.vue';
import AppListPills from '@/components/ui/AppListPills.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import useSearch from '@/composables/useSearch';
import AppIconCopy from '@/components/svg/AppIconCopy.vue';
import AppIconEye from '@/components/svg/AppIconEye.vue';
import AppIconLink from '@/components/svg/AppIconLink.vue';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import { goals, accountOptions, properties, propertyID, webStreamID, pagePath } from '@/data/settings';

const modalStore = useModalStore();
const { simulateSave, simulateResync } = useModalActions();

const isModalOpen = computed(() => modalStore.isModalOpen('syncedGoogleAnalytics4Modal'));
const isLoading = computed(() => modalStore.modalLoading); // Using loading state from store

// Create separate search logic for accounts and properties
const { searchValue: accountSearchValue, filteredOptions: filteredAccounts } = useSearch(accountOptions);
const { searchValue: propertySearchValue, filteredOptions: filteredProperties } = useSearch(properties);
const { searchValue: pagePathSearchValue, filteredOptions: filteredPagePath } = useSearch(pagePath);

// Separate selected options for accounts and properties
const selectedAccounts = ref([]);
const selectedProperties = ref([]);
const selectedPagePath = ref([]);

// Handle selection for accounts
function handleAccountSelection(option) {
  const index = selectedAccounts.value.findIndex((acc) => acc.id === option.id);
  if (index > -1) {
    selectedAccounts.value.splice(index, 1);
  } else {
    selectedAccounts.value.push(option);
  }
}

// Handle selection for properties
function handlePropertySelection(option) {
  const index = selectedProperties.value.findIndex((prop) => prop.id === option.id);
  if (index > -1) {
    selectedProperties.value.splice(index, 1);
  } else {
    selectedProperties.value.push(option);
  }
}

// Handle selection for page path
function handlePagePathSelection(option) {
  const index = selectedPagePath.value.findIndex((prop) => prop.id === option.id);
  if (index > -1) {
    selectedPagePath.value.splice(index, 1);
  } else {
    selectedPagePath.value.push(option);
  }
}

// Check if an account, property or page path is selected
function isAccountChecked(accountId) {
  return selectedAccounts.value.some((acc) => acc.id === accountId);
}

function isPropertyChecked(propertyId) {
  return selectedProperties.value.some((prop) => prop.id === propertyId);
}

function isPagePathChecked(pagePathID) {
  return selectedPagePath.value.some((prop) => prop.id === pagePathID);
}

// Copy text to clipboard function
function copyToClipboard(text) {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert('Copied to clipboard!'); // You can replace this with a toast or another notification
    })
    .catch((err) => {
      console.error('Failed to copy: ', err);
    });
}

// Extract property ID value from `propertyID` in our data
const currentPropertyID = ref(propertyID[0].value);
// Extract WebStream ID value from `WebStreamID` in our data
const currentWebStreamID = ref(webStreamID[0].value);
</script>

<template>
  <teleport to="body">
    <AppModal title="Google Analytics 4" modal-id="syncedGoogleAnalytics4Modal" v-model:isModalOpen="isModalOpen">
      <template #modalBody>
        <div v-if="isLoading" class="spinner-wrapper">
          <AppSpinner />
        </div>

        <div v-else>
          <!-- Account MultiSelector -->
          <AppMultiSelector title="Account" v-model:selectedOptions="selectedAccounts" placeholder="Select one or more accounts">
            <template v-slot:body>
              <AppSearch placeholder="Search accounts..." v-model="accountSearchValue" @click.stop />
              <ul class="list">
                <li class="list-item" v-for="account in filteredAccounts" :key="account.id">
                  <div class="list-item-contents-wrapper">
                    <AppCheckboxButton
                      :label="account.name"
                      :is-check="true"
                      :is-checked="isAccountChecked(account.id)"
                      @checkboxClicked="handleAccountSelection(account)"
                    />
                  </div>
                </li>
              </ul>
            </template>
          </AppMultiSelector>

          <!-- Properties MultiSelector -->
          <AppMultiSelector title="Properties" v-model:selectedOptions="selectedProperties" placeholder="Select one or more properties">
            <template v-slot:body>
              <AppSearch placeholder="Search properties..." v-model="propertySearchValue" @click.stop />
              <ul class="list">
                <li class="list-item" v-for="property in filteredProperties" :key="property.id">
                  <div class="list-item-contents-wrapper">
                    <AppCheckboxButton
                      :label="property.name"
                      :is-check="true"
                      :is-checked="isPropertyChecked(property.id)"
                      @checkboxClicked="handlePropertySelection(property)"
                    />
                  </div>
                </li>
              </ul>
            </template>
          </AppMultiSelector>

          <!-- Property ID -->
          <AppCopyTextInput title="Property ID" :modelValue="currentPropertyID" readonly>
            <!-- Passing the copy icon through the slot -->
            <template #icon>
              <button @click="copyToClipboard(currentPropertyID)">
                <AppIconCopy />
              </button>
            </template>
          </AppCopyTextInput>

          <!-- WebStream ID -->
          <AppCopyTextInput title="WebStream" :modelValue="currentWebStreamID" readonly>
            <!-- Passing the copy icon through the slot -->
            <template #icon>
              <button @click="copyToClipboard(currentWebStreamID)">
                <AppIconCopy />
              </button>
            </template>
          </AppCopyTextInput>

          <!-- Page Path MultiSelector -->
          <AppMultiSelector title="Page Path" v-model:selectedOptions="selectedPagePath" placeholder="Select one or more page path">
            <template v-slot:body>
              <AppSearch placeholder="Search pages..." v-model="pagePathSearchValue" @click.stop />
              <ul class="list">
                <li class="list-item" v-for="page in filteredPagePath" :key="page.id">
                  <div class="list-item-contents-wrapper">
                    <AppCheckboxButton
                      :label="page.name"
                      :is-check="true"
                      :is-checked="isPagePathChecked(page.id)"
                      @checkboxClicked="handlePagePathSelection(page)"
                    />
                  </div>
                </li>
              </ul>
            </template>
          </AppMultiSelector>

          <!-- Goals Pill Box -->
          <AppListPills title="Goals" :listItems="goals" />
        </div>
      </template>

      <!-- Modal Sub-Footer -->
      <template #modalSubFooter>
        <div class="modal-sync-buttons">
          <AppButton label="Unlink" :isLoading="isLoading" @click="simulateSave('syncedGoogleAnalytics4Modal')">
            <AppIconLink />
          </AppButton>
          <AppButton label="Resync" :isLoading="isLoading" @clicked="simulateResync">
            <AppIconLink />
          </AppButton>
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modalFooter>
        <div class="modal-controls">
          <div class="show-script">
            <AppIconEye />
            Show Script
          </div>
          <div>
            <AppButton label="Save" :isLoading="isLoading" @clicked="simulateSave('syncedGoogleAnalytics4Modal')" />
          </div>
        </div>
      </template>
    </AppModal>
  </teleport>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.list {
  list-style: none;
  padding: 10px 0;
}

.list-item-contents-wrapper {
  padding: 2px 4px;
  border-radius: 5px;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}

.copy-button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  z-index: 2;

  svg {
    width: 100%;
    height: 100%;
  }
}

.modal-sync-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  button {
    background: none;
    border-radius: 12.799px;
    border: 0.853px solid var(--secondary-color);
    color: var(--primary-text-color);
    font-size: 17.066px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.452px;
    /* 155% */
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.modal-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  button {
    border-radius: 8px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;

    &:first-child {
      margin-right: 5px;
    }
  }
}

.show-script {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
</style>
