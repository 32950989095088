import { defineStore } from 'pinia';
import { reportOptionsData } from '@/data/reportsData';

export const useReportsStore = defineStore('ReportsStore', {
  state: () => ({
    report: false,
    landingPage: true,
    isLoading: false,
    reportOptions: [...reportOptionsData], // here for  future use
    selectedReport: null,
    benchmark: false,
    trending: false,
    reportName: '',
  }),
  getters: {},
  actions: {
    loadReport(selectedReport) {
      this.isLoading = true;
      setTimeout(() => {
        if (selectedReport === 1) {
          this.landingPage = false;
          this.selectedReport = 1;
          this.benchmark = true;
          this.trending = false;
          this.report = true;
          this.reportName = this.reportOptions.find((report) => report.value === selectedReport).label;
        } else if (selectedReport === 6) {
          this.landingPage = false;
          this.selectedReport = 6;
          this.trending = true;
          this.benchmark = false;
          this.report = true;
          this.reportName = this.reportOptions.find((report) => report.value === selectedReport).label;
        } else {
          this.landingPage = true;
          this.selectedReport = null;
          this.report = false;
          this.reportName = 'Report';
          this.benchmark = false;
          this.trending = false;
        }
        this.isLoading = false;
      }, 2000);
    },
    resetReport() {
      this.report = false;
      this.landingPage = true;
      this.benchmark = false;
      this.trending = false;
      this.selectedReport = null;
    },
  },
});
