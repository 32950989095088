// LoginStore.js

import { defineStore } from 'pinia';

export const useLoginStore = defineStore({
  id: 'step',
  state: () => ({
    step: 'email',
    validationError: null,  // track all validation errors
    isEmailInvalid: false,
    isPasswordInvalid: false
  }),
  actions: {
    setStep(newStep) {
      this.step = newStep;
    },
    setValidationError(error) {  // set validation errors
      this.validationError = error;
    },
    setEmailInvalid(isInvalid) {
      this.isEmailInvalid = isInvalid;
    },
    setPasswordInvalid(isInvalid) {
      this.isPasswordInvalid = isInvalid;
    },
    validateEmail(email) {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      this.isEmailInvalid = !re.test(String(email).toLowerCase());
      return !this.isEmailInvalid;
    },
    validatePassword(password) {
      this.isPasswordInvalid = password.length < 8;
      return !this.isPasswordInvalid;
    }
  },
});
