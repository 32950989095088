<!-- AppIconDotsVertical.vue -->
<script setup>

</script>

<template>
  <svg class="icon-color" width="13" height="13" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="colorize"
          d="M13.3635 2.86364C13.3635 1.28209 12.0814 0 10.4999 0C8.91832 0 7.63623 1.28209 7.63623 2.86364C7.63623 4.44518 8.91832 5.72727 10.4999 5.72727C12.0814 5.72727 13.3635 4.44518 13.3635 2.86364Z"
          />
    <path class="colorize"
          d="M13.3635 10.5C13.3635 8.91845 12.0814 7.63635 10.4999 7.63635C8.91832 7.63635 7.63623 8.91845 7.63623 10.5C7.63623 12.0815 8.91832 13.3636 10.4999 13.3636C12.0814 13.3636 13.3635 12.0815 13.3635 10.5Z"
          />
    <path class="colorize"
          d="M13.3635 18.1363C13.3635 16.5548 12.0814 15.2727 10.4999 15.2727C8.91832 15.2727 7.63623 16.5548 7.63623 18.1363C7.63623 19.7179 8.91832 21 10.4999 21C12.0814 21 13.3635 19.7179 13.3635 18.1363Z"
          />
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
