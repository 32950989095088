<!-- SettingsGBPModal.vue -->
<script setup>
import { ref, computed } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import { useModalActions } from '@/composables/useModalActions';
import AppModal from '@/components/ui/AppModal.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppTextInput from '@/components/ui/AppTextInput.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import AppIconResync from '@/components/svg/AppIconResync.vue';
import AppIconLink from '@/components/svg/AppIconLink.vue';
import { googleBusinessAccountOptions } from '@/data/settings';
import { propertyGroupOptions } from '@/data/properties';

const modalStore = useModalStore();
const { simulateSave, simulateResync } = useModalActions();

const isModalOpen = computed(() => modalStore.isModalOpen('syncedGoogleBusinessProfileModal'));
const isLoading = computed(() => modalStore.modalLoading); // Using loading state from store

const selectedAccount = ref('');
const selectedProperty = ref('');
</script>

<template>
  <teleport to="body">
    <AppModal title="Google Business Profile" modal-id="syncedGoogleBusinessProfileModal" v-model:isModalOpen="isModalOpen">
      <template #modalBody>
        <div v-if="isLoading" class="spinner-wrapper">
          <AppSpinner />
        </div>

        <div v-else>
          <!-- Account Selector -->
          <AppSingleSelector
            body-width="350px"
            title="Account"
            placeholder="Select an account"
            :options="googleBusinessAccountOptions"
            v-model="selectedAccount"
          />

          <!-- Property Selector -->
          <AppSingleSelector
            body-width="350px"
            title="Property"
            placeholder="Select a property"
            :options="propertyGroupOptions"
            v-model="selectedProperty"
          />

          <!-- Yelp URL -->
          <AppTextInput title="Yelp URL" placeholder="Enter a URL" class="modal-input" />

          <!-- Bing URL -->
          <AppTextInput title="Bing URL" placeholder="Enter a URL" class="modal-input" />
        </div>
      </template>

      <!-- Modal Sub-Footer -->
      <template #modalSubFooter>
        <div class="modal-sync-buttons">
          <AppButton label="Unlink" :isLoading="isLoading" @click="simulateSave('syncedGoogleBusinessProfileModal')">
            <AppIconLink />
          </AppButton>
          <AppButton label="Resync" :isLoading="isLoading" @clicked="simulateResync">
            <AppIconLink />
          </AppButton>
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modalFooter>
        <div class="modal-controls">
          <AppButton label="Save" :isLoading="isLoading" @clicked="simulateSave('syncedGoogleBusinessProfileModal')" />
        </div>
      </template>
    </AppModal>
  </teleport>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.list {
  list-style: none;
  padding: 10px 0;
}

.list-item-contents-wrapper {
  padding: 2px 4px;
  border-radius: 5px;

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
  }
}

.toggle-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  padding-bottom: 50px;
  font-weight: 700;
}

.modal-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border-radius: 8px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;

    &:first-child {
      margin-right: 5px;
    }
  }
}

.modal-sync-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  button {
    background: none;
    border-radius: 12.799px;
    border: 0.853px solid var(--secondary-color);
    color: var(--primary-text-color);
    font-size: 17.066px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.452px;
    /* 155% */
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
