<!-- BudgetTableFirstRowCol.vue -->
<script setup>
import AppIconTableDropdownArrow from '@/components/svg/AppIconTableDropdownArrow.vue';
import AppIconTableMenu from '@/components/svg/AppIconTableMenu.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import { useBudgetTableStore } from '@/stores/BudgetTableStore';
import { useModalStore } from '@/stores/ModalStore';
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

defineProps({
  item: {
    type: Object,
    required: true,
  },
  parentId: {
    type: [String, Number],
    default: null,
  },
});

const budgetTableStore = useBudgetTableStore();
const modalStore = useModalStore();

const target = ref(null);

onClickOutside(target, () => {
  budgetTableStore.showOverlay = false;
});

const openEditModal = (item) => {
  budgetTableStore.openEditModal(item);
  modalStore.openModal('expenseModal');
};
</script>

<template>
  <div class="first-row-container">
    <div class="icon-dropdown-container">
      <AppIconTableDropdownArrow
        v-if="item.children && item.children.length > 0"
        :class="{ 'icon-rotated': budgetTableStore.openDrawers.includes(item.id) }"
        @click="budgetTableStore.toggleDrawer(item.id)"
      />
    </div>
    <p :class="{ 'child-style': parentId !== null }">{{ item.name || item.data_source }}</p>
    <div class="overlay-icon-container" ref="target">
      <AppIconTableMenu @icon-clicked="budgetTableStore.handleIconClicked(item.id)" />
      <AppOverlayMenu
        v-if="budgetTableStore.showOverlay === item.id"
        @option-clicked="budgetTableStore.handleOptionClicked"
        :options="['editOption', 'deleteOption']"
      >
        <template #editOption>
          <p @click="openEditModal(item)">Edit</p>
        </template>
        <template #deleteOption>
          <button class="delete-button-overlay" @click="budgetTableStore.handleOptionClicked('Delete', item.id, parentId)">Delete</button>
        </template>
      </AppOverlayMenu>
    </div>
  </div>
</template>

<style scoped lang="scss">
.first-row-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  min-width: 400px;
  border-right: var(--border-size) solid var(--secondary-color);
  border-bottom: var(--border-size) solid var(--secondary-color);
}

.first-row-container > :last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}
.first-row-container > :last-child {
  width: 50px;
  align-self: center;
}

.first-row-container > p {
  flex-grow: 1;
  margin: 0 10px;
  text-align: left;
  align-self: center;
}

.icon-dropdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 50px;
  border-right: var(--border-size) solid var(--secondary-color);
}

.icon-rotated {
  transform: rotate(360deg);
  transition: transform 0.1s ease-in-out;
}

.child-style {
  padding-left: 20px;
  font-weight: 300;
}

.overlay-icon-container {
  position: relative;
}

.delete-button-overlay {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
</style>
