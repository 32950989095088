/**
 * transformData function is used to transform an array of objects into a grouped structure.
 * Each group is defined by a specific category type (a property from the original objects),
 * and each group contains an array of categories that are objects comprised of specified properties.
 *
 * @param {Array} categoriesArray - Array of objects to be transformed. All objects should have properties mentioned in 'propForCategoryType' and 'propsForCategory'.
 * @param {string} propForCategoryType - The property in the original objects that will be used as the category type.
 * @param {Array.<string>} propsForCategory - An array of properties in the original objects that should be included in categories inside each group.
 *
 * @returns {Array} The transformed array, where each entry is an object with a category_type property and a categories array.
 * Each category_type object contains a key (name of the category type) and an array of categories.
 * Each category is an object comprised of properties specified in 'propsForCategory'.
 *
 * @example
 *
 * const input = [
 *   { id: 1, name: 'name1', type: 'type1' },
 *   { id: 2, name: 'name2', type: 'type2' },
 *   { id: 3, name: 'name3', type: 'type1' },
 * ];
 * const result = transformCategories(input, 'type', ['id', 'name']);
 * console.log(result)
 *
 * // Outputs:
 * [
 *   {
 *     type: 'type1',
 *     categories: [
 *       { id: 1, name: 'name1' },
 *       { id: 3, name: 'name3' },
 *     ],
 *   },
 *   {
 *     type: 'type2',
 *     categories: [{ id: 2, name: 'name2' }],
 *   },
 * ];
 */
export function transformData(categoriesArray, propForCategoryType, propsForCategory) {
  return categoriesArray.reduce((accumulator, category) => {

    // Check if the property for category_type exists in the accumulator
    let existingCategoryType = accumulator.find(
      (item) => item[propForCategoryType] === category[propForCategoryType]
    );

    if (existingCategoryType) {
      // If exists, push the new category with specified properties to the categories array
      let newCategory = {};
      propsForCategory.forEach(prop => {
        newCategory[prop] = category[prop];
      });
      existingCategoryType.categories.push(newCategory);

      // Sort the categories alphabetically by name
      existingCategoryType.categories.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      // If doesn't exist, push a new category_type object
      let newCategory = {};
      propsForCategory.forEach(prop => {
        newCategory[prop] = category[prop];
      });
      accumulator.push({ [propForCategoryType]: category[propForCategoryType], categories: [newCategory] });
    }
    return accumulator;
  }, []);
}