<script setup>
import { computed } from 'vue';
import WebsitePerformance from '@/components/reports/trending-library/WebsitePerformance.vue';
import AdsSearch from '@/components/reports/trending-library/AdsSearch.vue';
import AdsSiteRemarketing from '@/components/reports/trending-library/AdsSiteRemarketing.vue';
import AdsInMarketAudiences from '@/components/reports/trending-library/AdsInMarketAudiences.vue';
import AdsCustomIntentAudiences from '@/components/reports/trending-library/AdsCustomIntentAudiences.vue';
import AdsDemandGen from '@/components/reports/trending-library/AdsDemandGen.vue';
import AdsPerformanceMax from '@/components/reports/trending-library/AdsPerformanceMax.vue';
import AdsVideo from '@/components/reports/trending-library/AdsVideo.vue';
import GoogleBusinessProfile from '@/components/reports/trending-library/GoogleBusinessProfile.vue';
import MetaAdsPerformance from '@/components/reports/trending-library/MetaAdsPerformance.vue';
import LeasingPerformance from '@/components/reports/trending-library/LeasingPerformance.vue';
import LpConversionRate from '@/components/reports/trending-library/LpConversionRate.vue';
import LpContactType from '@/components/reports/trending-library/LpContactType.vue';
import TheAvailability from '@/components/reports/trending-library/TheAvailability.vue';
import ReputationOverall from '@/components/reports/trending-library/ReputationOverall.vue';
import ReputationGoogle from '@/components/reports/trending-library/ReputationGoogle.vue';
import { useTrendingReportStore } from '@/stores/TrendingReportStore';

// Logic...
const trendingReportStore = useTrendingReportStore();

// Function to format date ranges for display
function reportDate(end, start) {
  // Check if the start and end dates are the same
  if (start === end) {
    return start;
  }
  // If they are different, return the range
  return `${start} - ${end}`;
}

// Helper functions to parse and format date strings
function parseDateString(dateStr) {
  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(Date.UTC(year, month - 1, day));
}

function formatDate(date) {
  const year = date.getUTCFullYear();
  const month = `${date.getUTCMonth() + 1}`.padStart(2, '0');
  const day = `${date.getUTCDate()}`.padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function generateDateRanges(startDateStr, endDateStr, numPeriods, periodType) {
  const dateRanges = [];
  let currentEndDate = parseDateString(endDateStr);
  const startDate = parseDateString(startDateStr);

  for (let i = 0; i < numPeriods; i++) {
    let periodEnd = new Date(currentEndDate.getTime());
    let periodStart = new Date(periodEnd.getTime());

    if (periodType === 1) {
      // Daily
      periodStart = new Date(periodEnd.getTime());
    } else if (periodType === 2) {
      // Weekly
      periodStart.setUTCDate(periodStart.getUTCDate() - 6);
    } else if (periodType === 3) {
      // 30 day
      periodStart.setUTCDate(periodStart.getUTCDate() - 29);
    } else if (periodType === 4) {
      // Monthly
      periodStart.setUTCDate(1); // First day of the current month
    } else if (periodType === 5) {
      // Yearly
      periodStart.setUTCMonth(0); // January
      periodStart.setUTCDate(1); // First day of the year
    } else {
      // Unknown period type
      break;
    }

    // Ensure periodStart does not go before startDate
    if (periodStart < startDate) {
      periodStart = new Date(startDate.getTime());
    }

    dateRanges.unshift({
      start: formatDate(periodStart),
      end: formatDate(periodEnd),
    });

    // Move currentEndDate to the end of the previous period
    if (periodType === 1) {
      // Daily
      currentEndDate.setUTCDate(currentEndDate.getUTCDate() - 1);
    } else if (periodType === 2) {
      // Weekly
      currentEndDate.setUTCDate(currentEndDate.getUTCDate() - 7);
    } else if (periodType === 3) {
      // 30 day
      currentEndDate.setUTCDate(currentEndDate.getUTCDate() - 30);
    } else if (periodType === 4) {
      // Monthly
      currentEndDate.setUTCDate(1); // First day of current month
      currentEndDate.setUTCDate(0); // Last day of previous month
    } else if (periodType === 5) {
      // Yearly
      currentEndDate.setUTCMonth(0); // January
      currentEndDate.setUTCDate(1); // First day of the year
      currentEndDate.setUTCDate(0); // Last day of previous year
    }

    // Stop if currentEndDate is before startDate
    if (currentEndDate < startDate) {
      break;
    }
  }

  return dateRanges;
}

// Generate the dateRanges array as a computed property
const dateRanges = computed(() => {
  return generateDateRanges(
    trendingReportStore.trendingReport.startDay,
    trendingReportStore.trendingReport.endDay,
    trendingReportStore.numSelectedPeriod,
    trendingReportStore.selectedPeriod
  );
});
</script>

<template>
  <div class="table-container">
    <table class="custom-table table-striped table-hover table-responsive">
      <tbody>
        <!-- Conditionally include the GA4: Website Performance section -->
        <template v-if="trendingReportStore.report.website_performance">
          <tr>
            <th>GA4: Website Performance</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Website Performance Section Data -->
          <WebsitePerformance :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Google Ads: Search Section -->
        <template v-if="trendingReportStore.report.ads_search">
          <tr>
            <th>Google Ads: Search</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <AdsSearch :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Google Ads: Site Remarketing Section -->
        <template v-if="trendingReportStore.report.ads_site_remarketing">
          <tr>
            <th>Google Ads: Site Remarketing</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <AdsSiteRemarketing :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Google Ads: In-Market Audiences Section -->
        <template v-if="trendingReportStore.report.ads_in_market_audiences">
          <tr>
            <th>Google Ads: In-Market Audiences</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <AdsInMarketAudiences :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Google Ads: Custom Intent Audiences Section -->
        <template v-if="trendingReportStore.report.ads_custom_intent_audiences">
          <tr>
            <th>Google Ads: Custom Intent Audiences</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <AdsCustomIntentAudiences :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Google Ads: Demand Gen Section -->
        <template v-if="trendingReportStore.report.ads_demand_gen">
          <tr>
            <th>Google Ads: Demand Gen</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <AdsDemandGen :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Google Ads: Performance Max Section -->
        <template v-if="trendingReportStore.report.ads_p_max">
          <tr>
            <th>Google Ads: Performance Max</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <AdsPerformanceMax :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Google Ads: Video Section -->
        <template v-if="trendingReportStore.report.ads_video">
          <tr>
            <th>Google Ads: Video</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <AdsVideo :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Google Business Profile Section -->
        <template v-if="trendingReportStore.report.google_business_profile">
          <tr>
            <th>Google Business Profile</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <GoogleBusinessProfile :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Meta Ads Performance Section -->
        <template v-if="trendingReportStore.report.meta_ads_performance">
          <tr>
            <th>Meta Ads Performance</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <MetaAdsPerformance :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Leasing Performance Section -->
        <template v-if="trendingReportStore.report.leasing_performance">
          <tr>
            <th>Leasing Performance</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <LeasingPerformance :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Leasing Performance: Conversion Rate Section -->
        <template v-if="trendingReportStore.report.lp_conversion_rate">
          <tr>
            <th>Leasing Performance: Conversion Rate</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <LpConversionRate :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Leasing Performance: Contact Type Section -->
        <template v-if="trendingReportStore.report.lp_contact_type">
          <tr>
            <th>Leasing Performance: Contact Type</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <LpContactType :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Availability Section -->
        <template v-if="trendingReportStore.report.availability">
          <tr>
            <th>Availability</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <TheAvailability :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Reputation: Overall Section -->
        <template v-if="trendingReportStore.report.reputation_overall">
          <tr>
            <th>Reputation: Overall</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <ReputationOverall :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
        <!-- Conditionally include the  Reputation: Google Section -->
        <template v-if="trendingReportStore.report.reputation_google">
          <tr>
            <th>Reputation: Google</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <!-- Ads Search Section Data -->
          <ReputationGoogle :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
.table-container {
  border: 2px var(--secondary-color) solid;
  border-radius: 7px;
  overflow-x: auto;
  overflow-y: hidden;

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 9px;
    transition:
      width 0.5s ease,
      background-color 0.5s ease;
    scrollbar-gutter: auto;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.custom-table {
  width: 100%;
  min-width: 100%;
  border-spacing: 0;
  text-align: left;
  border-collapse: separate;

  tbody {
    tr {
      background-color: var(--property-card-bg);
      font-weight: bold;

      th {
        padding: 12px;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--secondary-color);
        border-right: 1px var(--secondary-color) solid;
        border-left: 1px var(--secondary-color) solid;
        border-bottom: 1px var(--secondary-color) solid;
        border-top: 1px var(--secondary-color) solid;
      }

      /* Remove the left border of the first row in the table to prevent touching the blue border */
      th:first-child {
        border-left: none;
      }

      /* Remove the right border of the last row in the table to prevent touching the blue border */
      th:last-child {
        border-right: none;
      }
    }
  }

  /* Remove the top border from the first row */
  tbody tr:first-child th {
    border-top: none;
  }

  /* Remove the bottom border from the last row */
  tbody tr:last-child th {
    border-bottom: none;
  }
}

@media (max-width: 768px) {
  .custom-table {
    font-size: 12px;

    th,
    td {
      padding: 8px;
    }
  }
}
</style>
