<!-- TheHeader.vue -->
<script setup>
// Imports...
import TheThemeSwitcher from './TheThemeSwitcher.vue';
import AppIconLogout from './svg/AppIconLogout.vue';
import AppIconAngleRight from './svg/AppIconAngleRight.vue';
import { useAuthStore } from '@/stores/AuthStore';
// Logic...
const authStore = useAuthStore();
const logout = () => {
  authStore.logOut();
  window.location.href = '/';
};

const linkGroups = [
  [
    { text: 'Properties', url: '/properties' },
    { text: 'Dashboards', url: '/dashboards' },
    { text: 'Reports', url: '/reports' },
    { text: 'Budgets', url: '/budgets' },
    { text: 'Alerts', url: '/alerts' },
  ],
  [
    { text: 'Settings', url: '/settings' },
    { text: 'Users', url: '/users' },
  ],
  [{ text: 'Theme' }, { text: 'Log Out' }],
];
</script>

<template>
  <ul class="list-container">
    <li v-for="(group, index) in linkGroups" :key="index" class="group">
      <div v-for="link in group" :key="link.text" class="list-item">
        <a v-if="link.url" :href="link.url" class="nav-option">
          {{ link.text }}
          <AppIconAngleRight class="nav-icon" />
        </a>

        <div v-if="link.text === 'Theme'" class="nav-option">
          {{ link.text }}
          <TheThemeSwitcher />
        </div>

        <div @click="logout" v-if="link.text === 'Log Out'" class="nav-option">
          {{ link.text }}
          <AppIconLogout />
        </div>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-direction: column;
  padding: 7px 10px;
  border-radius: 7px;
  list-style: none; /* Remove default list item dots */
  margin: 0; /* Remove default margins */
}

.group + .group {
  border-top: 1px solid var(--secondary-color-muted);
}

.group .list-item {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-text-color);
  font-family: Lato, sans-serif;
}

.nav-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  border-radius: 7px;
  transition:
    background 0.4s,
    color 0.4s;
  text-decoration: none; /* Remove underline from links */
  color: var(--text-color);
  opacity: 0.75;
  .nav-icon {
    color: var(--secondary-color);
  }

  &:hover {
    opacity: 100;
    transition: all 0.3s ease;
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);

    .nav-icon {
      color: var(--primary-color);
    }
  }
}
</style>
