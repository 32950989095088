<!-- AppIconExposure.vue -->
<script setup>
// Imports...
// Logic...
defineProps({
  amount: {
    type: [String, Number],
    required: true
  }
});

const getExposureColor = (amount) => {
  const numericAmount = parseFloat(amount.replace('%', ''));
  if (!amount) return 'color-default';  // Return a default color or value

  if (numericAmount <= 5) {
    return 'color-good';
  } else if (numericAmount <= 15) {
    return 'color-warning';
  } else {
    return 'color-danger';
  }
}
</script>

<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.299 4.70101 21 10.5 21ZM11.2569 4.24702L17.0244 13.5966C17.3963 14.1994 16.9627 14.9765 16.2543 14.9765H4.7193C4.01098 14.9765 3.57732 14.1994 3.9492 13.5966L9.71672 4.24702C10.0702 3.67398 10.9034 3.67398 11.2569 4.24702ZM8.98792 3.79744C9.67595 2.68209 11.2977 2.68209 11.9857 3.79744L17.7532 13.147C18.4771 14.3204 17.633 15.8328 16.2543 15.8328H4.7193C3.34066 15.8328 2.49658 14.3204 3.22039 13.147L8.98792 3.79744ZM10.4868 7.19528C10.2002 7.19528 9.96793 7.42759 9.96793 7.71417V11.1165C9.96793 11.4031 10.2002 11.6354 10.4868 11.6354C10.7734 11.6354 11.0057 11.4031 11.0057 11.1165V7.71417C11.0057 7.42759 10.7734 7.19528 10.4868 7.19528ZM9.96792 12.9536C9.96792 12.6854 10.1854 12.468 10.4536 12.468H10.5201C10.7883 12.468 11.0057 12.6854 11.0057 12.9536C11.0057 13.2218 10.7883 13.4392 10.5201 13.4392H10.4536C10.1854 13.4392 9.96792 13.2218 9.96792 12.9536Z"
            :class="getExposureColor(amount)" />
    </svg>
</template>

<style lang="scss" scoped>

</style>
