// AuthStore.js
import { defineStore } from 'pinia';
import { useLoginStore } from '@/stores/LoginStore';

const LOCAL_API_URL = 'http://localhost:3000/';
const PROD_API_URL = 'https://nodejs-production-75ff.up.railway.app/';
const baseApi = process.env.NODE_ENV === 'production' ? PROD_API_URL : LOCAL_API_URL;

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isLoggedIn: false,
    isInitialized: false,
    authUser: {
      email: '',
      password: '',
    },
    token: null,
  }),
  actions: {
    async startLogin(email, password) {
      const payload = {
        email,
        password,
      };
      try {
        const response = await fetch(`${baseApi}login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (response.ok && data.token) {
          sessionStorage.setItem('authToken', data.token); // Store token by session
          this.token = data.token;
          this.isloggedIn = true;
          return true;
        } else {
          console.error('Login failed:', data.error || 'Unknown error');
          return false;
        }
      } catch (error) {
        console.error('Login failed:', error);
        return false;
      }
    },
    logOut() {
      const loginStore = useLoginStore();
      this.isLoggedIn = false;
      this.token = null;
      loginStore.setStep('email');
      sessionStorage.removeItem('authToken'); // Remove token from local storage
    },
    initializeFromSessionStorage() {
      const token = sessionStorage.getItem('authToken');
      if (token) {
        this.isLoggedIn = true;
        this.token = token;
      } else {
        this.logOut(); // Log out if the token is expired or not valid
      }
    },
    checkTokenExpiry() {
      // In production we will most likely decode the JWT and check expiry
      return true; // token is valid
    },
  },
});
