<!-- AppFilterControlPanel.vue -->
<script setup>
// Imports...
import {usePropertiesStore} from "@/stores/PropertiesStore";

// Logic...
const props = defineProps({
  currentFilterType: {type: String}
});

const emit = defineEmits(['clearAll', 'update:currentFilterType']);

const propertyStore = usePropertiesStore();

function clearAll() {
  emit('clearAll');
}

</script>

<template>
  <div class="filter-wrapper">
    <div class="and-or-container">
      <!-- filter type logic here......-->
      <button @click.stop="propertyStore.updateCurrentFilterType('AND')"
              :class="['filter-button', { 'filter-button-active': props.currentFilterType === 'AND' }]">AND
      </button>
      <button @click.stop="propertyStore.updateCurrentFilterType('OR')"
              :class="['filter-button', { 'filter-button-active': props.currentFilterType === 'OR' }]">OR
      </button>
    </div>
    <div class="clear-container flex-end">
      <!-- Clear All Button -->
      <button @click="clearAll" class="clear-all-button">Clear</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

.filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.and-or-container {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-start;

}

.clear-container {
  display: flex;
  width: 50%;
  padding-right: 5px;
  align-items: flex-end;
  justify-content: flex-end;
}

.flex-end {
  justify-content: flex-end;
  flex-grow: 1;
}

.filter-button {
  background-color: transparent;
  font-size: 0.6em;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding: 2px 5px;
  margin: 5px 0px;
  cursor: pointer;
  border-radius: 15px;
  min-width: 40px;
  transition: all 0.3s ease
}

.filter-button:hover {
  color: var(--primary-text-color);
}

.filter-button:nth-child(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.filter-button:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.filter-button-active {
  background: var(--secondary-color);
  color: var(--dropdown-bg);
}

.clear-all-button {
  background-color: transparent;
  font-size: 0.75em;
  color: var(--secondary-color);
  padding: 2px 0;
  margin: 5px 0;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  transition: all 0.3s ease;
  text-decoration: underline;
}

.clear-all-button:hover {
  color: var(--primary-text-color);
}

.clear-all-button:active {
  background-color: var(--secondary-color);
}

</style>





