<!-- PropertiesManageGroupsModal.vue -->
<script setup>
import { ref } from "vue";
import { groupTypeOptions } from "@/data/properties";
import AppModal from "@/components/ui/AppModal.vue";
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppSingleSelector from "@/components/ui/selects/AppSingleSelector.vue";
import AppPropertiesSelector from "@/components/ui/selects/AppPropertiesSelector.vue";

const isLoading = ref(false);
const selectedType = ref("");

const handleSpinnerButton = () => {
  isLoading.value = true;
  setTimeout(() => {
    isLoading.value = false;
  }, 2000);
};

</script>

<template>
  <teleport to="body">
    <AppModal
      title="Manage Groups"
      modalId="manageGroupsModal"
    >
      <template #modalBody>
        <!-- Type input -->
        <AppSingleSelector
          title="Type"
          placeholder="Select a group type"
          :options="groupTypeOptions"
          v-model="selectedType"
        />
        <!-- Properties input -->
        <AppPropertiesSelector />
      </template>

      <template #modalFooter>
        <AppButton
          label="Save"
          :isLoading="isLoading"
          @clicked="handleSpinnerButton"
        />
      </template>
    </AppModal>
  </teleport>
</template>

<style scoped lang="scss"></style>
